import { ResourceData } from "../common/resource/resource-service";
import {
  IAthlete,
  IAthleteSearch,
  IServerGenericPagingResponseAthlete,
} from "./athlete-models";
import { IServerResponse } from "../common/common-models";
import https from "../common/https";
import { EntryPerfServer } from "../athleteCompSched/pb/v3/edit-pb-v3-models";

export class AthleteDataService extends ResourceData<IAthlete> {
  constructor() {
    super("/v5/athlete-maint");
  }

  public getAthleteByUrn(
    aoCode: string,
    urn: string,
    dob: string
  ): Promise<IServerResponse<IAthlete>> {
    const payload = {
      aocode: aoCode,
      regid: urn,
      dob,
    };
    return https.get("/v5/athlete/getid", {
      params: payload,
    }) as any as Promise<IServerResponse<IAthlete>>;
  }

  public findAthletes(
    competitionId: number,
    teamId: number,
    pageNumber: number,
    pageSize: number,
    sortKey: string,
    athleteSearch: IAthleteSearch
  ): Promise<IServerGenericPagingResponseAthlete> {
    const paramsPayload = {
      ...athleteSearch,
      compid: competitionId,
      teamid: teamId,
      pagenumber: pageNumber,
      pagesize: pageSize,
      sort: sortKey,
    };

    return https.get("/v5/athletes", {
      params: paramsPayload,
    }) as any as Promise<IServerGenericPagingResponseAthlete>;
  }

  public getEntryperf(
    entryId: number
  ): Promise<IServerResponse<EntryPerfServer>> {
    return https.get("/v5/entryperf/" + entryId) as any as Promise<
      IServerResponse<EntryPerfServer>
    >;
  }
}
