//  https://thescottyjam.github.io/snap.js/#!/utils

import {
  GENDER,
  GenderType,
  IBase,
  IObjectKey,
  IObjectKeyType,
  IsoDate,
  IsoDateTime,
} from "./common-models";
import { format, parse } from "date-fns";
// import * as R from "ramda";

export function getIsoDatePattern(): string {
  return "YYYY-MM-DD";
}

export function getIsoDateTimePattern(): string {
  return getIsoDatePattern() + "[T]HH:mm:ssZ";
}

export function getDateDisplayFormat(): string {
  return "DD/MM/YYYY";
}

export function convertDateToIsoWithOffset(date: Date): IsoDateTime {
  return format(date, getIsoDateTimePattern());
}

export function getE4sStandardHumanDateOutPut(
  dateTime: IsoDate | IsoDateTime
): string {
  return format(parse(dateTime), "Do MMM YYYY");
}

/**
 *
 * @param objectType
 */
export function simpleClone<ObjectType>(objectType: ObjectType): ObjectType {
  //  can't use structuredClone as it doe snot work with this version of project.
  return JSON.parse(JSON.stringify(objectType));
}

/**
 *  N.B. very, very simple comparison. Prop order is required.
 * @param objectType
 */
export function isEqual<ObjectType>(
  objectOne: ObjectType,
  objectTwo: ObjectType
): boolean {
  return JSON.stringify(objectOne) === JSON.stringify(objectTwo);
}

/**
 * Handles sorting on strings or numbers
 * @param prop
 * @param someArray
 */
export function sortArray<ObjectType, PropName extends keyof ObjectType>(
  prop: ((t: ObjectType) => string | number) | PropName,
  someArray: ObjectType[],
  order: "ASC" | "DESC" = "ASC"
): ObjectType[] {
  if (!someArray || someArray.length === 0) {
    return someArray;
  }

  return simpleClone(someArray).sort((a, b) => {
    const propValueA: unknown = typeof prop === "function" ? prop(a) : a[prop];
    const propValueB: unknown = typeof prop === "function" ? prop(b) : b[prop];

    if (typeof propValueA === "string" && typeof propValueB === "string") {
      const compA: string = (
        order === "ASC" ? propValueA : propValueB
      ).toUpperCase();
      const compB: string = (
        order === "ASC" ? propValueB : propValueA
      ).toUpperCase();
      // const compB: string = propValueB.toString().toUpperCase();

      if (compA < compB) {
        return -1;
      }
      if (compA > compB) {
        return 1;
      }
      // names must be equal
      return 0;
    }

    if (typeof propValueA === "number" && typeof propValueB === "number") {
      return order === "ASC"
        ? propValueA - propValueB
        : propValueB - propValueA;
    }

    return 0;
  });
}

export function howManyPages<ObjectArray>(
  objectArray: ObjectArray[],
  pageSize: number
): number {
  return Math.ceil(objectArray.length / pageSize);
}

export function chunkArray<ObjectArray>(
  myArray: ObjectArray[],
  chunkSize: number
): ObjectArray[][] {
  const arrayLength: number = myArray.length;
  const tempArray: ObjectArray[][] = [];

  let index: number;
  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

export function roundNumberToDecimalPlaces(
  value: string | number,
  decimalPlaces: number,
  asNumber: boolean = true
): number | string {
  let inputValue: string = value.toString();
  inputValue = inputValue.length === 0 ? "0" : inputValue;
  if (asNumber) {
    return Number(parseFloat(inputValue).toFixed(decimalPlaces));
  }
  return parseFloat(inputValue).toFixed(decimalPlaces);
}

/**
 * N.B. it will return 5.12.3 as FALSE, which is true.
 * @param str
 */
export function isNumeric(str: string | number) {
  if (typeof str === "number") {
    return true;
  }
  if (typeof str !== "string") return false; // we only process strings from here on!
  return (
    !isNaN(str as any as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function hasAtLeastOneCharacter(
  someNumber: string | number,
  sepsAllowed?: string[]
): boolean {
  let valueToTest = someNumber.toString();
  if (sepsAllowed) {
    valueToTest = sepsAllowed.reduce((accum, sep) => {
      accum = accum.replace(sep, "");
      return accum;
    }, valueToTest);
  }
  const regex = /[a-zA-Z]/;
  return regex.test(valueToTest);
}

/**
 * Use this if there is a one to one mapping.   E.g.
 * {
 *      "MA": {city: "Boston"},
 *      "CT": {"city": "New York"}
 * }
 * @param prop
 * @param someArray
 */
// public convertArrayToObject<T>(prop: string, someArray: T[]): IObjectKeyType<T> {
//     if (R.isNil(someArray) || someArray.length === 0) {
//         return {};
//     }
//     // @ts-ignore
//     const objFromListWith = R.curry((fn, list) => R.chain(R.zipObj, R.map(fn))(list));
//     const result = objFromListWith(
//         R.prop(prop)
//     )(someArray);
//     // @ts-ignore
//     return result;
// }
export function convertArrayToObject<T>(
  prop: ((t: T) => any) | string,
  someArray: T[]
): Record<string, T> {
  if (isNil(someArray) || someArray.length === 0) {
    return {};
  }
  return someArray.reduce((accum, obj: T) => {
    // @ts-ignore
    const propValueString = typeof prop === "string" ? obj[prop] : prop(obj);
    //  @ts-ignore
    accum[propValueString] = obj;
    return accum;
  }, {} as Record<string, T>);
}

/**
 * Use this if there is a one to many mapping.   E.g.
 * {
 *      "MA": [
 *          {city: "Boston"},
 *          {city: "Winchester"}
 *      ],
 *      "CT": [
 *          {city: "New Canaan"}
 *      ]
 * }
 * @param prop
 * @param someArray
 */
export function convertArrayToObjectArray<T>(
  prop: ((t: T) => any) | keyof T,
  someArray: T[]
): Record<string, T[]> {
  return someArray.reduce((accum, obj: T) => {
    // @ts-ignore
    const propValueString = typeof prop === "string" ? obj[prop] : prop(obj);
    //  Produce a keyed object
    if (!accum[propValueString]) {
      accum[propValueString] = [];
    }
    accum[propValueString].push(obj);
    return accum;
  }, {} as IObjectKey);
}

export function uniqueArrayById<T>(objs: T[], idProp?: string): T[] {
  // const uniques = Array.from(new Set(objs.map((a) => a.id)))
  //     .map((id) => {
  //         return objs.find((a) => a.id === id);
  //     }) as IBase[];
  const prop: string = idProp ? idProp : "id";
  const uniques = objs.reduce((acc: T[], current: T) => {
    //  TODO find does not work in IE11
    const x = acc.find((item: T) => {
      // return item.id === current.id;
      // @ts-ignore
      return item[prop] === current[prop];
    });
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  return uniques;
}

export function convertToIsoDateTimeWithOffset(date: Date) {
  return format(date, getIsoDateTimePattern());
}

export function getE4sStandardHumanDateTimeOutPut(
  dateTime: IsoDate | IsoDateTime,
  showYear: boolean = true
): string {
  return format(
    parse(dateTime),
    showYear ? "Do MMM YYYY h:mma" : "Do MMM h:mma"
  );
}

export function eventDateDisplay(iso: IsoDateTime, dateNow?: Date): string {
  const dateEvent = parse(iso);
  const yearEvent = format(dateEvent, "YYYY");
  const yearNow = dateNow
    ? format(dateNow, "YYYY")
    : format(new Date(), "YYYY");
  const pattern = yearEvent === yearNow ? "Do MMM" : "Do MMM YYYY";
  return format(parse(iso), pattern);
}

export function eventDateDisplayCard(iso: IsoDateTime, dateNow?: Date): string {
  const dateEvent = parse(iso);
  const dayOfWeek = format(dateEvent, "ddd");

  return dayOfWeek + " " + eventDateDisplay(iso, dateNow);
}

export function eventTimeDisplay(iso: IsoDateTime): string {
  //  Well this is rubbish...date at time of writing test is: 2021-03-03.
  //  If you format(parse("2024-03-31T00:00:00+01:00")) 31st Mar IS in DST...
  //  ...but date-fns is not taking that into account and using time as of now!!!!
  //  ...so...take the f-ing time from the iso string.
  iso = iso.replace("+01:00", "");
  const time = format(parse(iso), "HH:mm");
  return time === "00:00" ? "TBC" : time;
}

export function eventDateTimeDisplay(iso: IsoDateTime): string {
  return eventDateDisplay(iso) + " @" + eventTimeDisplay(iso);
}

export function getAmountAsCurrency(value: number, currency: string): string {
  /*
      Problem with this, we don't know the currency code, but it is way faster than method below.
      new Intl.NumberFormat('en-US', {
          style: "currency",
          currency: "GBP",
      }).format(compEvent.order.wcLineValue)
      */
  // return currency + Number(value.toFixed(2)).toLocaleString();
  return currency + roundNumberToDecimalPlaces(value, 2, false);
}

export function unique<T>(objs: T[]): T[] {
  // return [...new Set(objs)];
  // return Array.from(new Set(simpleClone(objs)));
  return Array.from(
    new Set(
      objs.map((obj) => {
        return JSON.stringify(obj);
      })
    )
  ).map((obj) => {
    return JSON.parse(obj);
  });
  // return R.uniq(objs);
}

export function uniqueBy<InputObject>(
  objs: InputObject[],
  propNameOrFunction: ((t: InputObject) => string) | keyof InputObject
): InputObject[] {
  const objMap = objs.reduce<Record<string, InputObject>>((accum, obj) => {
    let pValue: string = "";
    if (typeof propNameOrFunction === "string") {
      pValue = obj[propNameOrFunction] as any as string;
    } else {
      //  @ts-ignore
      pValue = propNameOrFunction(obj) as any as string;
    }

    const exists = accum[pValue];

    if (!exists) {
      accum[pValue] = obj;
    }
    return accum;
  }, {});

  return Object.values(objMap);
}

/*
export function unique<T>(objs: T[]): T[] {
  // // @ts-ignore
  // return [...new Set(objs)];
  return R.uniq(objs);
}
*/

export function getGenderLabel(gender: GENDER): string {
  return gender === GENDER.MALE ? "Male" : "Female";
}

export function getGenderLabelAll(gender: GenderType): string {
  const genderMap: Partial<Record<GenderType, string>> = {
    F: "Female",
    M: "Male",
    O: "Open",
  };

  // if (genderMap[gender]) {
  //   return genderMap[gender];
  // } else {
  //   return "Unknown";
  // }

  //  @ts-ignore TODO  I'm tired...fix another time
  return genderMap[gender] ? genderMap[gender] : "Unknown";
}

export function pluckUnique<InputObject, OutputObject>(
  prop: ((t: InputObject) => OutputObject) | keyof InputObject,
  someArray: InputObject[]
): OutputObject[] {
  return unique(pluck(prop, someArray));
}

/**
 * Pluck a property from an array of objects.
 * @param propNameOrFunction
 * @param someArray
 */
export function pluck<InputObject, OutputObject>(
  propNameOrFunction: ((t: InputObject) => OutputObject) | keyof InputObject,
  someArray: InputObject[]
): OutputObject[] {
  return someArray.reduce<OutputObject[]>((accum, obj: InputObject) => {
    let pValue;
    if (typeof propNameOrFunction === "string") {
      pValue = obj[propNameOrFunction];
    } else {
      //  @ts-ignore
      pValue = propNameOrFunction(obj);
    }

    // const pValue =
    //   typeof propNameOrFunction === "string"
    //     ? obj[propNameOrFunction]
    //     : propNameOrFunction(obj);
    accum.push(pValue);
    return accum;
  }, []);
}

export function propValue<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export function getIdText<T, K extends keyof T>(
  obj: T | IBase,
  key: K,
  withSpace: boolean = true
) {
  const prefix = withSpace ? " " : "";
  if (key === "id") {
    return prefix + "(" + (obj as IBase).id + ")";
  }
  return prefix + "(" + propValue(obj as T, key) + ")";
}

export function getSimpleObjectNameMaybeAdmin<SomeObject extends IBase>(
  obj: SomeObject,
  key: keyof SomeObject,
  isAdmin: boolean
): string {
  return obj[key] + (isAdmin ? " (" + obj.id + ")" : "");
}

export function intersection<T>(array1: T[], array2: T[]) {
  return array1.filter((x) => array2.includes(x));
}
export function convertArrayToObject2<KeyType, ObjType>(
  prop: ((t: ObjType, index?: number) => KeyType) | KeyType,
  someArray: ObjType[]
): Record<string | number, ObjType> {
  if (isNil(someArray) || someArray.length === 0) {
    return {};
  }
  return someArray.reduce<Record<string | number, ObjType>>(
    (accum, obj: ObjType, currentIndex) => {
      // const propValueString =
      //   typeof prop === "string" || typeof prop === "number"
      //     ? obj[prop]
      //     : prop(obj, currentIndex);
      let propValueString: string | number;
      if (typeof prop === "string" || typeof prop === "number") {
        // @ts-ignore
        propValueString = obj[prop];
      } else {
        // @ts-ignore
        propValueString = prop(obj, currentIndex);
      }
      accum[propValueString] = obj;
      return accum;
    },
    {}
  );
}

export function convertObjectToArray<T>(someObject: IObjectKeyType<T>): T[] {
  const arr: T[] = [];
  for (const key in someObject) {
    if (someObject.hasOwnProperty(key)) {
      arr.push(someObject[key]);
    }
  }
  return arr;
}

export function isOnlyNumbers(someValue: string): boolean {
  return /^\d+$/.test(someValue);
}

export async function executePromisesSequentially(
  proms: (() => Promise<unknown>)[]
) {
  const tasks = proms;
  for (const fn of tasks) {
    await fn();
  }
}

export function isValidHttpUrl(someUrl: string) {
  let url;

  try {
    url = new URL(someUrl);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export interface IConvertedSecondsToHMS {
  hours: number;
  minutes: number;
  seconds: number;
  hundredths: number;
}

export function convertSecondsToHMS(sec: number): IConvertedSecondsToHMS {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - minutes * 60);
  const hundredths = getFractionalPartAsWholeNumber(sec);
  return { hours, minutes, seconds, hundredths };
}

export function convertSecondsToUserDisplayString(
  inputSeconds: number
): string {
  return getUserOutputText(convertSecondsToHMS(inputSeconds));
}

/**
 * Convert IConvertedSecondsToHMS to seconds
 * @param convertedSecondsToHMS
 */
export function convertedSecondsToHMSToSeconds(
  convertedSecondsToHMS: IConvertedSecondsToHMS
): number {
  const { hours, minutes, seconds, hundredths } = convertedSecondsToHMS;
  return hours * 3600 + minutes * 60 + seconds + hundredths / 100;
}

export function getUserOutputText(
  convertedSecondsToHMS: IConvertedSecondsToHMS
): string {
  const keys: (keyof IConvertedSecondsToHMS)[] = [
    "hours",
    "minutes",
    "seconds",
    "hundredths",
  ];
  const res: string = keys.reduce<string>((accum, key) => {
    const value = convertedSecondsToHMS[key];
    if (accum.length > 0) {
      accum += key === "hundredths" ? "." : ":";
    }
    if (key === "hours" && value === 0) {
      return accum;
    }
    if (key === "minutes" && value === 0 && convertedSecondsToHMS.hours === 0) {
      //  E.g. the 100m, if hours and mins are 0, don't show the hours, minutes.
      return accum;
    }
    accum += value.toString().padStart(2, "0");
    return accum;
  }, "");

  return res;
}

/**
 * Given a float as input, return the fractional part of the number as a whole number.
 */
export function getFractionalPartAsWholeNumber(
  value: number,
  decimalPlaces: 1 | 2 | 3 = 2
): number {
  let multiplier = 100;
  if (decimalPlaces === 1) {
    multiplier = 10;
  }
  if (decimalPlaces === 3) {
    multiplier = 1000;
  }

  return Math.round((value % 1) * multiplier);
}

/**
 * Given a number, return the number as a string with leading zeros as specified by the number of digits.
 * @param value
 * @param digits
 * @returns E.g. getNumberWithLeadingZeros(1, 2) returns "01", getNumberWithLeadingZeros(1, 3) returns "001"
 */
export function getNumberWithLeadingZeros(
  value: number,
  digits: 1 | 2 | 3 = 2
): string {
  const multiplier = 10 ** digits;
  const valueWithLeadingZeros = (multiplier + value).toString().slice(1);
  return valueWithLeadingZeros;
}

export type ObjectWidthAndHeight = { width: number; height: number };
export function getElementWidthAndHeight(
  selector: string
): ObjectWidthAndHeight {
  const element: HTMLElement | null = document.querySelector(selector);
  if (element) {
    return { width: element.offsetWidth, height: element.offsetHeight };
  }
  return { width: -1, height: -1 };
}

export function convertObjectToUrlParams(obj: unknown): string {
  // return Object.entries(obj)
  //   .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
  //   .join("&");

  return Object.entries(obj as Record<string, string | number | boolean>)
    .map((kv) => kv.map(encodeURIComponent).join("="))
    .join("&");
}

export function getNextDateInSequence(
  dates: string[],
  dateToMatch?: string
): string {
  const dateToMatchFNS = dateToMatch ? parse(dateToMatch) : parse(new Date());
  const dateToMatchDateOnly = format(dateToMatchFNS, "YYYY-MM-DD");
  return dates.reduce((accum, date) => {
    const dateOnly = format(parse(date), "YYYY-MM-DD");

    if (dateOnly === dateToMatchDateOnly) {
      return dateOnly;
    }
    if (accum.length === 0 && dateOnly > dateToMatchDateOnly) {
      return dateOnly;
    }
    return accum;
  }, "");
}

export function isNil(value: any): boolean {
  // check for null or undefined or empty string, array, object or number without using Ramda
  // return value == null || value === "" || value.length === 0;
  return (
    value == null ||
    typeof value === "undefined" ||
    value === "" ||
    value.length === 0
  );
}

export interface IAgeInYearsMonthsDays {
  years: number;
  months: number;
  days: number;
}

export function getAgeInYearsMonthsDaysObject(
  dateOfBirth: string,
  dateToCompare: string
): IAgeInYearsMonthsDays {
  const dob = parse(dateOfBirth);
  const date = parse(dateToCompare);
  const age = date.getTime() - dob.getTime();
  const ageDate = new Date(age);
  const years = Math.abs(ageDate.getUTCFullYear() - 1970);
  const months = ageDate.getUTCMonth();
  const days = ageDate.getUTCDate() - 1;
  return {
    years,
    months,
    days,
  };
}

// create a function that will return an age in years, months and days when given a date of birth in the format YYYY-MM-DD
// and a date to compare to in the format YYYY-MM-DD
export function getAgeInYearsMonthsDays(
  dateOfBirth: string,
  dateToCompare: string
): string {
  const { years, months, days } = getAgeInYearsMonthsDaysObject(
    dateOfBirth,
    dateToCompare
  );
  return `${years} years, ${months} months and ${days} days`;
}

// export function convertXmlToJson(xml: string): unknown {
/*
  const jsonData: any = {};
  //  @ts-ignore
  for (const result of xml.matchAll(
    /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
  )) {
    const key: any = result[1] || result[3];
    const value: any = result[2] && convertXmlToJson(result[2]);
    jsonData[key] =
      (value && Object.keys(value).length ? value : result[2]) || null;
  }
  return jsonData;
   */

//   const json: any = {};
//   //  @ts-ignore
//   for (const res of xml.matchAll(
//     /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
//   )) {
//     const key: any = res[1] || res[3];
//     const value: any = res[2] && convertXmlToJson(res[2]);
//     json[key] = (value && Object.keys(value).length ? value : res[2]) || null;
//   }
//   return json;
// }

// export function xmlToJson(xml: string): Record<string, any> {
//   const parser = new window.DOMParser();
//   const xmlDoc = parser.parseFromString(xml, "text/xml");
//   const result: Record<string, any> = {};
//
//   function parseNode(node: Node): void {
//     if (node.nodeType === Node.ELEMENT_NODE) {
//       const nodeName = node.nodeName;
//       const nodeValue = node.textContent?.trim() || "";
//
//       if (!result[nodeName]) {
//         result[nodeName] = nodeValue;
//       } else if (Array.isArray(result[nodeName])) {
//         result[nodeName].push(nodeValue);
//       } else {
//         result[nodeName] = [result[nodeName], nodeValue];
//       }
//
//       for (const childNode of Array.from(node.childNodes)) {
//         parseNode(childNode);
//       }
//     }
//   }
//
//   parseNode(xmlDoc.documentElement);
//   return result;
// }
