import * as R from "ramda";
import {
  ICompetitionSummaryPublic,
  IWorkFlow,
} from "../../competition/competition-models";
import { ActionContext, Module } from "vuex";
import { IRootState, useStore } from "../../app.store";
import { CompetitionData } from "../../competition/competition-data";
import { IClub } from "../../club/club-models";
import { handleResponseMessages } from "../../common/handle-http-reponse";

import * as CompetitionDataV2 from "../../competition/v2/competition-data-v2";
import * as CompetitionServiceV2 from "../../competition/v2/competiton-service-v2";
import * as CommonServiceUtils from "../../common/common-service-utils";
import { IBase, IPaging } from "../../common/common-models";
import { EntryPublicService } from "./entry-public-service";
import { CommonService } from "../../common/common-service";
import { messageDispatchHelper } from "../../user-message/user-message-store";
import { USER_MESSAGE_LEVEL } from "../../user-message/user-message-models";
import { ICompFilterParams } from "../../competition/v2/competition-data-v2";

const competitionData = new CompetitionData();
const entryPublicService = new EntryPublicService();
const commonService = new CommonService();

export interface IPublicCompsStoreState {
  isLoadingCompSummary: boolean;
  //  So badly named!!!!!!!
  competitionSummaryPublic: ICompetitionSummaryPublic[];
  page: IPaging;
  compOrgs: IClub[];
  compOrgsAll: IClub[];
  workFlows: IWorkFlow[];
  compSimpleFilter: string;
  compFilter: ICompFilterParams;
  displayType: "CARD" | "LIST";
}

export enum PUBLIC_COMPS_STORE_CONST {
  PUBLIC_COMPS_CONST_MODULE_NAME = "PUBLIC_COMPS_CONST_MODULE_NAME",

  // <MUTATIONS>
  PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS = "PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS",
  PUBLIC_COMPS_MUTATIONS_SET_COMPS = "PUBLIC_COMPS_MUTATIONS_SET_COMPS",
  PUBLIC_COMPS_MUTATIONS_SET_PAGE = "PUBLIC_COMPS_MUTATIONS_SET_PAGE",
  PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS = "PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS",
  PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS_ALL = "PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS_ALL",
  PUBLIC_COMPS_MUTATIONS_SET_WORK_FLOWS = "PUBLIC_COMPS_MUTATIONS_SET_WORK_FLOWS",
  PUBLIC_COMPS_MUTATIONS_SET_SIMPLE_FILTER = "PUBLIC_COMPS_MUTATIONS_SET_SIMPLE_FILTER",
  PUBLIC_COMPS_MUTATIONS_SET_DISPLAY_TYPE = "PUBLIC_COMPS_MUTATIONS_SET_DISPLAY_TYPE",
  PUBLIC_COMPS_MUTATIONS_SET_COMP_FILTERS = "PUBLIC_COMPS_MUTATIONS_SET_COMP_FILTERS",
  // </MUTATIONS>

  // <ACTIONS>
  PUBLIC_COMPS_ACTIONS_GET_COMPS = "PUBLIC_COMPS_ACTIONS_GET_COMPS",
  PUBLIC_COMPS_ACTIONS__FILTER_COMPS = "PUBLIC_COMPS_ACTIONS__FILTER_COMPS",
  // </ACTIONS>
}

const mutations = {
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS](
    state: IPublicCompsStoreState,
    isLoading: boolean
  ) {
    state.isLoadingCompSummary = isLoading;
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMPS](
    state: IPublicCompsStoreState,
    comps: ICompetitionSummaryPublic[]
  ) {
    state.competitionSummaryPublic = R.clone(comps);
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_PAGE](
    state: IPublicCompsStoreState,
    page: IPaging
  ) {
    state.page = R.clone(page);
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS](
    state: IPublicCompsStoreState,
    compOrgs: IClub[]
  ) {
    state.compOrgs = R.clone(compOrgs);
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS_ALL](
    state: IPublicCompsStoreState,
    compOrgs: IClub[]
  ) {
    state.compOrgsAll = R.clone(compOrgs);
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_WORK_FLOWS](
    state: IPublicCompsStoreState,
    workFlows: IWorkFlow[]
  ) {
    state.workFlows = R.clone(workFlows);
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_SIMPLE_FILTER](
    state: IPublicCompsStoreState,
    filterText: string
  ) {
    state.compSimpleFilter = filterText;
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_DISPLAY_TYPE](
    state: IPublicCompsStoreState,
    displayType: "CARD" | "LIST"
  ) {
    state.displayType = displayType;
  },
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_FILTERS](
    state: IPublicCompsStoreState,
    compFilterParams: ICompFilterParams
  ) {
    state.compFilter = compFilterParams;
  },
};
const getters = {};

const actions = {
  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_ACTIONS_GET_COMPS](
    context: ActionContext<IPublicCompsStoreState, IRootState>,
    payload: {
      compOrgId: number;
    }
  ) {
    // context.dispatch(
    //   PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_ACTIONS__FILTER_COMPS,
    //   { compFilterParams: context.state.compFilter }
    // );

    context.commit(
      PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS,
      true
    );
    let prom;
    let gettingAllComps: boolean = false;
    if (payload && payload.compOrgId) {
      prom = competitionData.getAllComps(payload.compOrgId);
    } else {
      gettingAllComps = true;
      prom = competitionData.getAllComps();
    }
    prom
      .then((resp) => {
        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS,
          false
        );

        // const comps = resp.data.map((comp) => {
        //     const compNew = R.mergeDeepRight(competitionService.factorySummaryPublic(), comp);
        //     return compNew;
        // });

        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMPS,
          resp.data
        );

        let compOrgsLocal = entryPublicService.mapCompToClub(resp.data);
        compOrgsLocal = commonService.uniqueArrayById(
          compOrgsLocal as IBase[]
        ) as IClub[];
        compOrgsLocal = compOrgsLocal.sort((a: IClub, b: IClub) => {
          return a.club.localeCompare(b.club);
        });

        compOrgsLocal.unshift({ club: "All Organisers", clubid: 0, id: 0 });
        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS,
          compOrgsLocal
        );
        if (gettingAllComps) {
          context.commit(
            PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_ORGS_ALL,
            compOrgsLocal
          );
        }
        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_WORK_FLOWS,
          resp.meta.workflow
        );
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
        return;
      });
  },

  [PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_ACTIONS__FILTER_COMPS](
    context: ActionContext<IPublicCompsStoreState, IRootState>,
    payload: {
      compFilterParams: ICompFilterParams;
    }
  ) {
    context.commit(
      PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS,
      true
    );

    context.commit(
      PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMP_FILTERS,
      CommonServiceUtils.simpleClone(payload.compFilterParams)
    );

    const prom = CompetitionDataV2.getFilteredComps(payload.compFilterParams);
    handleResponseMessages(prom);
    prom
      .then((resp) => {
        const compsV1: ICompetitionSummaryPublic[] = resp.data.map((compV2) => {
          return CompetitionServiceV2.mapCompetitionSummaryPublicToICompetitionSummaryPublicV2(
            compV2
          );
        });

        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_COMPS,
          compsV1
        );

        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_PAGE,
          resp.meta
        );

        // context.commit(
        //   PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_SET_WORK_FLOWS,
        //   resp.meta.workflow
        // );
      })
      .finally(() => {
        context.commit(
          PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_MUTATIONS_IS_LOADING_COMPS,
          false
        );
      });
  },
};

const fromDate = new Date();
fromDate.setDate(fromDate.getDate() - 7);

export const publicCompStore: Module<IPublicCompsStoreState, IRootState> = {
  namespaced: true,
  state: {
    isLoadingCompSummary: false,
    competitionSummaryPublic: [],
    page: {
      page: 1,
      pageSize: 20,
      totalCount: 0,
    },
    compOrgs: [],
    compOrgsAll: [],
    workFlows: [],
    compSimpleFilter: "",
    compFilter: {
      fromDate: fromDate.toISOString().split("T")[0],
      toDate: "",
      freeTextSearch: "",
      organiser: {
        id: 0,
        name: "",
      },
      location: {
        id: 0,
        name: "",
      },
      type: "ALL",
      compOrg: {
        id: 0,
        name: "",
      },
      event: [],
      pagenumber: 1,
    },
    displayType: "CARD",
  },
  mutations,
  getters,
  actions,
};

export function useCompStoreState(): IPublicCompsStoreState {
  const store = useStore();
  return store.state[PUBLIC_COMPS_STORE_CONST.PUBLIC_COMPS_CONST_MODULE_NAME];
}
