import * as R from "ramda";
import {
  IAreaUserInfo,
  IClubUserInfo,
  IConfigApp,
  IEntity,
  IConfigPermission,
  IConfigPermLevel,
  IUserInfo,
  IUserInfoEntity,
  USER_ROLES,
  BUILDER_PERM_LEVEL_NAMES,
  IConfigOptions,
  SECURITY_ROLES,
  IUserApplication,
  IConfigHealth,
  IConfigHealthStatus,
} from "./config-app-models";
import { IOrg } from "../org/org-models";
import {
  AO_CODE,
  IAthleticsOrganisation,
} from "../common/ui/athletic-org/athletic-org-models";
import { differenceInSeconds, parse } from "date-fns";
import { IConfigStoreState } from "./config-store";

export class ConfigService {
  public factoryConfigApp(): IConfigApp {
    return {
      systemName: "",
      env: "prod",
      theme: "def",
      logo: "",
      lite: "",
      currency: "",
      userguide: "",
      defaultao: {} as IAthleticsOrganisation,
      aos: [],
      userId: 0,
      role: USER_ROLES.ANON,
      menus: {
        admin: {
          builder: false,
          cheques: false,
          userSearch: false,
        },
        reports: [],
      },
      classifications: [],
      orgs: [],
      userInfo: this.factoryUserInfo(),
      options: this.factoryConfigOptions(),
      logout: "",
      checkIn: {
        shortUrl: "",
        defaultText: "",
      },
      help: [],
      messages: [],
    };
  }

  public factoryUserInfo(): IUserInfo {
    return {
      user: this.factoryUserApplication(),
      areas: [] as IAreaUserInfo[],
      clubs: [] as IClubUserInfo[],
      orgs: [] as IOrg[],
      security: {
        permLevels: {
          builder: [] as IConfigPermLevel[],
        },
        permissions: [] as IConfigPermission[],
      },
      e4s_credit: 0,
    };
  }

  public factoryUserApplication(): IUserApplication {
    return {
      id: 0,
      role: "",
      display_name: "",
      impersonating: false,
      user_email: "",
      google_email: "",
      permissions: [],
      version: {
        current: "v1",
        toggle: false,
      },
    };
  }

  public factoryEntity(): IEntity {
    return {
      name: "",
      entityLevel: 0,
      id: 0,
      entityName: "",
      clubType: "",
    };
  }

  public factoryEntityClub(isSchool: boolean): IEntity {
    return {
      ...this.factoryEntity(),
      entityLevel: 1,
      entityName: isSchool ? "School" : "Club",
    };
  }

  public factoryEntityArea(): IEntity {
    //  TODO, don't know if these are County or Region
    return {
      ...this.factoryEntity(),
      entityLevel: 2,
      entityName: "County",
    };
  }

  public factoryConfigOptions(): IConfigOptions {
    return {
      addCost: 0,
      minFee: 0,
      minCost: 0,
      percCharged: 0,
      message: "",
      nationalminCost: 0,
      nationaladdCost: 0,
      homeMessage: "",
      cartTimeLimit: 30,
      health: this.factoryConfigHealth(),
      homePage: {
        //  using the factory() function I think cause some circular dependency, hence the hand crank.
        message: "",
        defaultFilters: {
          fromDate: "",
          toDate: "",
          freeTextSearch: "",
          organiser: {
            id: 0,
            name: "",
          },
          location: {
            id: 0,
            name: "",
          },
          type: "ALL",
          compOrg: {
            id: 0,
            name: "",
          },
          event: [],
          pagenumber: 1,
        },
      },
      pwd: {
        regex: "",
        helpText: "",
      },
      defaultAllowExpiredRegistration: true,
      useStripeConnect: false,
    };
  }

  public factoryConfigHealth(): IConfigHealth {
    return {
      entryCheck: {
        every: 0,
        lastRun: "",
      },
      healthMonitor: {
        every: 0,
        lastRun: "",
      },
      orderCheck: {
        every: 0,
        lastRun: "",
      },
      paymentStatus: {
        every: 0,
        lastRun: "",
      },
      waitingList: {
        every: 0,
        lastRun: "",
      },
    };
  }

  public getEntityLevelFromUserInfo(configApp: IConfigApp): IEntity | null {
    if (R.isNil(configApp) || R.isNil(configApp.userInfo)) {
      return null;
    }
    const userInfo = R.clone(configApp.userInfo);
    const areas: IUserInfoEntity[] = userInfo.areas ? userInfo.areas : [];
    const clubs: IUserInfoEntity[] = userInfo.clubs ? userInfo.clubs : [];
    const allEntities: IUserInfoEntity[] = areas.concat(clubs);
    return allEntities && allEntities.length === 1
      ? this.convertToEntity(allEntities[0])
      : null;
  }

  public convertToEntity(
    obj: IUserInfoEntity | IClubUserInfo | IAreaUserInfo
  ): IEntity {
    const name =
      obj.entityName === "Club"
        ? (obj as IClubUserInfo).Clubname
        : (obj as IAreaUserInfo).areaname;

    return {
      name,
      entityLevel: obj.entityLevel,
      id: obj.entityName === "Club" ? (obj as IClubUserInfo).id : obj.areaid,
      entityName: obj.entityName,
      clubType:
        obj.entityName === "Club" ? (obj as IClubUserInfo).clubtype : "",
    };
  }

  public getEntitiesFromUserInfo(userInfo: IUserInfo): IEntity[] {
    if (R.isNil(userInfo)) {
      return [];
    }
    const areas: IUserInfoEntity[] = userInfo.areas ? userInfo.areas : [];
    const clubs: IUserInfoEntity[] = userInfo.clubs ? userInfo.clubs : [];

    const allEntities: IUserInfoEntity[] = areas.concat(clubs);
    const entities: IEntity[] = allEntities.map((obj) => {
      return this.convertToEntity(obj);
    });
    return entities;
  }

  public hasBuilderPermission(
    userInfo: IUserInfo,
    builderPermLevelName: BUILDER_PERM_LEVEL_NAMES
  ): boolean {
    const userInfoLocal = R.clone(userInfo);

    if (userInfoLocal && userInfoLocal.security) {
      if (userInfoLocal.security.permissions) {
        const levels = userInfoLocal.security.permissions.filter((perm) => {
          return perm.role.toUpperCase() === "ALL";
        });

        if (levels.length > 0) {
          return true;
        }
      }

      if (
        userInfoLocal.security.permLevels &&
        userInfoLocal.security.permLevels.builder
      ) {
        const levels = userInfoLocal.security.permLevels.builder.filter(
          (permLevel) => {
            return (permLevel.level = builderPermLevelName);
          }
        );
        return levels.length > 0 ? true : false;
      }
    }
    return false;
  }

  public hasAppAdminPermissionForAnyOrg(userInfo: IUserInfo): boolean {
    return this.hasPermissionForComp(
      userInfo,
      -99,
      -99,
      SECURITY_ROLES.APPADMIN,
      false
    );
  }

  public hasBuilderPermissionForAnyOrg(userInfo: IUserInfo): boolean {
    return this.hasPermissionForComp(
      userInfo,
      -99,
      -99,
      SECURITY_ROLES.BUILDER,
      false
    );
  }

  public hasBuilderPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number,
    checkAgainstOrgComp: boolean = true
  ): boolean {
    return this.hasPermissionForComp(
      userInfo,
      orgId,
      compId,
      SECURITY_ROLES.BUILDER,
      checkAgainstOrgComp
    );
  }

  public hasCheckinPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number
  ): boolean {
    return (
      this.hasPermissionForComp(
        userInfo,
        orgId,
        compId,
        SECURITY_ROLES.CHECKIN
      ) || this.hasBuilderPermissionForComp(userInfo, orgId, compId)
    );
  }

  public hasTicketPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number
  ): boolean {
    return (
      this.hasPermissionForComp(
        userInfo,
        orgId,
        compId,
        SECURITY_ROLES.TICKET
      ) || this.hasBuilderPermissionForComp(userInfo, orgId, compId)
    );
  }

  public hasScoreBoardPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number
  ): boolean {
    return (
      this.hasPermissionForComp(
        userInfo,
        orgId,
        compId,
        SECURITY_ROLES.SCOREBOARD
      ) || this.hasBuilderPermissionForComp(userInfo, orgId, compId)
    );
  }

  /**
   *
   * @param userInfo
   * @param orgId
   * @param compId
   * @param checkAgainstOrgComp       Most likely want this true, so matches the user against their org.
   */
  public hasResultsPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number,
    checkAgainstOrgComp: boolean = true
  ): boolean {
    return this.hasPermissionForComp(
      userInfo,
      orgId,
      compId,
      SECURITY_ROLES.RESULTS,
      checkAgainstOrgComp
    );
  }

  public hasChequesPermissionForOrg(userInfo: IUserInfo): boolean {
    //  38 is National.
    // return this.hasPermissionForComp(userInfo, 38, -99, SECURITY_ROLES.FINANCE, true);

    if (userInfo.user?.role === "E4SUSER") {
      return true;
    }

    if (userInfo.security && userInfo.security.permissions) {
      return userInfo.security.permissions.reduce(
        (accum: boolean, perm: IConfigPermission) => {
          if (
            (perm.role.toUpperCase() === SECURITY_ROLES.ADMIN ||
              perm.role.toUpperCase() === SECURITY_ROLES.FINANCE) &&
            perm.orgname.toUpperCase() === "NATIONAL"
          ) {
            return true;
          }
          return accum;
        },
        false
      );
    }
    return false;
  }

  public hasAAIFinancePermissionForAnyOrg(userInfo: IUserInfo): boolean {
    //  38 is National
    // return this.hasPermissionForComp(userInfo, 38, -99, SECURITY_ROLES.FINANCE, true);

    if (userInfo.user?.role === "E4SUSER") {
      return true;
    }

    if (userInfo.security && userInfo.security.permissions) {
      return userInfo.security.permissions.reduce(
        (accum: boolean, perm: IConfigPermission) => {
          if (
            (perm.role.toUpperCase() === SECURITY_ROLES.ADMIN ||
              perm.role.toUpperCase() === SECURITY_ROLES.FINANCE) &&
            perm.orgname.toUpperCase() === "NATIONAL"
          ) {
            return true;
          }
          return accum;
        },
        false
      );
    }
    return false;
  }

  public hasFinancePermissionForAnyOrg(userInfo: IUserInfo): boolean {
    //  38 is National
    // return this.hasPermissionForComp(userInfo, 38, -99, SECURITY_ROLES.FINANCE, true);

    if (userInfo.user?.role === "E4SUSER") {
      return true;
    }

    if (userInfo.security && userInfo.security.permissions) {
      return userInfo.security.permissions.reduce(
        (accum: boolean, perm: IConfigPermission) => {
          if (
            perm.role.toUpperCase() === SECURITY_ROLES.ADMIN ||
            perm.role.toUpperCase() === SECURITY_ROLES.FINANCE
          ) {
            return true;
          }
          return accum;
        },
        false
      );
    }
    return false;
  }

  public hasPermissionForComp(
    userInfo: IUserInfo,
    orgId: number,
    compId: number,
    permissionName: string,
    checkAgainstOrgComp: boolean = true
  ): boolean {
    if (userInfo.user?.role === "E4SUSER") {
      return true;
    }

    if (!userInfo || !orgId || !compId || compId === 0) {
      return false;
    }

    if (userInfo.security && userInfo.security.permissions) {
      return userInfo.security.permissions.reduce(
        (accum: boolean, perm: IConfigPermission) => {
          if (
            perm.role.toUpperCase() === SECURITY_ROLES.ADMIN ||
            perm.role.toUpperCase() === permissionName.toUpperCase()
          ) {
            if (checkAgainstOrgComp) {
              if (perm.orgid === 0) {
                return true;
              }

              if (perm.orgid === orgId) {
                if (perm.compid === 0 || perm.compid === compId) {
                  return true;
                }
              }
              return accum;
            }
            //  Not checkAgainstOrgComp, so if user has Admin or Builder, good enough.
            return true;
          }
          return accum;
        },
        false
      );
    }
    return false;
  }

  public isThereHealthIssue(configApp: IConfigApp): boolean {
    if (!configApp.options.health) {
      return true;
    }
    const entryCheck = this.isConfigHealthStatusLate(
      configApp.options.health.entryCheck
    );
    const healthMonitor = this.isConfigHealthStatusLate(
      configApp.options.health.healthMonitor
    );
    const orderCheck = this.isConfigHealthStatusLate(
      configApp.options.health.orderCheck
    );
    const paymentStatus = this.isConfigHealthStatusLate(
      configApp.options.health.paymentStatus
    );
    const waitingList = this.isConfigHealthStatusLate(
      configApp.options.health.waitingList
    );

    return (
      entryCheck || healthMonitor || orderCheck || paymentStatus || waitingList
    );
  }

  public isConfigHealthStatusLate(
    configHealthStatus: IConfigHealthStatus
  ): boolean {
    if (configHealthStatus.lastRun.length === 0) {
      return true;
    }

    const diff = differenceInSeconds(
      new Date(),
      parse(configHealthStatus.lastRun)
    );
    return diff > configHealthStatus.every;
  }

  public factoryConfigStoreState(): IConfigStoreState {
    return {
      configApp: this.factoryConfigApp(),
      configMeta: {
        events: [],
        locations: [],
        organisers: [],
      },
      il8nLanguage: "en",
      athleticOrgObj: {},
      userEntity: this.factoryEntity(),
      ui: {
        version: "v1",
      },
    };
  }

  public isUserAdmin(configApp: IConfigApp): boolean {
    return configApp.role === USER_ROLES.E4SUSER;
  }

  public getRegistrationSystemAoCode(configApp: IConfigApp): AO_CODE {
    return configApp.defaultao?.code || "";
  }
}
