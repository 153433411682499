import * as R from "ramda";
import {
  ICompetition,
  ICompetitionStatus,
  ICompetitionInfo,
  ICompetitionSummaryPublic,
  ICompetitionSummary,
  IPublicPermissions,
  IContactPublic,
  ICompSummary,
} from "../competition/competition-models";
import {
  differenceInSeconds,
  format,
  isAfter,
  isBefore,
  parse,
  subHours,
  subSeconds,
} from "date-fns";
import { IStatusBase } from "./status/status-models";
import { BuilderService } from "../builder/builder-service";
import {
  ENTRY_DEFAULT_PANEL,
  IBuilderCompetition,
  IBuilderOptions,
  ISelfService,
} from "../builder/builder-models";
import { LocationService } from "../location/location-service";
import { ICompShedRuleConfig } from "../athletecompsched/athletecompsched-models";

const builderService: BuilderService = new BuilderService();

export class CompetitionService {
  public factorySummaryPublic(): ICompetitionSummaryPublic {
    return {
      active: false,
      id: 0,
      club: "",
      clubId: 0,
      compName: "",
      compOrgId: 0,
      compId: 0,
      closedate: "",
      clubCompInfo: {
        //  If use factory....() function here, some cyclical import webpack can't figure out.
        configData: {
          bibNos: [],
          categoryId: 0,
          categoryName: "",
          clubId: 0,
          clubName: "",
          clubCompId: 0,
          maxEntries: 0,
          maxPerEvent: 0,
          maxRelays: 0,
        },
        entryData: {
          entries: {},
          entryCnt: 0,
          teams: {},
          teamCnt: 0,
        },
        clubs: [],
      },
      compAgeGroups: [],
      opendate: "",
      location: new LocationService().factoryGetLocation(),
      dates: [],
      areaname: "",
      saleenddate: "",
      access: "",
      isClosed: false,
      reportAccess: false,
      reportId: "",
      reportLastChecked: "",
      entries: {
        athletes: 0,
        team: 0,
        indiv: 0,
        isTeam: false,
      },
      eventTypes: {
        indivEvents: true,
        teamEvents: false,
        tickets: false,
      },
      link: "",
      logo: "",
      options: new BuilderService().factoryOptions(),
      organisers: [],
      permissions: this.factoryPublicPermissions(),
      status: this.factoryStatus(),
      information: "",
      termsConditions: "",
      compRules: [],
      newsFlash: "",
    };
  }

  public factoryCompetitionSummary(): ICompetitionSummary {
    return {
      id: 0,
      name: "",
      organiser: {
        id: 0,
        name: "",
      },
      location: {
        id: 0,
        name: "",
      },
      date: "",
      dates: [],
    };
  }

  public factoryCompetitionInfo(): ICompetitionInfo {
    return {
      active: false,
      id: 0, //  this is coming up as "ID" in the API response.
      compId: 0,
      compName: "",
      areaid: 0,
      link: "",
      logo: "",
      locationid: 0,
      entity: "",
      entityid: 0,
      compOrgId: 0,
      name: "",
      tandclink: "",
      yearFactor: "",
      date: "",
      entriesClose: "",
      tandc: {
        link: "",
        description: "",
      },
      teamid: 0,
      indivEvents: true,
      teamEvents: false,
      compRules: [],
      options: builderService.factoryOptions(),
      information: "",
      clubCompInfo: {
        //  If use factory functions, webpack cyclical thingy
        configData: {
          bibNos: [],
          categoryId: 0,
          categoryName: "",
          clubId: 0,
          clubName: "",
          clubCompId: 0,
          maxEntries: 0,
          maxPerEvent: 0,
          maxRelays: 0,
        },
        entryData: {
          entries: {},
          entryCnt: 0,
          teams: {},
          teamCnt: 0,
        },
        clubs: [],
      },
      compAgeGroups: [],
    };
  }

  public factoryCompSummary(): ICompSummary {
    return {
      active: false,
      IndoorOutdoor: "",
      link: "",
      yearFactor: 0,
      areaid: 0,
      options: {
        isClubComp: false,
        allowExpiredRegistration: true,
        disabled: false,
        homeInfo: "",
        shortCode: "",
        priority: { required: false, code: "", dateTime: "", message: "" },
        bibNos: "1",
        bibSort1: "surname",
        bibSort2: "firstname",
        bibSort3: "dob",
        heatOrder: "s",
        stadium: "",
        contact: {
          email: "",
          id: 0,
          tel: "",
          userName: "",
          visible: true,
        },
        cardInfo: {
          enabled: true,
          availableFrom: "",
        },
        subscription: {
          enabled: false,
          timeCloses: "",
          organiserMessage: "",
          e4sMessage: "",
          refunded: "",
          process: true,
          processRefundTime: "",
        },
        cloneInfo: { fromId: 0 },

        checkIn: {
          enabled: false,
          checkInDateTimeOpens: "",
          defaultFrom: 180,
          defaultTo: 60,
          qrCode: true,
          text: "",
          terms: "",
          useTerms: false,
          seedOnEntries: false,
        },
        school: false,
        cheques: { allow: false, ends: "" },
        allowAdd: { unregistered: false, registered: true },
        timetable: "Provisional",
        helpText: { schedule: "", teams: "", cart: "" },
        showTeamAthletes: false,
        singleAge: false,
        athleteSecurity: { areas: [], clubs: [], onlyClubsUpTo: "" },
        ui: {
          enterButtonText: "Enter",
          entryDefaultPanel: "SCHEDULE",
          ticketComp: 0,
          ticketCompButtonText: "Buy Tickets",
          sectionsToHide: {
            SCHEDULE: false,
            ATHLETES: false,
            SHOP: false,
            TEAMS: false,
          },
          ticketCompBase: { id: 0, name: "" },
        },
        athleteQrData: false,
        disabledReason: "",
        paymentCode: "",
        laneCount: 8,
        compLimits: { athletes: 0, entries: 0, teams: 0 },
        stopReport: false,
        cancelEvent: {
          hrsBeforeClose: 0,
          refund: { allow: false, type: "E4S_FEES" },
          credit: { allow: false },
        },
        resultsAvailable: false,
        autoEntries: {
          selectedTargetComp: { id: 0, name: "", timeTronicsUrl: "" },
          targetable: { allowedSources: [], enabled: false },
        },
        level: "",
        pfTargetDirectory: "",
        dates: [],
        clubComp: false,
        pbMandatory: true,
      } as any as IBuilderOptions,
      teamid: null,
      lastentrymod: "",
      information: "",
      r4s: null,
      waitingrefunded: null,
      resultsAvailable: 0,
      areaname: "",
      today: "",
      systemtime: "",
      compDate: "",
      daysToComp: 0,
      daysToClose: 0,
      location: {
        id: 0,
        name: "",
        address1: "",
        address2: "",
        town: "",
        postcode: "",
        county: "",
        map: "",
        directions: "",
        website: "",
      },
      loccontact: "",
      logo: "",
      ctcid: null,
      maxathletes: null,
      maxteams: null,
      maxmale: null,
      maxfemale: null,
      maxagegroup: null,
      uniqueevents: null,
      singleagegroup: null,
      ctc: {
        ctcid: null,
        maxathletes: null,
        maxteams: null,
        maxmale: null,
        maxfemale: null,
        maxagegroup: null,
        uniqueevents: null,
        singleagegroup: null,
      },
      compOrgId: 0,
      compName: "",
      entityid: null,
      opendate: "",
      closedate: "",
      club: "",
      dates: [],
      saleenddate: null,
      entries: {
        eventCount: 0,
        teamEventCount: 0,
        indivEventCount: 0,
        indiv: 0,
        waitingCount: 0,
        uniqueIndivAthletes: 0,
        athletes: 0,
      },
      access: "",
      permissions: {
        adminMenu: false,
        builder: false,
        check: false,
        report: false,
      },
      reportId: "",
      reportAccess: false,
      status: {
        id: 0,
        compid: 0,
        description: "No Status",
        status: "NO_STATUS",
        invoicelink: "",
        reference: "",
        notes: "",
        value: 0,
        code: 0,
        wfid: 0,
        previd: 0,
        prevdescription: "",
        nextdescription: "",
        nextid: 0,
      },
      organisers: [],
      compId: 0,
      compRules: null,
      eventTypes: {
        indivEvents: false,
        teamEvents: false,
        tickets: false,
      },
      clubCompInfo: {},
      compAgeGroups: [],
    };
  }

  public factoryStatus(): ICompetitionStatus {
    return {
      id: 0,
      compid: 0,
      code: 0,
      description: "",
      nextid: 0,
      nextdescription: "",
      paid: 0,
      prevdescription: "",
      previd: 0,
      invoicelink: "",
      wfid: 0,
      ref: "",
      notes: "",
      value: 0,
      status: "",
      moveto: 0,
    };
  }

  public factoryContactPublic(): IContactPublic {
    return {
      id: 0,
      email: "",
      tel: "",
      userName: "",
    };
  }

  public factoryPublicPermissions(): IPublicPermissions {
    return {
      builder: false,
      check: false,
      report: false,
    };
  }

  public factoryStatusBase(
    competition: ICompetitionSummaryPublic
  ): IStatusBase {
    return {
      compid: competition.compId,
      invoicelink: "",
      paid: 0,
      moveto: competition.status ? competition.status.wfid : 0,
    } as IStatusBase;
  }

  public displayCompetitionFlyer(competition: ICompetitionInfo): boolean {
    if (competition.link && competition.link.length > 0) {
      return true;
    }
    return false;
  }

  public displayAddAthleteButton(competition: ICompetitionInfo): boolean {
    if (
      competition &&
      competition.options &&
      competition.options.allowAdd &&
      (competition.options.allowAdd.registered ||
        competition.options.allowAdd.unregistered)
    ) {
      return true;
    }
    return false;
  }

  public canAddRegisteredUsers(competition: ICompetitionInfo): boolean {
    return (
      competition &&
      competition.options &&
      competition.options.allowAdd &&
      competition.options.allowAdd.registered
    );
  }

  public canAddUnregisteredUsers(competition: ICompetitionInfo): boolean {
    return (
      competition &&
      competition.options &&
      competition.options.allowAdd &&
      competition.options.allowAdd.unregistered
    );
  }

  public isTeamCompetition(competition: ICompetitionInfo) {
    return competition.teamid && competition.teamid > 0 ? true : false;
  }

  public isEntryOpen(
    competition: ICompetitionSummaryPublic | ICompSummary,
    isoNow?: string
  ): boolean {
    const dateNow =
      R.isNil(isoNow) || R.isEmpty(isoNow) ? new Date() : parse(isoNow);
    const openDate = parse(competition.opendate);
    const closeDate = parse(competition.closedate);
    return isAfter(dateNow, openDate) && isBefore(dateNow, closeDate);
  }

  public isOpen(
    competition: ICompetitionSummaryPublic | ICompSummary,
    isoNow?: string
  ) {
    const dateNow =
      R.isNil(isoNow) || R.isEmpty(isoNow) ? new Date() : parse(isoNow);
    const openDate = parse(competition.opendate);
    return isAfter(dateNow, openDate);
  }

  public isClosed(closeDateIso: string, isoNow?: string) {
    const dateNow =
      R.isNil(isoNow) || R.isEmpty(isoNow) ? new Date() : parse(isoNow);
    const closeDate = parse(closeDateIso);
    return isAfter(dateNow, closeDate);
  }

  public isPastCompDate(
    competition: ICompetitionSummaryPublic | ICompSummary,
    isoNow?: string
  ) {
    const dateNow =
      R.isNil(isoNow) || R.isEmpty(isoNow) ? new Date() : parse(isoNow);
    if (competition.dates.length === 0) {
      return false;
    }
    //  Take the "last" date, as might be multi-day.
    const compDate = parse(competition.dates[competition.dates.length - 1]);
    return isAfter(dateNow, compDate);
  }

  public getPublicCardOpenCloseMessage(
    competition: ICompetitionSummaryPublic | ICompSummary
  ): string {
    if (competition.options.disabled) {
      return competition.options.disabledReason;
    }
    // const hack: string = (competition.options.live as any as string).toString();
    // if (hack !== "true" && hack !== "false") {
    //   return hack;
    // }
    if (!this.isLive(competition)) {
      return "Not Active";
    }
    const pattern = "Do MMM HH:mm";
    if (!this.isOpen(competition)) {
      return "Opens " + format(parse(competition.opendate), pattern);
    }

    const hasCompReachedEntryLimits =
      this.hasCompReachedEntryLimits(competition);
    if (
      hasCompReachedEntryLimits.athletes ||
      hasCompReachedEntryLimits.indivs
    ) {
      const maxMessages = [];

      if (hasCompReachedEntryLimits.athletes) {
        maxMessages.push(
          "Max athletes reached: " + competition.options.compLimits.athletes
        );
      }
      if (hasCompReachedEntryLimits.indivs) {
        maxMessages.push(
          "Max entries reached: " + competition.options.compLimits.entries
        );
      }
      return "Closed: " + maxMessages.join(", ");
    }

    if (this.isClosed(competition.closedate)) {
      return "Closed " + format(parse(competition.closedate), pattern);
    }

    if (this.hasSaleEndDate(competition)) {
      // const dateTime = this.getSaleEndDateIso(competition);
      // let dateSaleEnd = parse(dateTime);
      // dateSaleEnd = subSeconds(dateSaleEnd, 60);
      // if (this.isPastLateEntry(competition)) {
      //     // return "Late Entries Close " + format(dateSaleEnd, pattern);
      //     return "Late Entries Close " + format(parse(competition.closedate), pattern);
      // }
      // return "Std Entries Close " + format(dateSaleEnd, pattern);
      return this.getSaleEndMessage(competition);
    }
    return "Entries Close " + format(parse(competition.closedate), pattern);
  }

  public getSaleEndMessage(
    competition: ICompetitionSummaryPublic | ICompSummary
  ): string {
    const pattern = "Do MMM HH:mm";
    let dateSaleEnd = parse(this.getSaleEndDateIso(competition));
    dateSaleEnd = subSeconds(dateSaleEnd, 60);
    if (this.isPastLateEntry(competition)) {
      return (
        "Late Entries Close " + format(parse(competition.closedate), pattern)
      );
    }
    return "Std Entries Close " + format(dateSaleEnd, pattern);
  }

  public isPastLateEntry(
    competition: ICompetitionSummaryPublic | ICompSummary,
    now?: Date
  ): boolean {
    if (this.hasSaleEndDate(competition)) {
      const dateTime = this.getSaleEndDateIso(competition);

      if (dateTime.split(" ")[0] === "0000-00-00") {
        return false;
      }

      const dateSaleEnd = parse(dateTime);
      const dateNow = parse(now ? now : new Date());

      if (isAfter(dateNow, dateSaleEnd)) {
        return true;
      }
    }
    return false;
  }

  public getSaleEndDateIso(
    competition: ICompetitionSummaryPublic | ICompSummary
  ): string {
    if (this.hasSaleEndDate(competition)) {
      const saleEndDate = competition.saleenddate!.split(" ");
      return saleEndDate[0] + "T" + saleEndDate[1];
    }
    return "";
  }

  public showReportLink(comp: ICompetitionSummaryPublic): boolean {
    return comp.reportAccess;
  }

  // public showCheckInLink(comp: ICompetitionSummaryPublic): boolean {
  //     return comp.options.checkIn && comp.options.checkIn.enabled;
  // }

  public getSaleEndDateMobile(comp: ICompetitionSummaryPublic) {
    const date = this.getSaleEndDate(comp);
    return date.length > 0 ? date + " (Price Increase)" : "";
  }

  public getSaleEndDate(comp: ICompetitionSummaryPublic | ICompSummary) {
    if (!comp.saleenddate) {
      return "";
    }
    if (this.hasSaleEndDate(comp)) {
      return format(parse(comp.saleenddate), "Do MMM YYYY");
    }
    return "";
  }

  public getClosingDate(comp: ICompetitionSummaryPublic) {
    return format(parse(comp.closedate), "Do MMM YYYY");
  }

  public hasSaleEndDate(
    comp: ICompetitionSummaryPublic | ICompSummary
  ): boolean {
    return !!(comp.saleenddate && comp.saleenddate.length > 0);
  }

  public canUserEnter(
    comp: ICompetitionSummaryPublic | ICompSummary,
    isoNow?: string
  ): boolean {
    // const hack: string = (comp.active).toString();
    // if ( hack !== "true" && hack !== "false") {
    //     return false;
    // }

    if (comp.options.disabled) {
      return false;
    }
    if (!this.isLive(comp)) {
      return false;
    }
    if (this.isClosed(comp.closedate, isoNow)) {
      return false;
    }

    const hasCompReachedEntryLimits = this.hasCompReachedEntryLimits(comp);
    // console.log(comp.compId + " canUserEnter() hasCompReachedEntryLimits: " + JSON.stringify(hasCompReachedEntryLimits));
    if (
      hasCompReachedEntryLimits.athletes ||
      hasCompReachedEntryLimits.indivs ||
      hasCompReachedEntryLimits.teams
    ) {
      // console.log(comp.compId + " canUserEnter() >>>  NO");
      return false;
    }

    return this.isOpen(comp);
  }

  public hasCompReachedEntryLimits(
    comp: ICompetitionSummaryPublic | ICompSummary
  ): {
    athletes: boolean;
    indivs: boolean;
    teams: boolean;
  } {
    let athletesOver = false;
    let indivsOver = false;
    let teamsOver = false;
    if (comp.options && comp.options.compLimits) {
      if (
        comp.options.compLimits.athletes &&
        comp.options.compLimits.athletes > 0
      ) {
        athletesOver =
          comp.entries.athletes >= comp.options.compLimits.athletes;
      }
      if (
        comp.options.compLimits.entries &&
        comp.options.compLimits.entries > 0
      ) {
        indivsOver = comp.entries.indiv >= comp.options.compLimits.entries;
      }

      if (comp.options.compLimits.teams && comp.options.compLimits.teams > 0) {
        const teamsCount = (comp as ICompSummary).entries.teamEventCount
          ? (comp as ICompSummary).entries.teamEventCount
          : (comp as ICompetitionSummaryPublic).entries.team;
        teamsOver = teamsCount >= comp.options.compLimits.teams;
      }
    }
    return {
      athletes: athletesOver,
      indivs: indivsOver,
      teams: teamsOver,
    };
  }

  public canUserCheckIn(comp: ICompetitionSummaryPublic): boolean {
    if (!this.isOpen(comp) || !comp.options.checkIn) {
      return false;
    }
    const today = format(new Date(), "YYYY-MM-DD");
    return comp.dates.indexOf(today) > -1;
  }

  public isLive(
    competition: ICompetition | ICompetitionSummaryPublic | ICompSummary
  ): boolean {
    return competition.active;
  }

  public hasReportNotRun(comp: ICompetitionSummaryPublic): boolean {
    const reportLastChecked = comp.reportLastChecked;
    if (R.isNil(reportLastChecked) || R.isEmpty(reportLastChecked)) {
      return true;
    }

    const dateFnsNow = format(new Date());
    const dateFnsReport = format(reportLastChecked);

    const diffInSecs = differenceInSeconds(dateFnsNow, dateFnsReport);
    const diffInHours = diffInSecs / 3600;
    return diffInHours > 2;
  }

  public hasContactInfo(comp: ICompetitionInfo) {
    //  This does not work....???
    // return comp!.options!.contact!.email || comp!.options!.contact!.tel || comp!.options!.contact!.userName;
    if (comp.options && comp.options.contact) {
      const contact = comp.options.contact;
      //  Doing return (contact.userName && contact.userName.length > 0) || etc. does not work....????
      if (
        (contact.userName && contact.userName.length > 0) ||
        (contact.tel && contact.tel.length > 0) ||
        (contact.email && contact.email.length > 0)
      ) {
        return true;
      }
    }
    return false;
  }

  public getServServiceEntities(comp: ICompetitionInfo): ISelfService[] {
    return comp && comp.options && comp.options.selfService
      ? comp.options.selfService
      : [];
  }

  public isSchool(comp: ICompetitionInfo) {
    return comp && comp.options && comp.options.school
      ? comp.options.school
      : false;
  }

  public filterPublicCompetitions(
    filterTerm: string,
    comps: ICompetitionSummaryPublic[]
  ): ICompetitionSummaryPublic[] {
    if (filterTerm.length === 0) {
      return comps;
    }

    filterTerm = filterTerm.toLowerCase();

    const compIdPredicate = (comp: ICompetitionSummaryPublic): boolean => {
      return comp.compId.toString().toLowerCase().indexOf(filterTerm) > -1;
    };

    const compNamePredicate = (comp: ICompetitionSummaryPublic): boolean => {
      return comp.compName.toLowerCase().indexOf(filterTerm) > -1;
    };

    const compClubPredicate = (comp: ICompetitionSummaryPublic): boolean => {
      return comp.club.toLowerCase().indexOf(filterTerm) > -1;
    };

    const locationPredicate = (comp: ICompetitionSummaryPublic): boolean => {
      return comp.location.name.toLowerCase().indexOf(filterTerm) > -1;
    };

    return comps.filter((comp: ICompetitionSummaryPublic) => {
      return (
        compIdPredicate(comp) ||
        compNamePredicate(comp) ||
        locationPredicate(comp) ||
        compClubPredicate(comp)
      );
    });
  }

  public getCompetitionTitle(comp: ICompetitionSummaryPublic | ICompSummary) {
    const isOrgInCompName =
      comp.compName.toLowerCase().indexOf(comp.club.toLowerCase()) > -1;
    return (
      comp.compId +
      ": " +
      (isOrgInCompName ? "" : comp.club + " - ") +
      comp.compName
    );
  }

  public cancelsNotPermittedAfter(
    comp: ICompetitionSummaryPublic | IBuilderCompetition
  ): Date {
    let closeDate;
    if ("entriesCloseDateTime" in comp) {
      closeDate = comp.entriesCloseDateTime;
    } else {
      closeDate = comp.closedate;
    }

    const compCloses = parse(closeDate);
    const offsetHrsPriorToComp =
      comp.options.cancelEvent && comp.options.cancelEvent.hrsBeforeClose
        ? comp.options.cancelEvent.hrsBeforeClose
        : 48;
    return subHours(compCloses, offsetHrsPriorToComp);
  }

  public isCancelStillAvailable(
    comp: ICompetitionSummaryPublic,
    timeNow?: Date
  ): boolean {
    const seedTime = timeNow ? timeNow : new Date();
    // const offsetHrsPriorToComp = comp.options.cancelEvent && comp.options.cancelEvent.hrsBeforeClose ?
    //     comp.options.cancelEvent.hrsBeforeClose :
    //     48;
    // const compCloses = parse(comp.closedate);
    // const cancelCloses = subHours(compCloses, offsetHrsPriorToComp);
    const cancelCloses = this.cancelsNotPermittedAfter(comp);
    return isBefore(seedTime, cancelCloses);
  }

  public getCompFullName(comp: ICompetitionSummaryPublic): string {
    const compDate = format(parse(comp.dates[0]), "Do MMM YYYY");
    return (
      comp.compId + ": " + comp.club + " - " + comp.compName + " - " + compDate
    );
  }

  public getCompRuleForAgeGroup(
    comp: ICompetitionSummaryPublic,
    ageGroupId: number
  ): ICompShedRuleConfig | null {
    if (comp.compRules && comp.compRules.length > 0) {
      const compRule: ICompShedRuleConfig | null = comp.compRules.reduce(
        (accum: ICompShedRuleConfig | null, rule) => {
          if (rule.agid === 0 || rule.agid === ageGroupId) {
            accum = rule;
          }
          return accum;
        },
        null
      );
      return compRule;
    }
    return null;
  }

  public getEnterButtonText(builderOptions: IBuilderOptions) {
    if (
      builderOptions &&
      builderOptions.ui &&
      builderOptions.ui.enterButtonText
    ) {
      return builderOptions.ui.enterButtonText;
    }
    return "Enter";
  }

  public getEntryDefaultPanel(
    builderOptions: IBuilderOptions
  ): ENTRY_DEFAULT_PANEL {
    if (
      builderOptions &&
      builderOptions.ui &&
      builderOptions.ui.entryDefaultPanel === "SHOP"
    ) {
      return builderOptions.ui.entryDefaultPanel;
    }
    return "SCHEDULE";
  }

  public isPriorityRequired(comp: ICompetitionSummaryPublic, timeNow?: string) {
    if (!comp.options.priority.required) {
      return false;
    }

    const priorityDate =
      comp.options.priority.dateTime.length > 0
        ? comp.options.priority.dateTime
        : "";

    if (priorityDate.length === 0) {
      //  Required for entire length of comp open for entry.
      return true;
    }

    return isBefore(new Date(), parse(priorityDate));
  }
}
