import { IEventUom } from "../athletecompsched/athletecompsched-models";
import { ILocation } from "../location/location-models";
import { IOrg } from "../org/org-models";
import { ICompEvent } from "../compevent/compevent-models";
import { IPrice } from "../price/price-models";
import { IAgeGroupCompCoverageModel } from "../agegroup/agegroup-models";
import { IDiscount } from "../discount/discount-models";
import { ICompRule } from "../comprule/comprule-models.";
import { IArea } from "../area/area-models";
import {
  IBase,
  IBaseConcrete,
  IsoDate,
  IsoDateTime,
} from "../common/common-models";
import { IUserSummary } from "../admin/user/user-models";
import { CLUB_TYPE, E4sTheme, IEntityLevel } from "../config/config-app-models";
import { IValidateCompResponseMessage } from "../competition/v2/competition-data-v2";
import { ClubType } from "../club/crud/club-data-crud";

export interface IBuilderTeamConfig {
  id: number;
  maxAthletes: number;
  maxTeams: number;
  maxMale: number;
  maxFemale: number;
}

export const INDOOR_OUTDOOR = {
  NO_SELECTION: {
    label: "Please Select",
    value: "",
  },
  INDOOR: {
    label: "Indoor",
    value: "Indoor",
  },
  OUTDOOR: {
    label: "Outdoor",
    value: "Outdoor",
  },
};

export enum AO_CODES {
  EA = "EA",
  ANI = "ANI",
  IRL = "IRL",
}

export interface IBuilderCloneRequest {
  env: string; //  The AoCode, e.g. IRL, UK, ANI
  id: number; //  Comp Id.
}

export interface IBuilderPermission {
  levelid: number;
  level: string;
}

export interface IContact extends IBase {
  userName: string;
  tel: string;
  email: string;
  visible: boolean; //  applies to email and tel.
  socials?: []; //  E.g. Twitter, Fb, etc.
}

export interface IAudit extends IBase {
  compid: number;
  userid: number;
  reason: string;
  created: string;
  userName: string;
}

export interface IBuilderSubscription {
  enabled: boolean; //  Does comp allow
  timeCloses: string; //  when this time reached, prob 24hrs prior to comp, don't allow any more.
  organiserMessage: string; //  plain text
  e4sMessage: string; //  HTML  System wide, E4S edit only.
  readonly refunded: IsoDateTime; // Date refund actually  done ( read Only )
  process: boolean; // false = Do NOT Process movements of the waiting lists, true = Process ( NEW property )
  processRefundTime: IsoDateTime; //  The time the refunds will
}

export interface IBuilderOrgUsers {
  compId: number;
  orgId: number;
  permId: number;
  role: IBaseConcrete;
  user: {
    id: number;
    displayName: string;
    userEmail: string;
  };
}

export interface IBuilderCompetition {
  id: number;
  compOrg: IOrg; //  lookup or new
  active: boolean;
  location: ILocation; //  Lookup or + add button
  date: string; //  of comp
  name: string; //  free text
  entriesOpenDateTime: string; //  date-time picker
  entriesCloseDateTime: string;
  indoorOutdoor: string; //  select
  options: IBuilderOptions; //  check box   registered, unreg
  organisers: IBuilderOrgUsers[];
  link: string; //  Link to flyer
  yearFactor: number; //  input 4 chars
  area: IArea; //  N.B.  This restricts the competition to specific users...NOT athletes.
  //  @See IBuilderOptions.athleteSecurity for athlete security.
  teamConfigId: number;
  teamConfig: IBuilderTeamConfig;
  reportLink: string;
  information: string; //  html
  termsConditions: string; //  html
  newsFlash: string; //  html
  emailText: string; //  html

  tandclink: string; //  ignore for now
  tandcdesc: string; //  ignore for now

  e4sNotes: string;
  singleAge: boolean;
  audits: IAudit[];
  meta: {
    compEvents: ICompEvent[];
    ageGroups: IAgeGroupCompCoverageModel[];
    prices: IPrice[];
    rules: ICompRule[];
    discounts: IDiscount[];
    multiEvents: any[]; // To be discussed.
    access: IBuilderPermission[];
    validation?: IValidateCompResponseMessage[];
  };
  pfInfo: {
    key: string; //  TODO readonly
  };
}

export type ENTRY_DEFAULT_PANEL = "SCHEDULE" | "SHOP";
export type ENTRY_SECTION = "ATHLETES" | "TEAMS" | ENTRY_DEFAULT_PANEL;

export const EntryDefaultPanels = ["SCHEDULE", "SHOP", "SHOP_ONLY"] as const;
export type EntryDefaultPanel = typeof EntryDefaultPanels[number];

export type BuilderUiSectionsToHide = Record<ENTRY_SECTION, boolean>;

export interface IBuilderSectionUi {
  enterButtonText: string;
  entryDefaultPanel: EntryDefaultPanel;
  ticketComp: number; //  Use this on a live comp to set a "link" to an anonymous ticket comp.
  ticketCompBase: IBaseConcrete;
  ticketCompButtonText: string;
  sectionsToHide: BuilderUiSectionsToHide; // Use this to override which sections show.
}

export interface IBuilderOptions {
  homeInfo?: string; //  Some brief text to show on card.
  allowAdd: {
    unregistered: boolean;
    registered: boolean;
    international: boolean;
  };
  school: boolean;
  athleteQrData: boolean;
  // live: boolean;                      //  Allows release of comp for org'r to check over without public seeing.
  disabled: boolean; //  Visible, but not "enterable"
  disabledReason: string; //  Short text as to why disabled.
  compDates: string[];
  bibNos: string;
  bibSort1: string;
  bibSort2: string;
  bibSort3: string;
  paymentCode: string; //  This is code that allows org'r to link Stripe to a comp.
  laneCount: number; //  How many lanes does the track have.
  heatOrder: string; //  s = slowest, f = fastest
  cardInfo: {
    enabled: boolean; //  True = comp is using our T&F.
    availableFrom: IsoDateTime; //  Make cards available from this date, not when entries close
  };
  cheques: {
    allow: boolean;
    ends: string; //  Iso Datetime
  };
  helpText: {
    schedule: string; //  "panel" header help text, additional to the server
    teams: string; //  generated text, e.g. specific to user x and price info.
    cart: string;
  };
  showTeamAthletes: boolean; //  When "Show Entries" spreadsheet, show athletes names or not.
  singleAge: boolean;
  contact: IContact;
  compLimits: {
    athletes: number;
    entries: number;
    teams: number;
  };
  selfService: ISelfService[];
  athleteSecurity: IAthleteSecurity;
  stopReport: boolean;
  checkIn: IBuilderOptionsCheckIn;
  priority: {
    required: boolean;
    code: string;
    dateTime: string;
    message: string;
  };
  subscription: IBuilderSubscription | undefined | null;
  cancelEvent: ICancelEvent;
  resultsAvailable: boolean;
  ui: IBuilderSectionUi;
  stadium?: string;
  autoEntries: IAutoEntries;
  level?: "" | "1" | "2";
  shortCode: string; //  if value here you can do e.g. entry4sports.co.uk/my-super-comp
  pfTargetDirectory: string; //  Directory that PF config file will monitor.
  allowExpiredRegistration: boolean;
  dates: IsoDate[];
  timetable: "Provisional" | "Final";
  clubComp: boolean; //  TODO deprecate
  isClubComp: boolean;
  pbMandatory: boolean;
  cloneInfo: {
    fromId: number;
    //   saved: boolean; // If true, then this comp has been saved at least once as a clone.  To do
    //  with validation on save
  };
  saleEndDate: IsoDate;
  genericCompAthleteEntity?: {
    //  E.g. the comp is being set up for police "clubs" or sailing "clubs", the athlete may or may not
    //  be a member of a real club, but we need them to select on of these "clubs".
    name: string; //  "police", "sailing club", etc.
    type: ClubType | "";
    option: "" | "POLICE" | "SAILING";
  };
}

export interface IBuilderValidationOptions {
  theme: E4sTheme;
  stripe: {
    useStripeConnect: boolean;
  };
}

export interface IBuilderOptionsCheckIn {
  enabled: boolean;
  checkInDateTimeOpens: string;
  defaultFrom: number;
  defaultTo: number; //  -1 = does not close.
  qrCode: boolean;
  text: string; //  This will output to the QR code print out sheet.
  useTerms: boolean; //  If yes, when athlete want to checkin, they MUST accept T&C.
  terms: string; //  If asy terms and conditions, e.g. COV19
  codes?: Record<string, number>; //  If have authority
  seedOnEntries: boolean; //  Don't seed on checked in.  false = only seed on athletes checked in.
}

export interface IAutoEntries {
  //  This is bit that is for the "Regional" comp.
  // source: {
  // targetComp: IBaseConcrete;       //  This is the targetComp
  // },

  //  This is bit that is for the "Regional" comp.
  selectedTargetComp: {
    id: number;
    name: string;
    timeTronicsUrl: string;
  }; //  This is the targetComp

  //  This is bit that is for the "National" comp.
  targetable: {
    enabled: boolean; //  Does this allow "feeder" entries from source.
    allowedSources: number[]; //  V2, so we can control sources.
  };
}
export interface ICancelEvent {
  hrsBeforeClose: number; //  At this many hours prior to Entries close, no more cancels.
  refund: {
    allow: boolean; //  Allows the org'r to refund.
    type: "ALL" | "E4S_FEES" | "PERCENT";
  };
  credit: {
    allow: boolean;
  };
}

export interface IAthleteSecurity {
  areas: IBase[];
  clubs: IBase[];
  onlyClubsUpTo: string;
}

export interface ISelfService extends IEntityLevel {
  id: number; //
  clubType: CLUB_TYPE;
  approvalUsers: IUserSummary[]; //  User
}

export interface IStep {
  stepNumber: number;
  stepName: string;
  back: boolean;
  next: boolean;
  isValid: boolean;
}

//  : Record<string, IStep>
export const BUILDER_STEPS = {
  STEP_GENERAL: {
    stepName: "General",
    stepNumber: 1,
    back: false,
    next: true,
    isValid: false,
  } as IStep,
  STEP_OPTIONS: {
    stepName: "Options",
    stepNumber: 2,
    back: true,
    next: true,
    isValid: false,
  } as IStep,
  STEP_ADD_EVENT: {
    stepName: "Events",
    stepNumber: 3,
    back: true,
    next: true,
    isValid: false,
  } as IStep,
  STEP_SCHEDULE: {
    stepName: "Schedule",
    stepNumber: 4,
    back: true,
    next: true,
    isValid: false,
  } as IStep,
  STEP_RULES: {
    stepName: "Rules",
    stepNumber: 5,
    back: true,
    next: true,
    isValid: false,
  } as IStep,
};

export interface IStepOutput {
  step: IStep;
  builderCompetition: IBuilderCompetition;
}

export const VALIDATION_NAMES = {
  COMP_ORG_EMPTY: "COMP_ORG_EMPTY",
  LOCATION_EMPTY: "LOCATION_EMPTY",
  AREA_EMPTY: "AREA_EMPTY",
  COMP_NAME_EMPTY: "COMP_NAME_EMPTY",
  COMP_DATE_BEFORE_NOW: "COMP_DATE_BEFORE_NOW",
  OPEN_DATE_NOT_IN_RANGE: "OPEN_DATE_NOT_IN_RANGE",
  CLOSE_DATE_NOT_IN_RANGE: "CLOSE_DATE_NOT_IN_RANGE",
  STRIPE_USER: "STRIPE_USER",
  CONTACT_NAME_EMPTY: "CONTACT_NAME_EMPTY",
  CONTACT_EMAIL_EMPTY: "CONTACT_EMAIL_EMPTY",
};

export const EMIT_ACTIONS = {
  NEXT_STEP: "nextStep",
  STEP_ONE: "stepOne",
};

export interface IUomLookup {
  id: number;
  eventUom: IEventUom[];
}

// export interface IEventGroup extends IBase {
//     compId: number;
//     eventNo: number;                //  Actually "maxGroup", but will be surfaced as a number
//     name: string;                   //  "800m Girls", "800m Open", etc.
// }

export type HtmlSectionName =
  | "T&C_TERMS"
  | "QR_MESSAGE"
  | "INFORMATION"
  | "TERMS_CONDITIONS"
  | "NEWS_FLASH"
  | "EMAIL_TEXT";

export interface IPhotoFinishFile {
  App: {
    FolderToWatch: string;
    PostUrl: string;
  };
  Logging: {
    LogLevel: {
      Default: "Information";
      Microsoft: "Warning";
      "Microsoft.Hosting.Lifetime": "Information";
    };
  };
}
