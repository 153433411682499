import * as R from "ramda";
import { Module } from "vuex";
import {
  IAgeGroup,
  IAgeGroupCompCoverageModel,
} from "../agegroup/agegroup-models";
import { IRootState } from "../app.store";
import { IEventE4S } from "../event/event-models";
import { ILocation } from "../location/location-models";
import { IOrg } from "../org/org-models";
import {
  IBuilderCompetition,
  IBuilderOptions,
  IBuilderPermission,
} from "./builder-models";
import { builderStoreActions } from "./builder-store-actions";
import { BUILDER_STORE_CONST } from "./builder-store-constants";
import { AgeGroupService } from "../agegroup/agegroup-service";
import { IEventUomType } from "../uom/uom-models";
import { IPrice } from "../price/price-models";
import { ICompEvent } from "../compevent/compevent-models";
import { BuilderStoreService } from "./builderStoreService";
import { CommonService } from "../common/common-service";
import { IBase, IObjectKeyType, IsoDate } from "../common/common-models";
import { IDiscount } from "../discount/discount-models";
import { ICompRule } from "../comprule/comprule-models.";
import { CompRuleService } from "../comprule/comprule-service";
import { IBuilderCompEvent } from "./buildercompevent/builder-comp-event-models";
import { IArea } from "../area/area-models";
import { BuilderService } from "./builder-service";
import { simpleClone } from "../common/common-service-utils";

const ageGroupService = new AgeGroupService();
const commonService: CommonService = new CommonService();
const builderStoreService: BuilderStoreService = new BuilderStoreService();
const compRuleService: CompRuleService = new CompRuleService();
const builderService: BuilderService = new BuilderService();

export interface IBuilderStoreState {
  builderCompetition: IBuilderCompetition;
  builderCompetitionLoading: boolean;
  builderCompetitionLoadingMessage: string;
  compOrgs: IOrg[];
  compOrgsLoading: boolean;
  locations: ILocation[];
  locationsLoading: boolean;
  areas: IArea[];
  areasLoading: boolean;
  ageGroupsForComp: IAgeGroupCompCoverageModel[];
  ageGroupsForCompLoading: boolean;
  ageGroupCompCoverageModels: IAgeGroupCompCoverageModel[];
  ageGroupsDefault: IAgeGroup[];
  ageGroupsDefaultLoading: boolean;
  ageGroupsAll: IAgeGroup[];
  ageGroupsAllLoading: boolean;
  ageGroupCreateLoading: boolean;
  events: IEventE4S[];
  eventsLoading: boolean;
  uoms: IEventUomType[];
  uomsLoading: boolean;
  pricesLoading: boolean;
  prices: IPrice[];

  stickyEntryDate: IsoDate;

  compEventLoading: boolean;
  // compEventBeingEdited: ICompEvent;
  builderCompEventBeingEdited: IBuilderCompEvent;
  compEventsSchedule: ICompEvent[]; //  TODO ICompEventSummary

  discounts: IDiscount[];
  compRules: ICompRule[];

  builderPermissions: IObjectKeyType<IBuilderPermission>;

  ui: {
    showLocationPicker: boolean;
  };
}

const mutations = {
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_STORE_BUILDER_RESET](
    state: IBuilderStoreState
  ) {
    state = R.clone(builderStoreService.factoryGetBuilder());
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER](
    state: IBuilderStoreState,
    builderCompetition: IBuilderCompetition
  ) {
    state.builderCompetition = R.clone(builderCompetition);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_BUILDER_FROM_ID](
    state: IBuilderStoreState,
    builderCompetition: IBuilderCompetition
  ) {
    builderCompetition.options = R.mergeDeepRight(
      builderService.factoryOptions(),
      builderCompetition.options
    ) as IBuilderOptions;

    // state.builderCompetition = R.clone(builderCompetition);
    // state.builderCompetition = Object.freeze(builderCompetition);
    state.builderCompetition = simpleClone(builderCompetition);
    //  TODO why doesn't sortAgeGroups() recognise IAgeGroupCompCoverageModel ?
    state.ageGroupsForComp = ageGroupService.sortAgeGroups(
      builderCompetition.meta.ageGroups
    ) as IAgeGroupCompCoverageModel[];

    state.ageGroupCompCoverageModels = R.clone(state.ageGroupsForComp);

    state.compEventsSchedule = simpleClone(builderCompetition.meta.compEvents);
    // state.discounts = builderCompetition.meta.discounts ? R.clone(builderCompetition.meta.discounts) : [];
    // @ts-ignore
    state.discounts = builderCompetition.meta.discounts
      ? simpleClone(builderCompetition.meta.discounts)
      : [];

    state.compRules = builderCompetition.meta.rules
      ? compRuleService.mapRulesFromServer(builderCompetition.meta.rules)
      : [];

    state.prices =
      builderCompetition.meta.prices.length > 0
        ? R.clone(builderCompetition.meta.prices)
        : [];

    state.builderPermissions = R.clone(
      commonService.convertArrayToObject(
        "level",
        builderCompetition.meta.access
      )
    );
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SUBMIT_BUILDER_LOADING_RESULT](
    state: IBuilderStoreState,
    message: string
  ) {
    state.builderCompetitionLoadingMessage = message;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS](
    state: IBuilderStoreState,
    compOrgs: IOrg[]
  ) {
    state.compOrgs = R.clone(compOrgs);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_ORGS_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.compOrgsLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS](
    state: IBuilderStoreState,
    locations: ILocation[]
  ) {
    state.locations = R.clone(locations);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_LOCATIONS_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.locationsLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_UPDATE_LOCATION](
    state: IBuilderStoreState,
    location: ILocation
  ) {
    const builderCompetition = R.clone(state.builderCompetition);
    builderCompetition.location = R.clone(location);
    state.builderCompetition = builderCompetition;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS](
    state: IBuilderStoreState,
    areas: IArea[]
  ) {
    state.areas = R.clone(areas);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_AREAS_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.areasLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDER_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.builderCompetitionLoading = isLoading;
  },

  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP](
    state: IBuilderStoreState,
    ageGroups: IAgeGroupCompCoverageModel[]
  ) {
    state.ageGroupsForComp = ageGroupService.sortAgeGroups(
      ageGroups
    ) as IAgeGroupCompCoverageModel[];
  },

  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_COVERAGE_COMP](
    state: IBuilderStoreState,
    ageGroupCompCoverageModels: IAgeGroupCompCoverageModel[]
  ) {
    state.ageGroupCompCoverageModels = R.clone(ageGroupCompCoverageModels);
  },

  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_ADD](
    state: IBuilderStoreState,
    ageGroup: IAgeGroup
  ) {
    state.ageGroupsForComp = ageGroupService.addAgeGroup(
      ageGroup,
      state.ageGroupsForComp
    ) as IAgeGroupCompCoverageModel[];
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.ageGroupsForCompLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_ALL](
    state: IBuilderStoreState,
    ageGroups: IAgeGroup[]
  ) {
    state.ageGroupsAll = R.clone(ageGroups);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.ageGroupsDefaultLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_DEFAULT_SET](
    state: IBuilderStoreState,
    ageGroups: IAgeGroup[]
  ) {
    state.ageGroupsDefault = ageGroupService.sortAgeGroups(ageGroups);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_AGE_GROUPS_FOR_COMP](
    state: IBuilderStoreState,
    ageGroups: IAgeGroup[]
  ) {
    state.ageGroupsForComp = ageGroupService.sortAgeGroups(
      ageGroups
    ) as IAgeGroupCompCoverageModel[];
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_CREATE_GROUP_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.ageGroupCreateLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.eventsLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_EVENTS](
    state: IBuilderStoreState,
    events: IEventE4S[]
  ) {
    state.events = R.clone(events);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.uomsLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_UOMS](
    state: IBuilderStoreState,
    uoms: IEventUomType[]
  ) {
    state.uoms = R.clone(uoms);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_SET_COMP_EVENT_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.compEventLoading = isLoading;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICE_ADD](
    state: IBuilderStoreState,
    price: IPrice
  ) {
    let prices = R.clone(state.builderCompetition.meta.prices);
    prices = commonService.updateObjectByIdInArray(
      price as IBase,
      prices as IBase[]
    ) as IPrice[];

    // prices.push(price);
    state.builderCompetition.meta.prices = prices;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICES_SET](
    state: IBuilderStoreState,
    prices: IPrice[]
  ) {
    //  state.builderCompetition.meta.prices = R.clone(prices);
    state.prices = R.clone(prices);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_PRICES_LOADING](
    state: IBuilderStoreState,
    isLoading: boolean
  ) {
    state.pricesLoading = isLoading;
  },
  // [ BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMPEVENT_EDIT_SET ](state: IBuilderStoreState, compEvent: ICompEvent) {
  //     state.compEventBeingEdited = R.clone(compEvent);
  // },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_BUILDERCOMPEVENT_EDIT_SET](
    state: IBuilderStoreState,
    builderCompEvent: IBuilderCompEvent
  ) {
    state.builderCompEventBeingEdited = R.clone(builderCompEvent);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMP_EVENT_ADD](
    state: IBuilderStoreState,
    compEvent: ICompEvent
  ) {
    const compEvents = R.clone(state.compEventsSchedule);
    compEvents.push(compEvent);
    state.compEventsSchedule = compEvents;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_DISCOUNT_SET](
    state: IBuilderStoreState,
    discounts: IDiscount[]
  ) {
    state.discounts = R.clone(discounts);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_DISCOUNT_ADD](
    state: IBuilderStoreState,
    price: IDiscount
  ) {
    let discounts = R.clone(state.discounts);
    discounts = commonService.updateObjectByIdInArray(
      price as IBase,
      discounts as IBase[]
    ) as IDiscount[];
    state.discounts = discounts;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMP_RULE_ADD](
    state: IBuilderStoreState,
    compRule: ICompRule
  ) {
    let compRules = R.clone(state.compRules);
    compRules = commonService.updateObjectByIdInArray(
      compRule as IBase,
      compRules as IBase[]
    ) as ICompRule[];
    state.compRules = compRules;
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMP_RULE_SET](
    state: IBuilderStoreState,
    compRules: ICompRule[]
  ) {
    state.compRules = R.clone(compRuleService.mapRulesFromServer(compRules));
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS_COMPEVENTS_SET](
    state: IBuilderStoreState,
    compEvents: ICompEvent[]
  ) {
    // state.compEventsSchedule = R.clone(compEvents);
    // @ts-ignore
    state.compEventsSchedule = simpleClone(compEvents);
  },
  [BUILDER_STORE_CONST.BUILDER_MUTATIONS__STICKY_DATE_SET](
    state: IBuilderStoreState,
    stickyDate: IsoDate
  ) {
    state.stickyEntryDate = stickyDate;
  },

  // [ BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_SET ](state: IBuilderStoreState, secondary: ISecondaryDef[]) {
  //     state.secondaries = R.clone(secondary);
  // },
  // [ BUILDER_STORE_CONST.BUILDER_MUTATIONS_SECONDARIES_LOADING ](state: IBuilderStoreState, isLoading: boolean) {
  //     state.secondariesLoading = isLoading;
  // }
};

const getters = {};

export const builderStore: Module<IBuilderStoreState, IRootState> = {
  namespaced: true,
  state: builderStoreService.factoryGetBuilder(),
  mutations,
  getters,
  actions: builderStoreActions,
};
