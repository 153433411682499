import {
  CompetitionStatusDescription,
  ICompetitionBase,
  ICompetitionOptionsV2,
  ICompetitionStatus,
  ICompetitionSummaryPublic,
  ICompetitionSummaryPublicV2,
  ICompetitionV2,
  ICompSummary,
} from "../competition-models";
import { CompetitionService } from "../competiton-service";
import { BuilderService } from "../../builder/builder-service";
import {
  format,
  isAfter,
  isBefore,
  parse,
  subHours,
  subSeconds,
} from "date-fns";
import {
  IBuilderCompetition,
  IBuilderOptions,
} from "../../builder/builder-models";
import {
  IClubCompInfo,
  IClubCompInfoClub,
  IClubCompInfoConfigData,
  IClubCompInfoEntryData,
} from "../../entry/v2/schools/clubCompInfo-models";
import { IUserInfo } from "../../config/config-app-models";
import {
  eventDateDisplay,
  getE4sStandardHumanDateTimeOutPut,
} from "../../common/common-service-utils";

export type UserCanNotEnterReason =
  | "Not Active"
  | "Inactive"
  | "Not Open"
  | "Disabled"
  | "No Access"
  | "Entries Closed"
  | "Max athletes reached"
  | "Max entries reached";

const competitionService: CompetitionService = new CompetitionService();
const builderService: BuilderService = new BuilderService();

export function mapCompetitionSummaryPublicToICompetitionSummaryPublicV2(
  competitionSummaryPublicv2: ICompetitionSummaryPublicV2
): ICompetitionSummaryPublic {
  const competitionSummaryPublic: ICompetitionSummaryPublic =
    competitionService.factorySummaryPublic();

  const competitionV2: ICompetitionV2 = competitionSummaryPublicv2.competition;

  //  <competitionV2>
  competitionSummaryPublic.id = competitionV2.id;
  competitionSummaryPublic.compId = competitionV2.id;
  competitionSummaryPublic.compName = competitionV2.name;
  competitionSummaryPublic.link = competitionV2.link;
  competitionSummaryPublic.active = competitionV2.active;
  competitionSummaryPublic.closedate = competitionV2.closeDate;
  competitionSummaryPublic.opendate = competitionV2.openDate;
  if (!competitionSummaryPublic.status) {
    competitionSummaryPublic.status = factoryCompetitionStatus();
  }
  if (competitionSummaryPublicv2.status) {
    competitionSummaryPublic.status = competitionSummaryPublicv2.status;
  }

  competitionSummaryPublic.organisers = competitionSummaryPublicv2.organisers;

  //  </competitionV2>

  // competitionSummaryPublic.termsConditions = competitionSummaryPublicv2.???;

  //  <organiser>
  competitionSummaryPublic.compOrgId = competitionSummaryPublicv2.organiser.id;
  competitionSummaryPublic.clubId = competitionSummaryPublicv2.organiser.id;
  competitionSummaryPublic.club = competitionSummaryPublicv2.organiser.name;
  //  </organiser>

  // competitionSummaryPublic.compDate
  competitionSummaryPublic.dates =
    competitionSummaryPublicv2.competition.options.dates;
  // competitionSummaryPublic.areaname = competitionSummaryPublicv2.competition
  // competitionSummaryPublic.saleenddate = competitionSummaryPublicv2.competition.options.saleEndDate;
  // competitionSummaryPublic.access = ""

  //  TODO compute
  // competitionSummaryPublic.isClosed = competitionSummaryPublicv2.

  competitionSummaryPublic.entries = {
    athletes: competitionSummaryPublicv2.counts.uniqueCnt,
    team: competitionSummaryPublicv2.counts.relayCnt,
    indiv: competitionSummaryPublicv2.counts.totalCnt,
    isTeam: competitionSummaryPublicv2.counts.teamCnt > 0,
  };

  competitionSummaryPublic.logo = competitionSummaryPublicv2.competition.logo;
  // competitionSummaryPublic.organisers
  // competitionSummaryPublic.permissions

  //  <Location>
  competitionSummaryPublic.location = {
    id: competitionSummaryPublicv2.location.id,
    name: competitionSummaryPublicv2.location.name,
    website: competitionSummaryPublicv2.location.website,
    address1: competitionSummaryPublicv2.location.address1,
    address2: competitionSummaryPublicv2.location.address2,
    town: competitionSummaryPublicv2.location.town,
    postcode: competitionSummaryPublicv2.location.postcode,
    directions: competitionSummaryPublicv2.location.directions,
    county: "",
    map: "",
    contact: "",
  };
  //  </Location>

  // competitionSummaryPublic.status?.status = competitionSummaryPublicv2.status.status
  // if (competitionSummaryPublic.status) {
  //   competitionSummaryPublic.status.status = competitionSummaryPublicv2.status.status;
  // }

  competitionSummaryPublic.information =
    competitionSummaryPublicv2.competition.text.information;
  competitionSummaryPublic.newsFlash =
    competitionSummaryPublicv2.competition.text.newsFlash;
  competitionSummaryPublic.termsConditions =
    competitionSummaryPublicv2.competition.text.termsConditions;

  // competitionSummaryPublic.compRules = ???
  competitionSummaryPublic.options =
    competitionSummaryPublicv2.competition.options;

  competitionSummaryPublic.information =
    competitionSummaryPublicv2.competition.text.information;
  competitionSummaryPublic.newsFlash =
    competitionSummaryPublicv2.competition.text.newsFlash;

  return competitionSummaryPublic;
}

export function factoryCompetitionBase(): ICompetitionBase {
  return {
    compAgeGroups: [],
    id: 0,
    compId: 0,
    compName: "",
    link: "",
    logo: "",
    name: "",
    clubCompInfo: factoryClubCompInfo(),
  };
}

export function factoryCompetitionSummaryPublicV2(): ICompetitionSummaryPublicV2 {
  return {
    competition: {
      id: 0,
      name: "",
      logo: "",
      link: "",
      active: true,
      date: "",
      closeDate: "",
      openDate: "",
      modified: "",
      options: builderService.factoryOptions(),
      text: {
        summary: "",
        information: "",
        termsConditions: "",
        newsFlash: "",
      },
    },
    location: {
      id: 0,
      name: "",
      website: "",
      address1: "",
      address2: "",
      town: "",
      postcode: "",
      directions: "",
    },
    organiser: {
      id: 0,
      name: "",
    },
    organisers: [],
    counts: {
      eventCnt: 0,
      indivCnt: 0,
      teamCnt: 0,
      totalCnt: 0,
      uniqueCnt: 0,
      waitingEntries: 0,
      relayCnt: 0,
      relayAthleteCnt: 0,
      relayUniqueAthleteCnt: 0,
      totalUniqueAthleteCnt: 0,
      force: 0,
    },
    status: factoryCompetitionStatus(),
  };
}

export function factoryCompetitionOptionsV2(): ICompetitionOptionsV2 {
  return {
    adjustEventNo: undefined,
    allowAdd: {
      registered: false,
      unregistered: false,
    },
    athleteQrData: false,
    athleteSecurity: {
      areas: [],
      clubs: [],
      onlyClubsUpTo: undefined,
    },
    athleteType: undefined,
    autoEntries: {
      selectedTargetComp: {
        id: 0,
        name: "",
      },
      targetable: {
        allowedSources: [],
        enabled: false,
      },
    },
    bibNos: 1,
    bibSort1: "surname",
    bibSort2: "firstname",
    bibSort3: "dob",
    cancelEvent: {
      credit: {
        allow: false,
      },
      hrsBeforeClose: 0,
      refund: {
        allow: false,
        type: "",
      },
    },
    cardInfo: {
      availableFrom: "",
      enabled: false,
    },
    categoryId: 0,
    checkIn: {
      checkInDateTimeOpens: "",
      defaultFrom: 0,
      defaultTo: 0,
      enabled: false,
      qrCode: false,
      seedOnEntries: false,
      terms: "",
      text: "",
      useTerms: false,
    },
    cheques: {
      allow: false,
      ends: "",
    },
    compLimits: {
      athletes: 0,
      entries: 0,
      teams: 0,
    },
    contact: {
      email: "",
      id: 0,
      tel: "",
      userName: "",
      visible: false,
    },
    dates: [],
    disabled: false,
    disabledReason: "",
    heatOrder: undefined,
    helpText: {
      cart: "",
      schedule: "",
      teams: "",
    },
    homeInfo: "",
    laneCount: 0,
    level: undefined,
    paymentCode: "",
    pfTargetDirectory: "",
    priority: {
      code: "",
      dateTime: "",
      message: "",
      required: false,
    },
    report: {
      athletes: false,
      events: false,
      individual_entries: false,
      orders: false,
      subscriptions: false,
      summary: false,
      teams: false,
      ttathletes: false,
      ttentries: false,
    },
    resultsAvailable: false,
    saleEndDate: "",
    school: false,
    scoreboard: { image: "" },
    seqEventNo: false,
    shortCode: "",
    showAthleteAgeInEntries: false,
    showTeamAthletes: false,
    singleAge: false,
    sockets: false,
    stadium: "",
    stopReport: false,
    stripeMandatory: false,
    subscription: {
      e4sMessage: "",
      enabled: false,
      organiserMessage: "",
      process: false,
      refunded: undefined,
      timeCloses: "",
    },
    tickets: { enabled: false },
    timetable: "",
    ui: {
      enterButtonText: "",
      entryDefaultPanel: "SCHEDULE",
      ticketComp: 0,
      ticketCompButtonText: "",
    },
    useTeamBibs: false,
  };
}

export function factoryCompetitionStatus(): ICompetitionStatus {
  return {
    id: 0,
    name: "",
    compid: 0,
    invoicelink: "",
    paid: 0,
    moveto: 0,
    value: 0,
    ref: "",
    notes: "",
    code: 0,
    description: "",
    status: "",
    nextdescription: "",
    nextid: 0,
    prevdescription: "",
    previd: 0,
    wfid: 0,
  };
}

export function isPriorityRequired(
  comp: ICompetitionSummaryPublic,
  timeNow?: string
) {
  if (!comp.options.priority.required) {
    return false;
  }

  const priorityDate =
    comp.options.priority.dateTime.length > 0
      ? comp.options.priority.dateTime
      : "";

  if (priorityDate.length === 0) {
    //  Required for entire length of comp open for entry.
    return true;
  }

  return isBefore(new Date(), parse(priorityDate));
}

export function isCompActive(comp: ICompetitionSummaryPublic): boolean {
  return comp.active;
}

export function competitionStatusDescription(
  comp: ICompetitionSummaryPublic
): CompetitionStatusDescription {
  let status: CompetitionStatusDescription = "Active";

  if (isCompActive(comp)) {
    if (!isEntryOpen(comp)) {
      status = "Closed";
    }
  } else {
    status = "Inactive";
  }
  return status;
}

export function isEntryOpen(
  competition: ICompetitionSummaryPublic,
  isoNow: string = ""
): boolean {
  const dateNow = isoNow.length === 0 ? new Date() : parse(isoNow);
  const openDate = parse(competition.opendate);
  const closeDate = parse(competition.closedate);
  return isAfter(dateNow, openDate) && isBefore(dateNow, closeDate);
}

export function cancelsNotPermittedAfter(
  comp: ICompetitionSummaryPublic | IBuilderCompetition
): Date {
  let closeDate;
  if ("entriesCloseDateTime" in comp) {
    closeDate = comp.entriesCloseDateTime;
  } else {
    closeDate = comp.closedate;
  }

  const compCloses = parse(closeDate);
  const offsetHrsPriorToComp =
    comp.options.cancelEvent && comp.options.cancelEvent.hrsBeforeClose
      ? comp.options.cancelEvent.hrsBeforeClose
      : 48;
  return subHours(compCloses, offsetHrsPriorToComp);
}

export function isCancelStillAvailable(
  comp: ICompetitionSummaryPublic,
  timeNow?: Date
): boolean {
  const seedTime = timeNow ? timeNow : new Date();
  const cancelCloses = cancelsNotPermittedAfter(comp);
  return isBefore(seedTime, cancelCloses);
}

export function enterButtonText(comp: ICompetitionSummaryPublic) {
  const builderOptions: IBuilderOptions = comp.options;
  if (
    builderOptions &&
    builderOptions.ui &&
    builderOptions.ui.enterButtonText
  ) {
    return builderOptions.ui.enterButtonText;
  }
  return "Enter";
}

export function isPastCompDate(
  competition: ICompetitionSummaryPublic | ICompSummary,
  isoNow: string = ""
) {
  const dateNow = isoNow?.length === 0 ? new Date() : parse(isoNow);
  if (competition.dates.length === 0) {
    return false;
  }
  //  Take the "last" date, as might be multi-day.
  const compDate = parse(competition.dates[competition.dates.length - 1]);
  return isAfter(dateNow, compDate);
}

export function canUserEnterCompetition(
  comp: ICompetitionSummaryPublic,
  userInfo: IUserInfo
): boolean {
  if (comp.options.disabled) {
    return false;
  }
  if (!isCompActive(comp)) {
    return false;
  }
  if (isAfter(new Date(), parse(comp.closedate))) {
    return false;
  }

  const compReachedEntryLimits = hasCompReachedEntryLimits(comp);
  // console.log(comp.compId + " canUserEnter() hasCompReachedEntryLimits: " + JSON.stringify(hasCompReachedEntryLimits));
  if (
    compReachedEntryLimits.athletes ||
    compReachedEntryLimits.indivs ||
    compReachedEntryLimits.teams
  ) {
    // console.log(comp.compId + " canUserEnter() >>>  NO");
    return false;
  }

  //  E.g. English Schools
  if (comp.options.clubComp) {
    if (!(userInfo as any)["e4s_clubcomp_" + comp.compId]) {
      return false;
    }
  }

  return isAfter(new Date(), parse(comp.opendate));
}

export function canUserNotEnterCompetitionReasons(
  comp: ICompetitionSummaryPublic,
  userInfo: IUserInfo
): UserCanNotEnterReason[] {
  const reasons: UserCanNotEnterReason[] = [];

  if (comp.options.disabled) {
    return ["Disabled"];
  }
  if (!isCompActive(comp)) {
    return ["Not Active"];
  }
  if (isAfter(new Date(), parse(comp.closedate))) {
    return ["Entries Closed"];
  }

  if (isBefore(new Date(), parse(comp.opendate))) {
    return ["Not Open"];
  }

  const compReachedEntryLimits = hasCompReachedEntryLimits(comp);
  //  Only stop users going in if all entry limits reached.
  if (
    compReachedEntryLimits.athletes &&
    compReachedEntryLimits.indivs &&
    compReachedEntryLimits.teams
  ) {
    reasons.push("Max entries reached");
  }

  //  E.g. English Schools
  if (comp.options.clubComp) {
    if (!(userInfo as any)["e4s_clubcomp_" + comp.compId]) {
      return ["No Access"];
    }
  }

  return [];
}

export function hasCompReachedEntryLimits(comp: ICompetitionSummaryPublic): {
  athletes: boolean;
  indivs: boolean;
  teams: boolean;
} {
  let athletesOver = false;
  let indivsOver = false;
  let teamsOver = false;
  if (comp.options && comp.options.compLimits) {
    if (
      comp.options.compLimits.athletes &&
      comp.options.compLimits.athletes > 0
    ) {
      athletesOver = comp.entries.athletes >= comp.options.compLimits.athletes;
    }
    if (
      comp.options.compLimits.entries &&
      comp.options.compLimits.entries > 0
    ) {
      indivsOver = comp.entries.indiv >= comp.options.compLimits.entries;
    }
    if (comp.options.compLimits.teams && comp.options.compLimits.teams > 0) {
      teamsOver = comp.entries.team >= comp.options.compLimits.teams;
    }
  }
  return {
    athletes: athletesOver,
    indivs: indivsOver,
    teams: teamsOver,
  };
}

export function hasSaleEndDate(comp: ICompetitionSummaryPublic) {
  return comp.options.saleEndDate && comp.options.saleEndDate.length > 0;
  // if (comp.options.saleEndDate && comp.options.saleEndDate.length > 0) {
  //   return true;
  // }
  // return comp.saleenddate && comp.saleenddate.length > 0;
}

export function getPublicCardOpenCloseMessage(
  competition: ICompetitionSummaryPublic,
  isoNow: string = ""
): string {
  const dateNow = isoNow.length === 0 ? new Date() : parse(isoNow);

  //  don't do this...too much text returned.
  // if (competition.options.disabled) {
  //   return competition.options.disabledReason;
  // }

  if (!isCompActive(competition)) {
    return "Not Active";
  }

  if (competition.options.disabled) {
    return "Disabled";
  }

  const pattern = "Do MMM HH:mm";
  const openDate = parse(competition.opendate);
  const openHours = competition.opendate.split("T")[1];
  if (isBefore(dateNow, openDate)) {
    return (
      "Opens " + format(openDate, openHours === "00:00:00" ? "Do MMM" : pattern)
    );
  }

  const compReachedEntryLimits = hasCompReachedEntryLimits(competition);
  if (compReachedEntryLimits.athletes || compReachedEntryLimits.indivs) {
    const maxMessages = [];

    if (compReachedEntryLimits.athletes) {
      maxMessages.push(
        "Max athletes reached: " + competition.options.compLimits.athletes
      );
    }
    if (compReachedEntryLimits.indivs) {
      maxMessages.push(
        "Max entries reached: " + competition.options.compLimits.entries
      );
    }
    return "Closed: " + maxMessages.join(", ");
  }

  const closeDate = parse(competition.closedate);
  if (isAfter(dateNow, closeDate)) {
    // return "Closed " + format(closeDate, pattern);
    return (
      "Closed " +
      getE4sStandardHumanDateTimeOutPut(competition.closedate, false)
    );
  }

  if (hasSaleEndDate(competition)) {
    // const dateTime = this.getSaleEndDateIso(competition);
    // let dateSaleEnd = parse(dateTime);
    // dateSaleEnd = subSeconds(dateSaleEnd, 60);
    // if (this.isPastLateEntry(competition)) {
    //     // return "Late Entries Close " + format(dateSaleEnd, pattern);
    //     return "Late Entries Close " + format(parse(competition.closedate), pattern);
    // }
    // return "Std Entries Close " + format(dateSaleEnd, pattern);

    return getSaleEndMessage(competition);
  }
  //  return "Entries Close " + format(parse(competition.closedate), pattern);
  return (
    "Entries Close " +
    getE4sStandardHumanDateTimeOutPut(competition.closedate, false)
  );
}

export function getSaleEndMessage(
  competition: ICompetitionSummaryPublic
): string {
  // const pattern = "Do MMM HH:mm";
  let dateSaleEnd = parse(getSaleEndDateIso(competition));
  dateSaleEnd = subSeconds(dateSaleEnd, 60);
  if (isPastLateEntry(competition)) {
    return (
      "Entries Close " +
      getE4sStandardHumanDateTimeOutPut(competition.closedate, false)
    );
  }
  return "Price Increases on " + getSaleEndDateTime(competition);
}

export function getSaleEndDateTime(
  competition: ICompetitionSummaryPublic
): string {
  return getE4sStandardHumanDateTimeOutPut(
    competition.options.saleEndDate,
    false
  );
}

export function getSaleEndDateIso(
  competition: ICompetitionSummaryPublic
): string | "" {
  if (hasSaleEndDate(competition)) {
    // const saleEndDate = competition.saleenddate.split(" ");
    // return saleEndDate[0] + "T" + saleEndDate[1];
    return competition.options.saleEndDate;
  }
  return "";
}

export function isPastLateEntry(
  competition: ICompetitionSummaryPublic,
  isoNow: string = ""
): boolean {
  if (hasSaleEndDate(competition)) {
    const dateTime = getSaleEndDateIso(competition);

    if (dateTime.split(" ")[0] === "0000-00-00") {
      return false;
    }

    const dateSaleEnd = parse(dateTime);
    const dateNow = isoNow.length === 0 ? new Date() : parse(isoNow);

    if (isAfter(dateNow, dateSaleEnd)) {
      return true;
    }
  }
  return false;
}

export function isNearToCloseDate(
  competition: ICompetitionSummaryPublic,
  isoNow: string = ""
): boolean {
  if (!competition.active) {
    return false;
  }

  //  If now is after the close date, return false
  if (isAfter(parse(isoNow), parse(competition.closedate))) {
    return false;
  }

  //  If comp has a saleEndDate then we are near to the close date.
  const closeDate = parse(competition.closedate);
  // const saleEndDate = getSaleEndDateIso(competition);
  // if (saleEndDate.length > 0) {
  //   const closeDateToUse = saleEndDate.length > 0 ? saleEndDate : closeDate;
  // }

  const dateNow = isoNow.length === 0 ? new Date() : parse(isoNow);

  if (isAfter(dateNow, closeDate)) {
    return false;
  }

  const offset = 48;
  const closeDateOffset = subHours(closeDate, offset);
  return isAfter(dateNow, closeDateOffset);
}

export function factoryClubCompInfoConfigData(): IClubCompInfoConfigData {
  return {
    bibNos: [],
    categoryId: 0,
    categoryName: "",
    clubId: 0,
    clubName: "",
    clubCompId: 0,
    maxEntries: 0,
    maxPerEvent: 0,
    maxRelays: 0,
  };
}

export function factoryClubCompInfoEntryData(): IClubCompInfoEntryData {
  return {
    entries: {},
    entryCnt: 0,
    teams: {},
    teamCnt: 0,
  };
}

export function factoryClubCompInfo(): IClubCompInfo {
  return {
    configData: factoryClubCompInfoConfigData(),
    entryData: factoryClubCompInfoEntryData(),
    clubs: [],
  };
}

export function factoryClubCompInfoComp(): IClubCompInfoClub {
  return {
    clubId: 0,
    clubName: "",
    clubCompId: 0,
    categoryId: 0,
    categoryName: "",
  };
}

export function hasAthleteSecurity(
  competitionSummaryPublic: ICompetitionSummaryPublic
): boolean {
  if (!competitionSummaryPublic.options.athleteSecurity) {
    return false;
  }
  const athleteSecurity = competitionSummaryPublic.options.athleteSecurity;
  return athleteSecurity.areas.length > 0 || athleteSecurity.clubs.length > 0;
}

export function isMultiDay(competition: ICompetitionSummaryPublic): boolean {
  return competition.options.dates.length > 1;
}

export function getCompDates(competition: ICompetitionSummaryPublic): string[] {
  return competition.options.dates.map((date) => {
    return eventDateDisplay(date, new Date());
  });
}

export function getCompDatesListForDisplay(
  competition: ICompetitionSummaryPublic
): string {
  return getCompDates(competition).join(", ");
}
