










import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from "@vue/composition-api";
import { ICompetitionSummaryPublic } from "../../../../competition/competition-models";
import { RawLocation } from "vue-router";
import { LAUNCH_ROUTES_PATHS } from "../../../../launch/launch-routes";
import { useRouter } from "../../../../router/migrateRouterVue3";
import ButtonGenericV2 from "./button-generic-v2.vue";
import { useAuthStore } from "../../../../auth/useAuthStore";
import { LAUNCH_ROUTES_PATHS_V2 } from "../../../../launch/v2/launch-routes-v2";
import {
  canUserEnterCompetition,
  enterButtonText,
} from "../../../../competition/v2/competiton-service-v2";
import { useConfigController } from "../../../../config/useConfigStore";
import { UiVersion } from "../../../../config/config-app-models";

export default defineComponent({
  name: "button-goto-comp-v2",
  components: { ButtonGenericV2 },
  props: {
    competitionSummaryPublic: {
      type: Object as PropType<ICompetitionSummaryPublic>,
      default: () => {
        return [];
      },
    },
    buttonText: {
      type: String,
      default: () => {
        return "Enter Competition!";
      },
    },
    configVersion: {
      type: String as PropType<UiVersion>,
      default: "v2",
    },
  },
  setup(
    props: {
      competitionSummaryPublic: ICompetitionSummaryPublic;
      buttonText: string;
      configVersion: UiVersion;
    },
    context: SetupContext
  ) {
    let authStore = useAuthStore();
    const router = useRouter();
    const configController = useConfigController();

    const canUserEnter = computed<boolean>(() => {
      return canUserEnterCompetition(
        props.competitionSummaryPublic,
        configController.getStore.value.configApp.userInfo
      );
    });

    const isCompAdmin = computed<boolean>(() => {
      if (configController.isAdmin.value) {
        return true;
      }
      const hasBuilderPermissionForComp =
        configController.hasBuilderPermissionForComp(
          props.competitionSummaryPublic.compOrgId,
          props.competitionSummaryPublic.compId
        );
      console.log(
        "hasBuilderPermissionForComp: " + hasBuilderPermissionForComp
      );
      if (hasBuilderPermissionForComp) {
        return true;
      }
      return false;
    });

    const isEnterButtonDisabled = computed<boolean>(() => {
      if (isCompAdmin.value) {
        return false;
      }
      return !canUserEnter.value;
    });

    const getEnterButtonText = computed<string>(() => {
      if (isCompAdmin.value && !canUserEnter.value) {
        return "Org Enter";
      }
      return enterButtonText(props.competitionSummaryPublic);
    });

    const getCss = computed<string[]>(() => {
      const css: string[] = [];

      //  If you are admin, but users can't enter, then make it red
      if (configController.isAdmin.value && !canUserEnter.value) {
        css.push("e4s-button--destructive");
      }

      if (getEnterButtonText.value.length > 10) {
        css.push(" e4s-button--auto");
      }

      return css;
    });

    function goToComp() {
      if (props.configVersion === "v1") {
        console.log("ButtonGotoCompV2.goToCompV1");
        context.emit("goToCompV1");
        return;
      }

      if (
        props.competitionSummaryPublic.options.ui.entryDefaultPanel ===
        "SHOP_ONLY"
      ) {
        router.push({
          path:
            "/" +
            LAUNCH_ROUTES_PATHS.SHOP +
            "/" +
            props.competitionSummaryPublic.compId,
        });
        return;
      }

      const compUrl =
        "/v2/" +
        LAUNCH_ROUTES_PATHS_V2.ENTRY_V2 +
        "/" +
        props.competitionSummaryPublic.compId;

      let location: RawLocation;
      if (!authStore.isLoggedIn) {
        location = {
          path: "/v2/" + LAUNCH_ROUTES_PATHS_V2.LOGIN_V2,
          query: {
            redirectFrom: compUrl,
          },
        };
      } else {
        location = {
          path: compUrl,
        };
      }

      router.push(location);
    }
    return {
      getEnterButtonText,
      canUserEnter,
      goToComp,
      configController,
      getCss,
      isEnterButtonDisabled,
    };
  },
});
