import { appStore } from "../app.store";
import { ITokenReponse } from "../auth/auth-models";
import { AUTH_STORE_CONST } from "../auth/auth-store";
import { isLoggedInGuard } from "../index";
import EntryConditionsRoute from "../public/entry-public/entry-conditions-route.vue";
import SecureRedirect from "./v2/SecureRedirect.vue";
// import AthleteSearchRouteV2 from "../athlete/v2/search/athlete-search-route-v2.vue";

export const LAUNCH_ROUTES_PATHS = {
  HOME: "home",
  SHOW_ENTRIES: "showentries",
  SHOW_ENTRY: "show-entry",

  LOGIN_V1: "login-v1",
  REGISTER_V1: "register-v1",
  FORGOT_V1: "forgot-v1",
  RESET_V1: "reset_pwd", //  N.B. the underscore, not a dash.

  ENTRY: "entry",
  ENTRY_MAIN: "entry-main",
  BUILDER: "builder",
  ATHLETE: "athlete",
  ATHLETE_PB: "athlete-pb",
  ATHLETE_CREATE: "athlete-create",
  ATHLETES: "athletes",
  CART: "cart",
  CART_REDIRECT: "cart-redirect",
  SECURE_REDIRECT: "secure-redirect",
  CHECKIN_ORGANISER: "checkin",
  CONTACT_ORGANISER: "contact-org",
  WHOISHERE: "whoishere",
  ATHLETE_CHECKIN: "checkin-athlete",
  CHECKIN_QR_ROUTE: "checkin-qr-route",
  CHECKIN_CODE: "checkin-code",

  CHEQUES: "cheques",
  USER_SEARCH: "user-search",
  USER: "user",
  REPORTS: "reports",
  HELP_FORM: "help-form",
  HELP_FORM_LIST: "help-form-list",

  SCOREBOARD_COMP_ROUTE: "scoreboard-comp",
  SCOREBOARD_OUTPUT: "scoreboard-output",
  SCOREBOARD_OUTPUT_LIST: "scoreboard-output-list",
  SCOREBOARD_OUTPUT_DISPLAY: "scoreboard-output-display",
  SCOREBOARD_SCHEDULE_LIST: "scoreboard-schedule-list",

  R4S_SCHEDULE_PUBLIC_OLD: "results-old", //  OLD "results"
  R4S_RESULTS_PUBLIC: "results", //  NEW "comp-results"
  R4S_RESULTS_PUBLIC_QR: "results-qr", //  NEW "comp-results"

  R4S_SEEDING_PUBLIC: "seeding",

  AUTO_ENTRIES: "auto-entries",

  RESULTS_ENTRY: "results-entry",
  RESULT_READ: "results-read",
  RESULTS_IMPORT: "results-import",

  SECONDARY_GRID: "sec-grid",

  TICKET_FORM: "ticket",
  TICKET_FORM_GATEKEEPER: "ticket-form-gatekeeper",
  TICKET_ORDER: "ticket-order",
  TICKET_ADMIN: "ticket-admin",

  COMP_ADMIN: "comp-admin",
  COMP_EVENT_ACTIONS: "actions",
  COMP_EVENT_GROUP_ATHLETES: "event-group-athletes",
  COMP_EVENT_AGE_GROUP_COVERAGE: "event-group-age-group-coverage",

  BIBS: "bibs",
  ENTRY_CONDITIONS: "entry-conditions",
  EMAIL_MESSAGES: "email-messages",
  EMAIL_MESSAGE: "email-message",
  EMAIL_MESSAGE_CREATE: "email-message-create",
  SHOP: "shop",
  STRIPE_USERS: "stripe-users",
  RESULTS_IMPORT_FILE: "results-import-file",

  CLUB_SEARCH: "club-search",
  CLUB: "club",
};

export type LaunchRouteKey = keyof typeof LAUNCH_ROUTES_PATHS;

// import ScoreboardOutputRoute from "../competition/scoreboard/scoreboard-output/scoreboard-output-route.vue"
// import ScoreboardOutputListRoute from "../competition/scoreboard/scoreboard-output/list/scoreboard-output-list-route.vue"

// const isVersion2 = true;

export const launchRoutes = [
  {
    name: LAUNCH_ROUTES_PATHS.HOME,
    path: "",
    component: () => {
      return import(
        /* webpackChunkName: "routev1-home" */ "../public/entry-public/v2/EntryPublicV2V1Wrapper.vue"
      );
    },
    meta: {
      title: "Comp List",
    },
  },
  // {
  //   name: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
  //   path: LAUNCH_ROUTES_PATHS_V2.HOME_V2,
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "routev1-entry-public-v2" */ "../public/entry-public/v2/EntryPublicV2V1Wrapper.vue"
  //     );
  //   },
  //   meta: {
  //     title: "Home",
  //   },
  // },
  {
    name: LAUNCH_ROUTES_PATHS.SHOW_ENTRIES,
    path: "/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRIES,
    component: () => {
      return import(
        /* webpackChunkName: "routev1-show-entries" */ "../public/entry-public/v2/EntryPublicV2V1Wrapper.vue"
      );
    },
    meta: {
      title: "Comp List",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.REGISTER_V1,
    path: LAUNCH_ROUTES_PATHS.REGISTER_V1,
    component: () => {
      return import(
        /* webpackChunkName: "register-container-v1" */ "../auth/v2/register/register-container-v2.vue"
      );
    },
    meta: {
      title: "Register",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.LOGIN_V1,
    path: LAUNCH_ROUTES_PATHS.LOGIN_V1,
    component: () => {
      return import(
        /* webpackChunkName: "login-container-v2" */ "../auth/v2/login-container-v2.vue"
      );
    },
    meta: {
      title: "Login",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.RESET_V1,
    path: LAUNCH_ROUTES_PATHS.RESET_V1,
    component: () => {
      return import(
        /* webpackChunkName: "reset-container-v1" */ "../auth/v2/reset/reset-container-v2.vue"
      );
    },
    meta: {
      title: "Reset",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.FORGOT_V1,
    path: LAUNCH_ROUTES_PATHS.FORGOT_V1,
    component: () => {
      return import(
        /* webpackChunkName: "forgot-container-v1" */ "../auth/v2/forgot/forgot-container-v2.vue"
      );
    },
    meta: {
      title: "Forgot",
    },
  },

  {
    name: LAUNCH_ROUTES_PATHS.SECURE_REDIRECT,
    path: "/" + LAUNCH_ROUTES_PATHS.SECURE_REDIRECT,
    component: SecureRedirect,
    beforeEnter: isLoggedInGuard,
    meta: {
      title: "Secure Redirect",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ENTRY_CONDITIONS,
    path: "/" + LAUNCH_ROUTES_PATHS.ENTRY_CONDITIONS + "/:id",
    component: EntryConditionsRoute,
    beforeEnter: isLoggedInGuard,
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Entry Condition ";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ENTRY,
    path: "/" + LAUNCH_ROUTES_PATHS.ENTRY,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "entry-launch" */ "../entry/entry-launch.vue"
      );
    },
    meta: {
      title: "Entry",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ENTRY_MAIN,
    path: "/" + LAUNCH_ROUTES_PATHS.ENTRY_MAIN + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "entry-main" */ "../entry/main/entry-main.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Entry";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ATHLETE,
    path: "/" + LAUNCH_ROUTES_PATHS.ATHLETE + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-route-container" */ "../athlete/maint/athlete-route-container.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Athlete";
      },
    },
  },
  // {
  //   name: LAUNCH_ROUTES_PATHS.ATHLETE_PB,
  //   path: "/" + LAUNCH_ROUTES_PATHS.ATHLETE_PB + "/:entryId",
  //   beforeEnter: isLoggedInGuard,
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "athlete-pb-route" */ "../athleteCompSched/pb/v3/AthletePbRoute.vue"
  //     );
  //   },
  //   meta: {
  //     title: (params: { entryId: number }) => {
  //       return "Entry Id" + params.entryId;
  //     },
  //   },
  // },
  {
    name: LAUNCH_ROUTES_PATHS.ATHLETE_CREATE,
    path: "/" + LAUNCH_ROUTES_PATHS.ATHLETE_CREATE,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-create-form-route" */ "../athlete/maint/athlete-create-form-route.vue"
      );
    },
    meta: {
      title: () => {
        return "Athlete Create";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.BUILDER,
    path: "/" + LAUNCH_ROUTES_PATHS.BUILDER + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "builder-form" */ "../builder/form/builder-form.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Builder";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ATHLETES, //  "../athlete/v2/search/athlete-search-route-v2.vue"
    path: "/" + LAUNCH_ROUTES_PATHS.ATHLETES, // ../athlete/athletes/athletes-container.vue"
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "athlete-search-route-v2" */ "../athlete/athletes/athletes-container.vue"
      );
    },
    meta: {
      title: "Athletes",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CART,
    path: "/" + LAUNCH_ROUTES_PATHS.CART,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "cart-route-container" */ "../cart/cart-route-container.vue"
      );
    },
    meta: {
      title: "Cart",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CART_REDIRECT,
    path: "/" + LAUNCH_ROUTES_PATHS.CART_REDIRECT,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "cart-route-container" */ "../cart/cart-redirect.vue"
      );
    },
    meta: {
      title: "Cart",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CHECKIN_ORGANISER,
    path: "/" + LAUNCH_ROUTES_PATHS.CHECKIN_ORGANISER + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "dyn-checkin-org" */ "../competition/checkin/checkin-organiser/checkin-organiser.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Check In";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CONTACT_ORGANISER,
    path: "/" + LAUNCH_ROUTES_PATHS.CONTACT_ORGANISER + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "contact-org" */ "../competition/askorganiser/AskOrganiserRoute.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Contact";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.WHOISHERE,
    path: "/" + LAUNCH_ROUTES_PATHS.WHOISHERE + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "whoishere" */ "../competition/checkin/whoishere/whoishere.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Who Here";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.ATHLETE_CHECKIN,
    path: "/" + LAUNCH_ROUTES_PATHS.ATHLETE_CHECKIN + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "checkin-athlete" */ "../competition/checkin/checkin-athlete/checkin-athlete.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Check In";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CHECKIN_QR_ROUTE,
    path: "/" + LAUNCH_ROUTES_PATHS.CHECKIN_QR_ROUTE + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "checkin-qr-route" */ "../competition/checkin/checkin-organiser/checkin-qr-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Check In";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CHECKIN_CODE,
    path: "/" + LAUNCH_ROUTES_PATHS.CHECKIN_CODE + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "checkin-code" */ "../competition/checkin/checkin-athlete/checkin-code.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Check In";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.USER_SEARCH,
    path: "/" + LAUNCH_ROUTES_PATHS.USER_SEARCH,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "user-search" */ "../admin/user/user-search.vue"
      );
    },
    meta: {
      title: "User Search",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.USER,
    path: "/" + LAUNCH_ROUTES_PATHS.USER + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "user-route-container" */ "../admin/user/user-route-container.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": User";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CHEQUES,
    path: "/" + LAUNCH_ROUTES_PATHS.CHEQUES,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "cheques-route" */ "../admin/cheques/cheques-route.vue"
      );
    },
    meta: {
      title: "Cheques",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.REPORTS,
    path: "/" + LAUNCH_ROUTES_PATHS.REPORTS,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "reports-container" */ "../admin/reports/reports-container.vue"
      );
    },
    meta: {
      title: "Reports",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.HELP_FORM,
    path: "/" + LAUNCH_ROUTES_PATHS.HELP_FORM + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "help-form-container" */ "../common/ui/field/field-help/help-form-container.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Help";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.HELP_FORM_LIST,
    path: "/" + LAUNCH_ROUTES_PATHS.HELP_FORM_LIST,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "help-form-list" */ "../common/ui/field/field-help/help-form-list.vue"
      );
    },
    meta: {
      title: "Help",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SCOREBOARD_COMP_ROUTE,
    path: "/" + LAUNCH_ROUTES_PATHS.SCOREBOARD_COMP_ROUTE + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "dyn-scoreboard" */ "../competition/scoreboard/scoreboard-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Scoreboard";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SCOREBOARD_COMP_ROUTE,
    path: "/" + LAUNCH_ROUTES_PATHS.SCOREBOARD_COMP_ROUTE + "/:compid/:eventno",
    component: () => {
      return import(
        /* webpackChunkName: "dyn-scoreboard-event" */ "../competition/scoreboard/scoreboard-event/scoreboard-event-route.vue"
      );
    },
    meta: {
      title: (params: { compid: string; eventno: string }) => {
        return params.compid + "/" + params.eventno + ": Score Event";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SECONDARY_GRID,
    path: "/" + LAUNCH_ROUTES_PATHS.SECONDARY_GRID,
    component: () => {
      return import(
        /* webpackChunkName: "secondary-form-grid" */ "../secondary/config/secondary-form-grid.vue"
      );
    },
    meta: {
      title: "Secondary",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.TICKET_FORM,
    path: "/" + LAUNCH_ROUTES_PATHS.TICKET_FORM + "/:compId/:id",
    // beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "ticket-form" */ "../secondary/ticket/ticket-route.vue"
      );
    },
    meta: {
      title: "Ticket",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.TICKET_ORDER,
    path: "/" + LAUNCH_ROUTES_PATHS.TICKET_ORDER + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "ticket-order" */ "../secondary/ticket/ticket-user/ticket-order/ticket-order-container.vue"
      );
    },
    meta: {
      title: "Ticket Order",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.TICKET_FORM_GATEKEEPER,
    path: "/" + LAUNCH_ROUTES_PATHS.TICKET_FORM_GATEKEEPER + "/:compId", //  For gateKeeper
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "ticket-form-gatekeeper" */ "../secondary/ticket/ticket-gatekeeper/ticket-route-gatekeeper.vue"
      );
    },
    meta: {
      title: (params: { compId: string }) => {
        return params.compId + ": Ticket Gate";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.TICKET_ADMIN,
    path: "/" + LAUNCH_ROUTES_PATHS.TICKET_ADMIN + "/:compId",
    component: () => {
      return import(
        /* webpackChunkName: "ticket-admin" */ "../secondary/ticket/ticket-admin/ticket-admin-route.vue"
      );
    },
    meta: {
      title: (params: { compId: string }) => {
        return params.compId + ": Ticket Admin";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.COMP_ADMIN,
    path: "/" + LAUNCH_ROUTES_PATHS.COMP_ADMIN + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "comp-admin" */ "../competition/admin/comp-admin-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Comp Admin";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.COMP_EVENT_ACTIONS,
    path:
      "/" +
      LAUNCH_ROUTES_PATHS.COMP_EVENT_ACTIONS +
      "/:compId/:athleteId/:clubId/:entryId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "comp-event-actions" */ "../athletecompsched/comp-event-actions/comp-event-actions-loader.vue"
      );
    },
    meta: {
      title: "Comp Actions",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.BIBS,
    path: "/" + LAUNCH_ROUTES_PATHS.BIBS + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "bibs" */ "../competition/admin/bibs/bib-form-route.vue"
      );
    },
    meta: {
      title: "Bibs",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SCOREBOARD_OUTPUT,
    path: "/" + LAUNCH_ROUTES_PATHS.SCOREBOARD_OUTPUT + "/:compId/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "scoreboard-output-route" */ "../competition/scoreboard/scoreboard-output/scoreboard-output-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Scoreboard Display";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SCOREBOARD_OUTPUT_LIST,
    path: "/" + LAUNCH_ROUTES_PATHS.SCOREBOARD_OUTPUT_LIST + "/:compId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "scoreboard-output-list-route" */ "../competition/scoreboard/scoreboard-output/list/scoreboard-output-list-route.vue"
      );
    },
    meta: {
      title: (params: { compId: string }) => {
        return params.compId + ": Scoreboard List";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SCOREBOARD_SCHEDULE_LIST,
    path:
      "/" + LAUNCH_ROUTES_PATHS.SCOREBOARD_SCHEDULE_LIST + "/:compId/:outputId",
    component: () => {
      return import(
        /* webpackChunkName: "scoreboard-schedule-list-route" */ "../competition/scoreboard/scoreboard-schedule/scoreboard-schedule-list/scoreboard-schedule-list-route.vue"
      );
    },
    meta: {
      title: (params: { compId: string; outputId: string }) => {
        return params.compId + "/" + params.outputId + ": Scoreboard Sched";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.R4S_SCHEDULE_PUBLIC_OLD,
    path: LAUNCH_ROUTES_PATHS.R4S_SCHEDULE_PUBLIC_OLD + "/:compId",
    component: () => {
      return import(
        /* webpackChunkName: "schedule-public-route" */ "../competition/scoreboard/rs4/output/public/schedule/schedule-public-route.vue"
      );
    },
    meta: {
      title: "Schedule",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.R4S_RESULTS_PUBLIC,
    path: LAUNCH_ROUTES_PATHS.R4S_RESULTS_PUBLIC + "/:compId",
    component: () => {
      return import(
        /* webpackChunkName: "results-public-route" */ "../competition/results/public/schedule/results-public-route.vue"
      );
    },
    meta: {
      title: "Results",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.R4S_RESULTS_PUBLIC_QR,
    path: "/" + LAUNCH_ROUTES_PATHS.R4S_RESULTS_PUBLIC_QR + "/:compId",
    component: () => {
      return import(
        /* webpackChunkName: "results-qr-route" */ "../competition/results/public/schedule/schedule-qr-route.vue"
      );
    },
    meta: {
      title: "Results",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.R4S_SEEDING_PUBLIC,
    path: LAUNCH_ROUTES_PATHS.R4S_SEEDING_PUBLIC + "/:compId",
    component: () => {
      return import(
        /* webpackChunkName: "seeding-public-route" */ "../competition/scoreboard/rs4/output/public/seeding/seeding-public-route.vue"
      );
    },
    meta: {
      title: "Entry List",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.RESULTS_ENTRY,
    path: LAUNCH_ROUTES_PATHS.RESULTS_ENTRY + "/:compId/:eventGroupId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "result-route" */ "../competition/results/result-route.vue"
      );
    },
    meta: {
      title: "Results Entry",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.RESULT_READ,
    path: LAUNCH_ROUTES_PATHS.RESULT_READ + "/:compId/:eventGroupId",
    component: () => {
      return import(
        /* webpackChunkName: "result-read-route" */ "../competition/results/public/read/result-read-route.vue"
      );
    },
    meta: {
      title: "Results Read",
    },
  },
  // {
  //   name: LAUNCH_ROUTES_PATHS.SHOW_ENTRY,
  //   path: "/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRY + "/:id",
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "show-entry" */ "../public/entry-public/entry-direct.vue"
  //     );
  //   },
  //   meta: {
  //     title: (params: { id: string }) => {
  //       return params.id + ": Comp";
  //     },
  //   },
  // },
  // {
  //   name: LAUNCH_ROUTES_PATHS.SHOW_ENTRY,
  //   path: "/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRY + "/:id",
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "show-entry-v2" */ "../public/entry-public/v2/entry-direct-v2.vue"
  //     );
  //   },
  //   meta: {
  //     title: (params: { id: string }) => {
  //       return params.id + ": Comp";
  //     },
  //   },
  // },
  {
    name: LAUNCH_ROUTES_PATHS.SHOW_ENTRY,
    path: "/" + LAUNCH_ROUTES_PATHS.SHOW_ENTRY + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "show-entry-v2-mnb" */ "../public/entry-public/v2/entry-direct-v1.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Comp";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.COMP_EVENT_GROUP_ATHLETES,
    path: "/" + LAUNCH_ROUTES_PATHS.COMP_EVENT_GROUP_ATHLETES + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "event-group-athlete-grid" */ "../competition/event-group-athletes/event-group-athlete-grid-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Event Group";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.EMAIL_MESSAGES,
    path: "/" + LAUNCH_ROUTES_PATHS.EMAIL_MESSAGES + "/:compId?",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "email-messages" */ "../email-message/grid/email-message-grid-route.vue"
      );
    },
    meta: {
      title: (params: { compId: number }) => {
        return (
          "Messages" +
          (params.compId && params.compId > 0
            ? " for Comp: " + params.compId
            : "")
        );
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.EMAIL_MESSAGE,
    path: "/" + LAUNCH_ROUTES_PATHS.EMAIL_MESSAGE + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "email-message" */ "../email-message/email-message-form/email-message-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Message";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.EMAIL_MESSAGE_CREATE,
    path: "/" + LAUNCH_ROUTES_PATHS.EMAIL_MESSAGE_CREATE + "/:compId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "email-message-create" */
        "../email-message/email-message-create/email-message-create-route.vue"
      );
    },
    meta: {
      title: () => {
        return "Message Create";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.COMP_EVENT_AGE_GROUP_COVERAGE,
    path: "/" + LAUNCH_ROUTES_PATHS.COMP_EVENT_AGE_GROUP_COVERAGE + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "compevent-age-group-coverage-route" */
        "../compevent/compeventschedule/compevent-age-group-coverage/compevent-age-group-coverage-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Event Group";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.AUTO_ENTRIES,
    path: LAUNCH_ROUTES_PATHS.AUTO_ENTRIES + "/:compId",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "auto-entries-route" */
        "../competition/results/public/entries/auto-entries-schedule-route.vue"
      );
    },
    meta: {
      title: "Results",
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.SHOP,
    path: "/" + LAUNCH_ROUTES_PATHS.SHOP + "/:id",
    component: () => {
      return import(
        /* webpackChunkName: "secondary-public" */
        "../secondary/secondary-public.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Shop";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.RESULTS_IMPORT,
    path: "/" + LAUNCH_ROUTES_PATHS.RESULTS_IMPORT + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "results-import-route" */
        "../competition/results-import/ResultsImportRoute.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": TimeTronics";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.STRIPE_USERS,
    path: "/" + LAUNCH_ROUTES_PATHS.STRIPE_USERS,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "stripe-users-wrapper" */ "../admin/stripe/StripeUsersWrapper.vue"
      );
    },
    meta: {
      title: () => {
        return "Stripe Users";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.RESULTS_IMPORT_FILE,
    path: "/" + LAUNCH_ROUTES_PATHS.RESULTS_IMPORT_FILE + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "results-import-file" */ "../competition/results/results-file-import/ResultsImportFileRoute.vue"
      );
    },
    meta: {
      title: () => {
        return "Photo Finish";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CLUB_SEARCH,
    path: "/" + LAUNCH_ROUTES_PATHS.CLUB_SEARCH,
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "club-search" */ "../club/v2/club-search.vue"
      );
    },
    meta: {
      title: () => {
        return "Clubs";
      },
    },
  },
  {
    name: LAUNCH_ROUTES_PATHS.CLUB,
    path: "/" + LAUNCH_ROUTES_PATHS.CLUB + "/:id",
    beforeEnter: isLoggedInGuard,
    component: () => {
      return import(
        /* webpackChunkName: "club-route" */ "../club/v2/club-route.vue"
      );
    },
    meta: {
      title: (params: { id: string }) => {
        return params.id + ": Club";
      },
    },
  },
];

export const e4sLoginJwtHelper = () => {
  const e4sTokenStorage = sessionStorage.getItem("e4s_token");
  if (
    e4sTokenStorage !== undefined &&
    e4sTokenStorage !== null &&
    e4sTokenStorage !== ""
  ) {
    const e4sToken = JSON.parse(e4sTokenStorage) as ITokenReponse;
    appStore.commit(
      AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME +
        "/" +
        AUTH_STORE_CONST.AUTH_MUTATIONS_TOKEN_RESPONSE,
      e4sToken
    );
  }
};

type LaunchRoutesV1 = typeof LAUNCH_ROUTES_PATHS;
export type LaunchRouteKeyV1 = keyof LaunchRoutesV1;
export type LaunchRouteValueV1 = LaunchRoutesV1[keyof LaunchRoutesV1];

export const widerRoutesClassV1: Partial<Record<LaunchRouteValueV1, string>> = {
  home: "e4s-width-controller-entry",
  showentries: "e4s-width-controller-entry",
  "entry-conditions": "e4s-width-controller-entry",
  "show-entry": "e4s-width-controller-entry",
  builder: "e4s-width-controller--builder",
};

export function getWrapperClassV1(
  //  If ew are on an old route, we want to use the old class

  routeName: LaunchRouteValueV1 | undefined | null | ""
) {
  if (!routeName) {
    return "row";
  }
  if (routeName === "") {
    return "row";
  }
  const classValue: LaunchRouteValueV1 | undefined =
    widerRoutesClassV1[routeName];
  if (classValue) {
    return "e4s-flex-row e4s-justify-flex-center";
  }
  return "row";
}

export function getContentWidthClassV1(
  routeName: LaunchRouteValueV1 | undefined | null | ""
) {
  if (!routeName) {
    return "class-route-name-null";
  }
  if (routeName === "") {
    return "class-route-name-empty";
  }
  const classValue: LaunchRouteValueV1 | undefined =
    widerRoutesClassV1[routeName];
  if (classValue) {
    return classValue;
  }
  return "e4s-full-width";
}
