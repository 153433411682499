import { ResourceData } from "../common/resource/resource-service";
import { IBuilderCompetition } from "./builder-models";
import { IBase, IServerResponse, IsoDate } from "../common/common-models";
import https from "../common/https";

export class BuilderData extends ResourceData<IBuilderCompetition> {
  constructor() {
    super("/v5/builder");
  }

  public cloneCompetition(
    aoCode: string,
    id: number,
    date: IsoDate
  ): Promise<IServerResponse<IBase>> {
    const payload = {
      env: aoCode,
      id,
      date,
    };
    return https.post(
      this.getEndPoint() + "/competition/clone",
      payload
    ) as any as Promise<IServerResponse<IBase>>;
  }

  public reportCreateUpdate(
    compId: number,
    reportId: string = "",
    googleEmail: string = ""
  ): Promise<any> {
    return https.get(
      "/v5/report?compid=" +
        compId +
        "&reportid=" +
        reportId +
        "&email=" +
        googleEmail
    ) as any as Promise<any>;
  }

  public readId(compId: number): Promise<any> {
    return https.get(
      "/v5/report/readid?compid=" + compId
    ) as any as Promise<any>;
  }
}
