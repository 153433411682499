import { ActionContext, Module } from "vuex";
import { IRootState, useStore } from "../../app.store";
import * as R from "ramda";
import { AthleteData } from "../../athlete/athlete-data";
import { IAthlete } from "../../athlete/athlete-models";
import {
  IServerGenericResponse,
  IServerResponse,
} from "../../common/common-models";
import { CONFIG } from "../../common/config";
import { debounce } from "../../common/debounce";
import { ICompetitionInfo } from "../../competition/competition-models";
import { ENTRY_STORE_CONST } from "../../entry/entry-store";
import { IAreaTeamSummary } from "../../team/team-models";
import { TEAM_STORE_CONST } from "../../team/team-store";
import {
  IUserMessage,
  USER_MESSAGE_LEVEL,
} from "../../user-message/user-message-models";
import {
  messageDispatchHelper,
  USER_MESSAGE_STORE_CONST,
} from "../../user-message/user-message-store";
import { AthleteCompSchedData } from "../athletecompsched-data";
import {
  IAgeInfo,
  IAthleteCompSched,
  IAthleteCompSchedResponse,
  IAthleteCompSchedRuleEvent,
  IEventAction,
  IEventActions,
  IPbKey,
  IPbMultiItem,
  IProdIdServerResponse,
  RULE_TYPE,
} from "../athletecompsched-models";
import { AthleteCompSchedService } from "../athletecompsched-service";
import { COMP_EVENT_TEAMS_STORE_CONST } from "../comp-event-teams/comp-event-store";
import { ICartWooCommerce } from "../../secondary/secondary-models";
import { handleResponseMessages } from "../../common/handle-http-reponse";
import { CONFIG_STORE_CONST } from "../../config/config-store";
import { IUserApplication, IUserInfo } from "../../config/config-app-models";
import { computed } from "@vue/composition-api";
import * as ClubCompInfoService from "../../entry/v2/schools/clubCompInfoService";
import { IPerfInfo } from "../pb/v3/edit-pb-v3-models";
import { simpleClone } from "../../common/common-service-utils";

const athleteCompSchedData: AthleteCompSchedData = new AthleteCompSchedData();
const athleteCompSchedService: AthleteCompSchedService =
  new AthleteCompSchedService();
const athleteData: AthleteData = new AthleteData();

export enum ATH_COMP_SCHED_STORE_CONST {
  ATH_COMP_SCHED_CONST_MODULE_NAME = "ATH_COMP_SCHED_CONST_MODULE_NAME",

  // <MUTATIONS>
  ATH_COMP_SCHED_EVENTS_SERVER_RESP = "ATH_COMP_SCHED_EVENTS_SERVER_RESP",
  ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS = "ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS",
  ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENT_UPDATE = "ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENT_UPDATE",
  ATH_COMP_SCHED_MUTATIONS_SELECTED = "ATH_COMP_SCHED_MUTATIONS_SELECTED",
  ATH_COMP_SCHED_MUTATIONS_CURRENT_SELECTED_EVENT = "ATH_COMP_SCHED_MUTATIONS__CURRENT_SELECTED_EVENT",
  ATH_COMP_SCHED_MUTATIONS_T_AND_C = "ATH_COMP_SCHED_MUTATIONS_T_AND_C",
  ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET = "ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET",
  ATH_COMP_SCHED_MUTATIONS_SET_CART = "ATH_COMP_SCHED_MUTATIONS_SET_CART",
  ATH_COMP_SCHED_MUTATIONS_SET_CART_LOADING = "ATH_COMP_SCHED_MUTATIONS_SET_CART_LOADING",
  ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING = "ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING",
  ATH_COMP_SCHED_MUTATIONS_PROCESS_PB = "ATH_COMP_SCHED_MUTATIONS_PROCESS_PB",
  ATH_COMP_SCHED_MUTATIONS_SET_PB = "ATH_COMP_SCHED_MUTATIONS_SET_PB",
  ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING = "ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING",
  ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTED_MESSAGE = "ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTED_MESSAGE",
  ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT = "ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT",
  ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_CANCEL = "ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_CANCEL",
  ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_SET_DEST = "ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_SET_DEST",
  // </MUTATIONS>

  //  <GETTERS>
  ATH_COMP_SCHED_GETTERS_AGE_INFO = "ATH_COMP_SCHED_GETTERS_AGE_INFO",
  ATH_COMP_SCHED_GETTERS_ORDER_SUMMARY_TOTALS = "ATH_COMP_SCHED_GETTERS_ORDER_SUMMARY_TOTALS",
  ATH_COMP_SCHED_GETTERS_SERVER_RESP = "ATH_COMP_SCHED_GETTERS_SERVER_RESP",
  //  </GETTERS>

  // <ACTIONS>
  ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS = "ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS",
  ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS = "ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS",
  ATH_COMP_SCHED_ACTIONS_SELECTED = "ATH_COMP_SCHED_ACTIONS_SELECTED",
  ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED = "ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED",
  ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED_NEW = "ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED_NEW",
  ATH_COMP_SCHED_ACTIONS_SEND_TO_BASKET = "ATH_COMP_SCHED_ACTIONS_SEND_TO_BASKET",
  ATH_COMP_SCHED_ACTIONS_GET_CART = "ATH_COMP_SCHED_ACTIONS_GET_CART",
  ATH_COMP_SCHED_ACTIONS_SUBMIT_PB = "ATH_COMP_SCHED_ACTIONS_SUBMIT_PB",
  ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_MULTI = "ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_MULTI",
  ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_RELOAD_EVENTS = "ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_RELOAD_EVENTS",
  ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET = "ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET",
  ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET_CANCEL = "ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET_CANCEL",
  ATH_COMP_SCHED_ACTIONS_SET_PAID = "ATH_COMP_SCHED_ACTIONS_SET_PAID",
  // </ACTIONS>
}

export interface IAthCompSchedStoreState {
  eventsServerResponse: IAthleteCompSchedResponse;
  eventsProcessed: IAthleteCompSchedRuleEvent[];
  eventsSelected: IAthleteCompSchedRuleEvent[];
  currentSelectedEvent: IAthleteCompSchedRuleEvent;
  tcConfirmed: boolean;
  isSubmitting: boolean;
  cart: IServerResponse<IAthleteCompSched[], { wcCart: ICartWooCommerce }>;
  cartLoading: boolean;
  eventsLoading: boolean;
  isSubmittingPb: boolean;
  submittedPbMessage: IUserMessage;
  eventAction: IEventAction;
  eventSource: IAthleteCompSchedRuleEvent;
  eventDest: IAthleteCompSchedRuleEvent;
  eventActionTemp: IAthleteCompSchedRuleEvent;
}

const mutations = {
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_EVENTS_SERVER_RESP](
    state: IAthCompSchedStoreState,
    athleteCompSchedResponse: IAthleteCompSchedResponse
  ) {
    state.eventsServerResponse = R.clone(athleteCompSchedResponse);
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SELECTED](
    state: IAthCompSchedStoreState,
    eventsSelected: IAthleteCompSchedRuleEvent[]
  ) {
    if (!eventsSelected) {
      return;
    }
    state.eventsSelected = R.clone(eventsSelected);
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS](
    state: IAthCompSchedStoreState,
    events: IAthleteCompSchedRuleEvent[]
  ) {
    state.eventsProcessed = R.clone(events);
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENT_UPDATE](
    state: IAthCompSchedStoreState,
    event: IAthleteCompSchedRuleEvent
  ) {
    let eventsProcessed = R.clone(state.eventsProcessed);
    eventsProcessed = eventsProcessed.map((evt) => {
      if (evt.ceid === event.ceid) {
        return R.clone(event);
      }
      return evt;
    });
    state.eventsProcessed = eventsProcessed;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_T_AND_C](
    state: IAthCompSchedStoreState,
    isConfirmed: boolean
  ) {
    state.tcConfirmed = isConfirmed;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET](
    state: IAthCompSchedStoreState,
    isSubmitting: boolean
  ) {
    state.isSubmitting = isSubmitting;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_CART](
    state: IAthCompSchedStoreState,
    cart: any
  ) {
    state.cart = cart;
    //  state.eventsSelected = cart;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_CART_LOADING](
    state: IAthCompSchedStoreState,
    isLoading: boolean
  ) {
    state.cartLoading = isLoading;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING](
    state: IAthCompSchedStoreState,
    isLoading: boolean
  ) {
    console.log(
      "ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING being set to: " + isLoading
    );
    state.eventsLoading = isLoading;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_CURRENT_SELECTED_EVENT](
    state: IAthCompSchedStoreState,
    event: IAthleteCompSchedRuleEvent
  ) {
    state.currentSelectedEvent = R.clone(event);
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING](
    state: IAthCompSchedStoreState,
    isSubmitting: boolean
  ) {
    state.isSubmittingPb = isSubmitting;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_PB](
    state: IAthCompSchedStoreState,
    payload: { compEvent: IAthleteCompSchedRuleEvent; perfInfo: IPerfInfo }
  ) {
    const compEvents = simpleClone(state.eventsProcessed);
    compEvents.forEach((evt) => {
      if (evt.ceid === payload.compEvent.ceid) {
        evt.pb = payload.perfInfo.perf;
        evt.perfInfo = simpleClone(payload.perfInfo);

        if (evt.ruleType === "PB") {
          evt.ruleMessage = "";
          evt.ruleIsDisabledBy = false;
          evt.ruleType = RULE_TYPE.NONE;
        }
      }
    });

    state.eventsProcessed = simpleClone(compEvents);
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTED_MESSAGE](
    state: IAthCompSchedStoreState,
    message: IUserMessage
  ) {
    state.submittedPbMessage = message;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT](
    state: IAthCompSchedStoreState,
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent
  ) {
    state.eventSource = R.clone(athleteCompSchedRuleEvent);
    state.eventDest = {} as IAthleteCompSchedRuleEvent;
    state.eventAction = IEventActions.SWITCH_EVENT;

    state.eventActionTemp = {
      ...R.clone(athleteCompSchedRuleEvent),
      ...({
        entered: false,
        paid: 0,
        ruleIsDisabledBy: false,
        ruleMessage: "",
        ruleType: "",
        userSelected: false,
        userEventAction: IEventActions.SWITCH_EVENT,
      } as IAthleteCompSchedRuleEvent),
    };
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_CANCEL](
    state: IAthCompSchedStoreState,
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent
  ) {
    state.eventSource = {} as IAthleteCompSchedRuleEvent;
    state.eventDest = {} as IAthleteCompSchedRuleEvent;
    state.eventAction = IEventActions.NONE;

    state.eventActionTemp = {} as IAthleteCompSchedRuleEvent;
  },
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_SET_DEST](
    state: IAthCompSchedStoreState,
    athleteCompSchedRuleEvent: IAthleteCompSchedRuleEvent
  ) {
    state.eventDest = R.clone(athleteCompSchedRuleEvent);
  },
};

const getters = {
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_GETTERS_AGE_INFO](
    state: IAthCompSchedStoreState
  ) {
    if (state.eventsServerResponse && state.eventsServerResponse.ageInfo) {
      return state.eventsServerResponse.ageInfo;
    } else {
      return {
        ageGroup: "",
        ageGroupId: 0,
        currentAge: 0,
        vetAgeGroup: "",
        vetAgeGroupId: 0,
      } as IAgeInfo;
    }
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_GETTERS_ORDER_SUMMARY_TOTALS](
    state: IAthCompSchedStoreState
  ) {
    return athleteCompSchedService.getOrderSummaryTotals(state.eventsSelected);
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_GETTERS_SERVER_RESP](
    state: IAthCompSchedStoreState
  ) {
    return state.eventsServerResponse;
  },
};

const actions = {
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS](
    context: ActionContext<IAthCompSchedStoreState, IRootState>
  ) {
    console.log("ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS");
    //  when initialising, we are always going to stuff the entered\ unpaid (NOPE...!) events in the cart.
    const athlete: IAthlete =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthlete;
    const selectedAthleteShowSecondaryClaim: boolean =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthleteShowSecondaryClaim;
    if (!(athlete && athlete.pbInfo)) {
      //  E.g. issuing refresh from cart
      context.commit(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS,
        []
      );
      return;
    }
    const pbKey: IPbKey = athleteCompSchedService.getPersonalBestAsKeyObject([
      ...athlete.pbInfo,
    ]);
    const selectedCompetition: ICompetitionInfo =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedCompetition;
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
      true
    );

    const promEvents = athleteCompSchedData.getCompSchedEventsForAthlete(
      selectedCompetition.id,
      athlete.id,
      selectedAthleteShowSecondaryClaim
        ? athlete.club2Id
        : athlete.clubId
        ? athlete.clubId
        : athlete.clubid
    );
    handleResponseMessages(promEvents);
    // return athleteCompSchedData.getCompSchedEventsForAthlete(
    //     selectedCompetition.id,
    //     athlete.id,
    //     selectedAthleteShowSecondaryClaim ? athlete.club2id : athlete.clubid
    // )
    return promEvents
      .then((response: IAthleteCompSchedResponse) => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_EVENTS_SERVER_RESP,
          response
        );
        // context.commit(
        //   ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
        //   false
        // );

        const isUserSwitching =
          context.state.eventAction.id === IEventActions.SWITCH_EVENT.id;
        let eventsFromFactory: IAthleteCompSchedRuleEvent[] =
          athleteCompSchedService.factoryEventsToEventRule(
            context.state.eventsServerResponse.events
          );
        //  sentry 1837174980  undefined
        let eventsSelected = context.state.eventsSelected
          ? [...context.state.eventsSelected]
          : [];
        const compRules = context.state.eventsServerResponse.rules;
        const ageInfo: IAgeInfo = context.state.eventsServerResponse.ageInfo;

        if (isUserSwitching) {
          const eventSource = context.state.eventActionTemp;
          eventsFromFactory = eventsFromFactory.map((evt) => {
            if (
              evt.ceid === eventSource.ceid &&
              evt.athleteid === eventSource.athleteid
            ) {
              return R.clone(eventSource);
            }
            return evt;
          });
          eventsSelected = eventsSelected.map((evt) => {
            if (
              evt.ceid === eventSource.ceid &&
              evt.athleteid === eventSource.athleteid
            ) {
              return R.clone(eventSource);
            }
            return evt;
          });
        }
        // const event = isUserSwitching ? context.state.eventActionTemp : null;
        // const selectedCompetition: ICompetitionInfo = context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME].entryForm.selectedCompetition;
        const userApplication: IUserApplication =
          context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME]
            .configApp.userInfo.user;
        const userInfo: IUserInfo =
          context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME]
            .configApp.userInfo;
        const eventsNew = athleteCompSchedService.processEvents(
          athlete,
          pbKey,
          compRules,
          null,
          eventsSelected,
          eventsFromFactory,
          userApplication,
          ageInfo,
          selectedCompetition,
          userInfo
        );
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS,
          eventsNew
        );

        return;
      })
      .finally(() => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
          false
        );
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      event: IAthleteCompSchedRuleEvent;
    }
  ) {
    console.log("ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS");
    let selectedEvents = context.state.eventsSelected;
    let events = context.state.eventsProcessed;
    const athlete: IAthlete =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthlete;
    const pbKey: IPbKey = athleteCompSchedService.getPersonalBestAsKeyObject([
      ...athlete.pbInfo,
    ]);
    const compRules = context.state.eventsServerResponse.rules;
    // const tokenResponse: ITokenReponse = context.rootState[AUTH_STORE_CONST.AUTH_CONST_MODULE_NAME].tokenReponse;
    const ageInfo: IAgeInfo = context.state.eventsServerResponse.ageInfo;
    const userApplication: IUserApplication =
      context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp
        .userInfo.user;

    const isUserSwitching =
      context.state.eventAction.id === IEventActions.SWITCH_EVENT.id;
    if (isUserSwitching) {
      const eventSource = context.state.eventSource;
      events = events.map((evt) => {
        if (
          evt.ceid === eventSource.ceid &&
          evt.athleteid === eventSource.athleteid
        ) {
          return R.clone(eventSource);
        }
        return evt;
      });
      selectedEvents = selectedEvents.map((evt) => {
        if (
          evt.ceid === eventSource.ceid &&
          evt.athleteid === eventSource.athleteid
        ) {
          return R.clone(eventSource);
        }
        return evt;
      });
      return;
    }
    const selectedCompetition: ICompetitionInfo =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedCompetition;
    const userInfo: IUserInfo =
      context.rootState[CONFIG_STORE_CONST.CONFIG_CONST_MODULE_NAME].configApp
        .userInfo;
    const eventsNew = athleteCompSchedService.processEvents(
      athlete,
      pbKey,
      compRules,
      payload.event,
      selectedEvents,
      events,
      userApplication,
      ageInfo,
      selectedCompetition,
      userInfo
    );

    console.log("ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS ......");
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PROCESS_EVENTS,
      eventsNew
    );
    return;
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SELECTED](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      setSelected: boolean;
      event: IAthleteCompSchedRuleEvent;
    }
  ) {
    //  ...loading
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
      true
    );

    console.log("ATH_COMP_SCHED_ACTIONS_SELECTED ......a");

    if (payload.event === null || payload.event === undefined) {
      context.commit(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
        false
      );
      console.log("ATH_COMP_SCHED_ACTIONS_SELECTED ......b");
      return;
    }

    /*
        const isUserSwitching = context.state.eventAction.id === IEventActions.SWITCH_EVENT.id;
        if ( isUserSwitching ) {
            context.commit(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_SET_DEST, payload.event);
            context.commit(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING, false);
            console.log("ATH_COMP_SCHED_ACTIONS_SELECTED ......c");
            return;
        }
         */

    const selectedTeam: IAreaTeamSummary =
      context.rootState[TEAM_STORE_CONST.TEAM_STORE_CONST_MODULE_NAME]
        .selectedTeam;
    const event: IAthleteCompSchedRuleEvent = R.clone(payload.event);
    const eventsSelected = R.clone(context.state.eventsSelected);

    const athlete2: IAthlete =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthlete;
    const showSecondaryClaim: boolean =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthleteShowSecondaryClaim;

    if (showSecondaryClaim) {
      event.club = athlete2.club2;
    }
    //  If true, then user in process of de-selecting
    /*
        if ((event.userSelected) && athleteCompSchedService.isSecondarySpendParent(event)) {
            const secondarySpendChildren = athleteCompSchedService.getSecondarySpendSelectedChildren(event, eventsSelected);
            if (secondarySpendChildren.length > 0) {
                // @ts-ignore
                const secNames: string = R.compose(R.join(","), R.map((evt: IAthleteCompSchedRuleEvent) => {
                    return evt.Name;
                }))(secondarySpendChildren);
                messageDispatchHelper(event.Name + " has secondary spend item(s), please remove these first: " + secNames, USER_MESSAGE_LEVEL.WARN);
                context.commit(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING, false);
                console.log("ATH_COMP_SCHED_ACTIONS_SELECTED ......d");
                return;
            }
        }
         */

    event.teamId = selectedTeam ? selectedTeam.teamId : 0;

    /*
        const eventInCart =  R.head(eventsSelected.filter((evt: IAthleteCompSchedRuleEvent) => {
            return evt.ceid === event.ceid;
        }));
         */
    const eventsNewSelected = athleteCompSchedService.toggleEventInSelectedList(
      event,
      eventsSelected
    );

    //  Team relay where e.g. U11 athletes can enter an U13, we need the ageGroupInfo on the event
    //  in the cart, else we can't tell what age group the user actually is.
    const ageInfo: IAgeInfo = context.state.eventsServerResponse.ageInfo;
    event.ageInfo = { ...ageInfo };

    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SELECTED,
      eventsNewSelected
    );

    //  Don't return this function, else ui lags while waiting for prod id.
    const athlete: IAthlete =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthlete;
    const selectedAthleteShowSecondaryClaim: boolean =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthleteShowSecondaryClaim;
    let clubId = selectedAthleteShowSecondaryClaim
      ? athlete.club2Id
      : athlete.clubId
      ? athlete.clubId
      : athlete.clubid;

    const comp =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedCompetition;
    const compOptions = comp.options;

    //  Utter dross...but need to get this out quick.
    if (compOptions.genericCompAthleteEntity) {
      if (compOptions.genericCompAthleteEntity.type !== "") {
        if (
          athlete.options &&
          athlete.options.genericCompAthleteEntities &&
          athlete.options.genericCompAthleteEntities[comp.id]
        ) {
          clubId = athlete.options.genericCompAthleteEntities[comp.id].id;
        }
      }
    }

    return athleteCompSchedData
      .getProdIdFor(
        event.ceid,
        event.athleteid,
        clubId,
        event.teamId,
        event.perfInfo.perf
      )
      .then((prodIdServerResponse: IProdIdServerResponse) => {
        if (prodIdServerResponse.errNo > 0) {
          messageDispatchHelper(
            prodIdServerResponse.error,
            USER_MESSAGE_LEVEL.ERROR
          );
          if (
            prodIdServerResponse.message &&
            prodIdServerResponse.message.length > 0
          ) {
            messageDispatchHelper(
              prodIdServerResponse.message,
              USER_MESSAGE_LEVEL.INFO
            );
          }
        }

        //  <hack-get-entities>
        const selectedCompetition =
          context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
            .entryForm.selectedCompetition;

        if (
          ClubCompInfoService.hasClubCompInfoCompetition(
            selectedCompetition.clubCompInfo
          )
        ) {
          context.dispatch(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
              "/" +
              ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO,
            { compId: selectedCompetition.id },
            { root: true }
          );
        }

        /*
        const promGetClubCompInfo = new CompetitionData().getClubCompInfo(
          compId
        );
        handleResponseMessages(promGetClubCompInfo);
        promGetClubCompInfo.then((resp) => {
          console.log("xxxxxxxxxxxxxxx", resp.meta);
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
            "/" +
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO,
            resp.meta!.clubCompInfo,
            { root: true }
          );
        });
         */
        //  </hack-get-entities>

        return context
          .dispatch(
            ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
          )
          .then(() => {
            return context.dispatch(
              ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART
            );
          });
      })
      .finally(() => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
          false
        );
      });

    console.log("ATH_COMP_SCHED_ACTIONS_SELECTED ......z");
  },

  //  TODO deprecated
  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      event: IAthleteCompSchedRuleEvent;
    }
  ) {
    messageDispatchHelper(
      "ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED deprecated!",
      USER_MESSAGE_LEVEL.ERROR.toString()
    );

    if (payload.event === null || payload.event === undefined) {
      return;
    }
    const event = { ...payload.event };
    const eventsSelected = context.state.eventsSelected;
    const eventsAfterRemove =
      athleteCompSchedService.removeEventFromSelectedByProdId(
        event,
        eventsSelected
      );
    if (
      (event.userSelected || event.order.productId > 0) &&
      athleteCompSchedService.isSecondarySpendParent(event)
    ) {
      const secondarySpendChildren =
        athleteCompSchedService.getSecondarySpendSelectedChildren(
          event,
          eventsSelected
        );
      if (secondarySpendChildren.length > 0) {
        const secNames: string = R.compose(
          R.join(","),
          R.map((evt: IAthleteCompSchedRuleEvent) => {
            return evt.Name;
          })
        )(secondarySpendChildren);
        messageDispatchHelper(
          event.Name +
            " has secondary spend item(s), please remove these first: " +
            secNames,
          USER_MESSAGE_LEVEL.WARN
        );
        return;
      }
    }

    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SELECTED,
      eventsAfterRemove
    );

    return athleteCompSchedData
      .removeFromBasket(event.order.productId)
      .then((response: IServerGenericResponse) => {
        if (response.errNo > 0) {
          messageDispatchHelper(response.error, USER_MESSAGE_LEVEL.WARN);
        } else {
          messageDispatchHelper(
            event.Name +
              " removed from basket for athlete: " +
              event.firstName +
              " " +
              event.surName,
            USER_MESSAGE_LEVEL.INFO
          );
        }
        context.dispatch(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
        );
        //  If team event and removing event, the back end may have recalc'd price, reload cart.
        // const isTeamEventWithPrice = athleteCompSchedService.isTeamEvent(event) && (event.price.actualPrice > 0 );
        // const hasSecondarySpendEvents = athleteCompSchedService.getAssociatedSecondarySpendEvents(event, eventsSelected);
        // if (isTeamEventWithPrice || hasSecondarySpendEvents.length > 0) {
        // @ts-ignore
        debounceRefreshCart(context);
        // }
        const selectedCompetition: ICompetitionInfo =
          context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
            .entryForm.selectedCompetition;
        if (selectedCompetition && selectedCompetition.id) {
          context.dispatch(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_CONST_MODULE_NAME +
              "/" +
              COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_ACTIONS_GET_TEAM_HEADERS,
            {
              compId: selectedCompetition.id,
            },
            {
              root: true,
            }
          );
        }

        //  <hack-get-entities>
        // const compId =
        //   context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        //     .entryForm.selectedCompetition.id;

        if (
          ClubCompInfoService.hasClubCompInfoCompetition(
            selectedCompetition.clubCompInfo
          )
        ) {
          context.dispatch(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
              "/" +
              ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO,
            { compId: selectedCompetition.id },
            { root: true }
          );
        }

        /*
        const promGetClubCompInfo = new CompetitionData().getClubCompInfo(
          compId
        );
        handleResponseMessages(promGetClubCompInfo);
        promGetClubCompInfo.then((resp) => {
          console.log("xxxxxxxxxxxxxxx remove", resp.meta);
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
            "/" +
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO,
            resp.meta!.clubCompInfo,
            { root: true }
          );
        });
        */
        //  </hack-get-entities>

        return;
      })
      .catch((error) => {
        console.log("athleteCompSchedData.removeFromBasket error", error);
        // @ts-ignore
        debounceRefreshCart(context);
        return;
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_REMOVE_FROM_SELECTED_NEW](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      compEvent: IAthleteCompSchedRuleEvent;
    }
  ) {
    if (payload.compEvent === null || payload.compEvent === undefined) {
      return;
    }
    const compEvent = { ...payload.compEvent };
    /*
        const eventsSelected = context.state.eventsSelected;
         */
    /*
        const eventInCart =  R.head(eventsSelected.filter((evt: IAthleteCompSchedRuleEvent) => {
            return evt.ceid === event.ceid;
        }));
        */
    // const eventsAfterRemove = athleteCompSchedService.removeEventFromSelectedByProdId(event, eventsSelected);
    /*
        if ((event.order.productId > 0) && athleteCompSchedService.isSecondarySpendParent(event)) {
            const secondarySpendChildren = athleteCompSchedService.getSecondarySpendSelectedChildren(event, eventsSelected);
            if (secondarySpendChildren.length > 0) {
                const secNames: string = R.compose(R.join(","), R.map((evt: IAthleteCompSchedRuleEvent) => {
                    return evt.Name;
                }))(secondarySpendChildren);
                messageDispatchHelper(event.Name + " has secondary spend item(s), please remove these first: " + secNames, USER_MESSAGE_LEVEL.WARN);
                return;
            }
        }
         */
    /*
        if (!eventInCart) {
            messageDispatchHelper(event.Name + " remove from basket for athlete: " +
                event.firstName + " " + event.surName + ": Could not find the product id for ", USER_MESSAGE_LEVEL.ERROR);
            return;
        }
        */

    // context.commit(
    //     ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING,
    //     true
    // );
    const prom = athleteCompSchedData.removeFromBasket(
      compEvent.order.productId
    );
    handleResponseMessages(prom);
    return prom
      .then((response: IServerGenericResponse) => {
        if (response.errNo === 0) {
          if (!(response.message && response.message.length > 0)) {
            messageDispatchHelper(
              compEvent.Name +
                " removed from basket for athlete: " +
                compEvent.firstName +
                " " +
                compEvent.surName,
              USER_MESSAGE_LEVEL.INFO
            );
          }
        }

        const selectedCompetition: ICompetitionInfo =
          context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
            .entryForm.selectedCompetition;
        if (selectedCompetition && selectedCompetition.id) {
          context.dispatch(
            COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_CONST_MODULE_NAME +
              "/" +
              COMP_EVENT_TEAMS_STORE_CONST.COMP_EVENT_TEAMS_ACTIONS_GET_TEAM_HEADERS,
            {
              compId: selectedCompetition.id,
            },
            {
              root: true,
            }
          );
        }

        //  <hack-get-entities>
        if (
          ClubCompInfoService.hasClubCompInfoCompetition(
            selectedCompetition.clubCompInfo
          )
        ) {
          context.dispatch(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
              "/" +
              ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_CLUB_COMP_INFO,
            { compId: selectedCompetition.id },
            { root: true }
          );
        }
        /*
        const compId =
          context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
            .entryForm.selectedCompetition.id;

        const promGetClubCompInfo = new CompetitionData().getClubCompInfo(
          compId
        );
        handleResponseMessages(promGetClubCompInfo);
        promGetClubCompInfo.then((resp) => {
          console.log("xxxxxxxxxxxxxxx remove", resp.meta);
          context.commit(
            ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
            "/" +
            ENTRY_STORE_CONST.ENTRY_STORE_MUTATIONS_SET_CLUB_COMP_INFO,
            resp.meta!.clubCompInfo,
            { root: true }
          );
        });
        */

        //  </hack-get-entities>

        return context
          .dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART)
          .then(() => {
            return context.dispatch(
              ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
            );
          });
      })
      .catch((error) => {
        console.log("athleteCompSchedData.removeFromBasket error", error);
        return;
      })
      .finally(() => {
        // context.dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART);
        // context.commit(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_EVENTS_LOADING, false);
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SEND_TO_BASKET](
    context: ActionContext<IAthCompSchedStoreState, IRootState>
  ) {
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET,
      true
    );
    const eventsSelected = [...context.state.eventsSelected];
    const userMessages: IUserMessage[] =
      athleteCompSchedService.canCartBeSubmittedToBasket(eventsSelected);
    console.log(".......................", userMessages);
    if (userMessages.length > 0) {
      const messages: string[] = userMessages.map((message) => {
        return message.message;
      });

      context.commit(
        USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
          "/" +
          USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
        {
          level: USER_MESSAGE_LEVEL.WARN,
          message:
            "<div>" + messages.join('<div class="divider"></div>') + "</div>",
          timeOut: 10000,
        } as IUserMessage,
        {
          root: true,
        }
      );
      context.commit(
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET,
        false
      );
      return;
    }
    const prodIds = eventsSelected.map((evt) => {
      return evt.order.productId;
    });
    athleteCompSchedData
      .addToBasket(prodIds)
      .then((response: IServerGenericResponse) => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
            "/" +
            ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SUBMITTING_TO_BASKET,
          false
        );
        let userMessage: IUserMessage;
        if (response.errNo !== 0) {
          userMessage = {
            level: USER_MESSAGE_LEVEL.WARN,
            message: "Error submitting to basket.",
          } as IUserMessage;
        } else {
          userMessage = {
            level: USER_MESSAGE_LEVEL.INFO,
            message: "Submitted to basket.",
          } as IUserMessage;
          //   Since successful, go straight to basket.
          window.location.href = CONFIG.WP_BASKET;
        }
        context.commit(
          USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
            "/" +
            USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
          userMessage,
          {
            root: true,
          }
        );
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART](
    context: ActionContext<IAthCompSchedStoreState, IRootState>
  ) {
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_CART_LOADING,
      true
    );
    return athleteCompSchedData
      .getCart()
      .then((response) => {
        if (response.errNo > 0) {
          return;
        }
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_CART,
          response
        );
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SELECTED,
          response.data
        );
        return;
      })
      .catch((error) => {
        messageDispatchHelper(error, USER_MESSAGE_LEVEL.ERROR.toString());
      })
      .finally(() => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SET_CART_LOADING,
          false
        );
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_RELOAD_EVENTS](
    context: ActionContext<IAthCompSchedStoreState, IRootState>
  ) {
    const athlete: IAthlete =
      context.rootState[ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME]
        .entryForm.selectedAthlete;

    context
      .dispatch(
        ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME +
          "/" +
          ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_SELECTED_ATHLETE,
        { id: athlete.id, forceReload: true },
        { root: true }
      )
      .then(() => {
        context.dispatch(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
        );
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SUBMIT_PB](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      eventName: string;
      pb: number;
      pbText: string;
      athleteId: number;
      eventId: number;
      compEventDisabled: boolean;
      trackSb: boolean;
      ceid: number;
    }
  ): Promise<boolean> {
    // const eventy = JSON.parse(JSON.stringify(payload.event));
    if (payload.pb === undefined || payload.pb === null) {
      return Promise.resolve(false);
    }

    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING,
      true
    );
    return athleteData
      .submitAthletePb(
        payload.athleteId,
        payload.eventId,
        payload.pb,
        payload.pbText,
        payload.trackSb,
        payload.ceid
      )
      .then((pbUpdateServerResponse: IServerGenericResponse) => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING,
          false
        );
        if (pbUpdateServerResponse.errNo !== 0) {
          //  Some server error and or validation failure.
          // return false;
          context.commit(
            USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
              "/" +
              USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
            {
              level: USER_MESSAGE_LEVEL.INFO,
              message: "Error updating PB.",
            } as IUserMessage,
            {
              root: true,
            }
          );
          context.commit(
            ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTED_MESSAGE,
            {
              level: USER_MESSAGE_LEVEL.WARN,
              message: "Error updating PB.",
            } as IUserMessage
          );
          return false;
        }

        context.commit(
          USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
            "/" +
            USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
          {
            level: USER_MESSAGE_LEVEL.INFO,
            message: "PB saved for " + payload.eventName,
          } as IUserMessage,
          {
            root: true,
          }
        );

        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTED_MESSAGE,
          {
            level: USER_MESSAGE_LEVEL.INFO,
            message: "PB saved for " + payload.eventName,
          } as IUserMessage
        );

        //  Only in specific circumstances, where changing PB will change status of events.
        //  If current event is not selected, and is disabled by a rule, does the new
        //  PB put it in tolerance...it must have, 'cos it passed the PB isValid() checks.
        // if (event.ruleIsDisabledBy) {
        //     context.dispatch(
        //         ENTRY_STORE_CONST.ENTRY_STORE_CONST_MODULE_NAME + "/" +
        //         ENTRY_STORE_CONST.ENTRY_STORE_ACTIONS_GET_SELECTED_ATHLETE,
        //         {id: payload.athleteId, forceReload: true},
        //         {root: true}
        //     ).then(() => {
        //         context.dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS);
        //     });
        // }
        return true;
      })
      .catch((err: any) => {
        context.commit(
          ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_PB_SUBMITTING,
          false
        );
        return false;
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SUBMIT_PB_MULTI](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      athlete: IAthlete;
      athleteMultiPb: IPbMultiItem[];
    }
  ) {
    const athleteMultiPb = { ...payload.athleteMultiPb };
    if (
      athleteMultiPb === undefined ||
      event === null ||
      athleteMultiPb.length === 0
    ) {
      return;
    }
    athleteData
      .submitAthleteMultiPb(payload.athlete.id, athleteMultiPb)
      .then((pbUpdateServerResponse: IServerGenericResponse) => {
        if (pbUpdateServerResponse.errNo !== 0) {
          //  Some server error and or validation failure.
          // return false;
          context.commit(
            USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
              "/" +
              USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
            {
              level: USER_MESSAGE_LEVEL.INFO,
              message: "Error updating Multi PBs.",
            } as IUserMessage,
            {
              root: true,
            }
          );
          return;
        }
      })
      .catch((err: any) => {
        return err;
      });
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      event: IAthleteCompSchedRuleEvent;
    }
  ) {
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT,
      payload.event
    );
    context.dispatch(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
    );
    // context.dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_PROCESS_EVENTS, {event: context.state.eventSource});
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SWITCH_EVENT_SET_CANCEL](
    context: ActionContext<IAthCompSchedStoreState, IRootState>
  ) {
    context.commit(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_MUTATIONS_SWITCH_EVENT_CANCEL
    );
    context.dispatch(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_INITIALISE_EVENTS
    );
  },

  [ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_SET_PAID](
    context: ActionContext<IAthCompSchedStoreState, IRootState>,
    payload: {
      prodIds: number[];
      paid: number;
      message: string;
    }
  ) {
    return athleteCompSchedData
      .setPaid(payload.prodIds, payload.paid, payload.message)
      .then((resp: IServerGenericResponse) => {
        if (resp.errNo !== 0) {
          context.commit(
            USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME +
              "/" +
              USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE,
            {
              level: USER_MESSAGE_LEVEL.INFO,
              message: "Error updating paid.",
            } as IUserMessage,
            {
              root: true,
            }
          );
          return false;
        }
        return true;
      })
      .catch((err: any) => {
        return false;
      });
  },
};

export const athCompSchedStore: Module<IAthCompSchedStoreState, IRootState> = {
  namespaced: true,
  state: {
    eventsServerResponse: {} as IAthleteCompSchedResponse,
    eventsSelected: [],
    eventsProcessed: [],
    currentSelectedEvent: {} as IAthleteCompSchedRuleEvent,
    tcConfirmed: false,
    isSubmitting: false,
    cart: {
      data: [],
      errNo: 0,
      error: "",
      meta: {
        wcCart: {
          currency: "",
          items: [],
          url: "",
        },
      },
    },
    cartLoading: false,
    eventsLoading: false,
    isSubmittingPb: false,
    submittedPbMessage: {
      level: USER_MESSAGE_LEVEL.INFO,
      message: "",
    } as IUserMessage,
    eventAction: IEventActions.NONE,
    eventSource: {} as IAthleteCompSchedRuleEvent,
    eventDest: {} as IAthleteCompSchedRuleEvent,
    eventActionTemp: {} as IAthleteCompSchedRuleEvent,
  },
  mutations,
  getters,
  actions,
};

const debounceRefreshCart = debounce(
  (context: ActionContext<IAthCompSchedStoreState, IRootState>) => {
    // appStore.dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART, null, { root: true });
    context.dispatch(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART
    );
  },
  1000
);

// const removeFromBasket = (context: ActionContext<IAthCompSchedStoreState, IRootState>, event: IAthleteCompSchedRuleEvent, events: IAthleteCompSchedRuleEvent[]) => {
//     // appStore.dispatch(ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART, null, { root: true });
//     const cartEventsObj = commonService.convertArrayToObject("ceid", events);
//     if (!athleteCompSchedService.isSecondarySpendEventParentUserSelected(event, cartEventsObj)) {
//         return athleteCompSchedData.removeFromBasket(event.prodId)
//             .then((response)  => {
//                 context.commit(
//                     USER_MESSAGE_STORE_CONST.USER_MESSAGE_CONST_MODULE_NAME + "/" +
//                     USER_MESSAGE_STORE_CONST.USER_MESSAGE_MUTATIONS_ADD_MESSAGE, {
//                         level: USER_MESSAGE_LEVEL.DEBUG,
//                         message: event.Name + " removed from basket for athlete: " + event.firstName + " " + event.surName
//                     } as IUserMessage,
//                     {
//                         root: true
//                     }
//                 );
//                 // @ts-ignore
//                 // debounceRefreshCart(context);
//                 return;
//             })
//             .catch((error) => {
//                 console.log("removeFromBasket athleteCompSchedData.removeFromBasket error", error);
//                 return;
//             });
//     }
//     return Promise.reject();
// };

export function useAthleteCompSchedStore() {
  const store = useStore();
  return store.state[
    ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME
  ];
}

export function useAthleteCompSchedStoreController() {
  const store = useStore();
  const athleteCompSchedStore = useStore();

  function reloadCart(): Promise<void> {
    return store.dispatch(
      ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_CONST_MODULE_NAME +
        "/" +
        ATH_COMP_SCHED_STORE_CONST.ATH_COMP_SCHED_ACTIONS_GET_CART
    );
  }

  const getState = computed(() => {
    return athleteCompSchedStore;
  });

  return {
    getState,
    reloadCart,
  };
}
