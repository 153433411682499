import {
  IAthlete,
  IAthleteOptions,
  IAthletePb,
  IAthleteSummary,
} from "../athlete-models";
import { IUserInfo } from "../../config/config-app-models";
import { IBaseConcrete, IsoDate } from "../../common/common-models";
import { ICompEventAgeGroupBase } from "../../compevent/compeventschedule/compevent-age-group-coverage/compevent-age-group-coverage-models";
import { ICompetitionBase } from "../../competition/competition-models";

export function factoryAthletePb(): IAthletePb {
  return {
    eventid: 0,
    eventName: "",
    pb: 0,
    pbText: "",
    uomInfo: {
      id: 0,
      type: "D",
      options: [],
    },
    min: 0,
    max: 0,
    pof10pb: 0,
    sb: 0,
    trackSb: false,
  };
}

export function factoryAthleteOptions(): IAthleteOptions {
  return {
    noEntryReason: "",
    emergency: {
      name: "",
      tel: "",
      relationship: "",
    },
    genericCompAthleteEntities: {},
  };
}

export interface IShouldBeClubRepState {
  clubMap: Record<number, number>;
  surNameMap: Record<any, number>;
  result: boolean;
}

export function resultShouldUserBeClubRep(
  athletes: IAthleteSummary[],
  userInfo: IUserInfo,
  surnameCountLimit = 2
): IShouldBeClubRepState {
  const initialState: IShouldBeClubRepState = {
    clubMap: {},
    surNameMap: {},
    result: false,
  };

  if (userInfo.areas.length > 0 || userInfo.clubs.length > 0) {
    return initialState;
  }

  //  same club, diff surname, > 3
  const shouldBeClubRepStateResult: IShouldBeClubRepState =
    athletes.reduce<IShouldBeClubRepState>((accum, athlete) => {
      const clubId = athlete.clubid;
      if (!accum.clubMap[clubId]) {
        accum.clubMap[clubId] = 0;
      }
      accum.clubMap[clubId]++;

      if (!accum.surNameMap[athlete.surName]) {
        accum.surNameMap[athlete.surName] = 0;
      }
      accum.surNameMap[athlete.surName]++;

      return accum;
    }, initialState);

  shouldBeClubRepStateResult.result = shouldBeClubRep(
    shouldBeClubRepStateResult,
    surnameCountLimit
  );

  return shouldBeClubRepStateResult;
}

export function hasSecondaryClaim(athlete: IAthlete): boolean {
  return athlete.club2Id > 0;
}

export function getPrimaryClub(athlete: IAthlete): IBaseConcrete {
  return {
    id: athlete.clubid,
    name: athlete.club,
  };
}

export function hasPowerOf10Claim(athlete: IAthlete): boolean {
  const hasCorrectAoCode = athlete.aocode === "EA";
  const hasUrn = !!(athlete.URN && athlete.URN.toString().length > 0);
  return hasCorrectAoCode && hasUrn;
}

export function getAthleteAgeDescription(
  athleteSummary: IAthleteSummary
): string {
  let ageDescription = getAgeGroupDescription(athleteSummary);
  if (ageDescription.length > 0) {
    ageDescription = " - " + ageDescription;
  }
  return ageDescription;
}

export function getAthleteGenderAgeDescription(
  athleteSummary: IAthlete
): string {
  const ageDescription = getAthleteAgeDescription(athleteSummary);
  return athleteSummary.gender
    ? " (" + athleteSummary.gender + ageDescription + ")"
    : "";
}

export function getAthleteDescription(athleteSummary: IAthleteSummary): string {
  // let ageDescription = getAgeGroupDescription(athleteSummary);
  // if (ageDescription.length > 0) {
  //   ageDescription = " - " + ageDescription;
  // }
  const ageDescription = getAthleteAgeDescription(athleteSummary);

  return (
    athleteSummary.firstName +
    " " +
    athleteSummary.surName +
    (athleteSummary.gender
      ? " (" + athleteSummary.gender + ageDescription + ")"
      : "")
  );
}

export function getAthleteNameDescription(
  athleteSummary: IAthleteSummary
): string {
  return athleteSummary.firstName + " " + athleteSummary.surName;
}

export function getAgeGroupDescription(
  athleteSummary: IAthleteSummary
): string {
  if (!(athleteSummary.ageInfo && athleteSummary.ageInfo.ageGroup)) {
    return "";
  }

  if (athleteSummary.ageInfo.ageGroup.id === 0) {
    return Math.floor(athleteSummary.ageInfo.currentAge).toString() + "yrs";
  }

  if (
    !athleteSummary.ageInfo.ageGroup.name &&
    athleteSummary.ageInfo.ageGroup.shortName
  ) {
    return athleteSummary.ageInfo.ageGroup.shortName;
  }

  return athleteSummary.ageInfo.ageGroup.name;
}

export interface IShouldBeClubRepState {
  clubMap: Record<number, number>;
  surNameMap: Record<any, number>;
  result: boolean;
}

export function shouldBeClubRep(
  shouldBeClubRepState: IShouldBeClubRepState,
  surnameCountLimit = 2
): boolean {
  const howManyClubs = Object.keys(shouldBeClubRepState.clubMap).length;
  const howManySurnames = Object.keys(shouldBeClubRepState.surNameMap).length;
  return howManyClubs === 1 && howManySurnames > surnameCountLimit;
}

export function getCompAgeGroup(
  compEventAgeGroups: ICompEventAgeGroupBase[],
  iso: IsoDate
): ICompEventAgeGroupBase | null {
  return compEventAgeGroups.reduce<ICompEventAgeGroupBase | null>(
    (accum, ageGroup) => {
      if (iso >= ageGroup.fromDate && iso <= ageGroup.toDate) {
        accum = ageGroup;
      }
      return accum;
    },
    null
  );
}

export function getYearsFromCompAgeGroup(
  compEventAgeGroups: ICompEventAgeGroupBase[]
): number[] {
  const years: number[] = compEventAgeGroups.reduce<number[]>(
    (accum, ageGroup) => {
      accum.push(Number(ageGroup.fromDate.split("-")[0]));
      accum.push(Number(ageGroup.toDate.split("-")[0]));
      return accum;
    },
    []
  );

  const min = Math.min(...years);
  const max = Math.max(...years);

  const diff = max - min;

  const yearsToReturn: number[] = [];
  for (let i = 0; i < diff + 1; i++) {
    yearsToReturn.push(min + i);
  }
  return yearsToReturn;
}

export function getClubName(athlete: IAthleteSummary | IAthlete): string {
  return athlete.club ? athlete.club : athlete.clubname;
}

export function getUrn(athlete: IAthleteSummary | IAthlete): string {
  return athlete.URN ? athlete.URN.toString() : "";
}

export function getCompAgeGroupMessage(
  dob: IsoDate,
  competitionBase: ICompetitionBase
): {
  isMatch: boolean;
  message: string;
} {
  if (
    dob.length === 0 ||
    competitionBase.compId === 0 ||
    competitionBase.compAgeGroups.length === 0
  ) {
    return {
      isMatch: true,
      message: "",
    };
  }

  const compEventAgeGroupBase: ICompEventAgeGroupBase | null = getCompAgeGroup(
    competitionBase.compAgeGroups,
    dob
  );
  const compName = competitionBase.compName
    ? competitionBase.compName
    : competitionBase.name;
  if (compEventAgeGroupBase) {
    return {
      isMatch: true,
      message:
        "For competition " +
        compName +
        ", athlete would be in age group: " +
        compEventAgeGroupBase.name,
    };
  } else {
    return {
      isMatch: false,
      message:
        "N.B. DOB does not fit within any age groups for competition " +
        compName,
    };
  }
}

export function getAthleteOptions(athlete: IAthleteSummary): IAthleteOptions {
  const defaultOptions = factoryAthleteOptions();
  let athleteOptions: IAthleteOptions = athlete.options;
  if (!athleteOptions) {
    return defaultOptions;
  }

  if (typeof athleteOptions === "string") {
    const optionsString = athleteOptions as string;
    if (
      optionsString.length === 0 ||
      optionsString === "undefined" ||
      optionsString === "null" ||
      optionsString === ""
    ) {
      return defaultOptions;
    }
    athleteOptions = JSON.parse(athleteOptions);
  }
  if (!athleteOptions.genericCompAthleteEntities) {
    athleteOptions.genericCompAthleteEntities = {};
  }
  return athleteOptions;
}
