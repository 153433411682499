import { IAgeGroupBase } from "../agegroup/agegroup-models";
import {
  CompIdNumber,
  GENDER,
  GenderType,
  IBase,
  IBaseConcrete,
  IServerGenericPagingResponse,
  IsoDate,
  IsoDateTime,
  PaidType,
  UrlFullPath,
} from "../common/common-models";
import { IAthleteEvent } from "./athlete-event/athlete-event-models";
import { IEventUom } from "../athletecompsched/athletecompsched-models";
import { AO_CODE } from "../common/ui/athletic-org/athletic-org-models";

// export enum GENDER {
//     UNKNOWN = "",
//     MALE = "M",
//     FEMALE = "F"
// }

export interface IAgeGroup extends IAgeGroupBase {
  Name: string; //  TODO can be deprecated by
  shortName: string;
}

export interface IAgeInfo {
  ageGroup: IAgeGroup; //  Soon to be deprecated, always use ageGroups.
  ageGroups: IAgeGroup[];
  vetAgeGroup: IVetAgeGroup; //  TODO  ...this is IAgeGRoup
  competitionAge: null | number;
  currentAge: number;
}

//  TODO  ...thisis IAgeGRoup
export interface IVetAgeGroup {
  Name: string;
  shortName: string;
}

export interface IAthleteUser {
  id: number;
  email: string;
  userName: string;
}

// export interface IAthleteOptions {
//   noEntryReason: string; //  Any value, athlete will not be able to enter.
// }

export interface IAthleteOptions {
  noEntryReason: string; //  Any value, athlete will not be able to enter.
  emergency: {
    name: string;
    tel: string;
    relationship: string;
  };
  genericCompAthleteEntities: Record<CompIdNumber, IBaseConcrete>;
}

export interface IAthleteSummary extends IBase {
  firstName: string;
  surName: string;
  URN: string;
  dob: IsoDate; //  yyyy-mm-dd
  club: string;
  clubname: string;
  clubid: number;
  clubId?: number;
  club2: string;
  club2id: number;
  club2Id: number;
  gender: GENDER;
  classification: number;
  schoolid: number;
  school: string;
  inTeam: boolean;
  ageInfo: IAgeInfo;
  aocode: AO_CODE;
  activeEndDate: string;
  userAthletes?: IAthleteUser[];
  events?: IAthleteEvent[];
  image: UrlFullPath;
  options: IAthleteOptions; // N.B. This can be a string.
  pbInfo: IAthletePb[];
  email: string;
  infoText: string; //  Some text/message.
}

export type PbDisciplineEventType = "T" | "D" | "P"; //  Time | Distance | Points

export interface IAthletePb {
  eventid: number;
  eventName: string;
  pb: null | number;
  pbText: string | number;
  uomInfo: {
    id: number;
    type: PbDisciplineEventType;
    options: IEventUom[];
  };
  min: number | string; //  Should always be number, but we have legacy data as string.
  max: number | string; //  as above.
  pof10pb: number | string;
  sb: number | string;
  trackSb: boolean;
}

export interface IAthletePbValidationResult {
  eventid: number;
  pb: null | number;
}

export const ATHLETE_VALIDATION_MESSAGES = {
  firstName: {
    NAME: "FIRSTNAME",
    MESSAGE: "Enter first name.",
  },
};

export const ATHLETE_PB_VALIDATION_MESSAGES = {
  PB_BELOW_MIN: {
    NAME: "PB_BELOW_MIN",
    MESSAGE: "PB is below standard min.",
  },
  PB_ABOVE_MIN: {
    NAME: "PB_ABOVE_MIN",
    MESSAGE: "PB is above standard min.",
  },
};

// export interface IPbUpdateServerResponse {
//     errno: number;
//     error: string;
// }

export interface IAthlete extends IAthleteSummary {
  pbInfo: IAthletePb[];
}

//  All lower case so we can just hand this off directly to HTTP params.
export interface IAthleteSearch {
  athleteid: number; //  we know exactly what we want.
  country: string;
  region: string;
  county: string;
  club: string;
  firstname: string;
  surname: string;
  gender: GenderType;
  ageGroupId: number | string;
  ceid: number;
  eventId: number; //  E.g. for League, if Id passed, only search for athletes in current
  //  comp who have entered this event.

  search: string; //  If passed, "adds" to back end where statement.
  urn: string;
  showAllAthletes: string; //  0 = only show eligible, 1 = show all athletes
}

export const ATHLETE_VALIDATION = {
  URN: {
    MESSAGE: "Please enter a URN of 6 characters.",
  },
};

export interface IServerGenericPagingResponseAthlete
  extends IServerGenericPagingResponse {
  ageGroupInfo: IAgeGroup;
}

export interface ICompEventGroupAthletes {
  comp: IBaseConcrete;
  eventGroup: IEventGroupEntries;
}

export interface IEventGroupEntries {
  id: number; //  EventGroupId
  date: IsoDateTime;
  name: string;
  eventNo: number;
  entries: IAthleteEntry[];
}

export interface IAthleteEntry {
  athlete: {
    id: number;
    firstName: string;
    surName: string;
    dob: IsoDate;
    gender: GenderType;
    ageGroup: string;
  };
  entry: {
    id: number;
    paid: PaidType;
    price: number;
    pb: unknown;
  };
  club: {
    id: number;
    clubName: string;
  };
  user: {
    id: number;
    userName: string;
    userDisplayName: string;
  };
}
