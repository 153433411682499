import {
  ICompetitionMoreInfoV2,
  ICompetitionSummaryPublic,
} from "../competition-models";
import { ref, computed } from "@vue/composition-api";
import { CONFIG } from "../../common/config";
import {
  eventDateDisplayCard,
  getE4sStandardHumanDateTimeOutPut,
  isValidHttpUrl,
  simpleClone,
} from "../../common/common-service-utils";
import { isAfter, parse } from "date-fns";
import { CompetitionService } from "../competiton-service";
import { useRoute, useRouter } from "../../router/migrateRouterVue3";
import { LAUNCH_ROUTES_PATHS_V2 } from "../../launch/v2/launch-routes-v2";
import * as CompetitionDataV2 from "./competition-data-v2";
import { ICompEventAgeGroupCoverageModel } from "../../compevent/compeventschedule/compevent-age-group-coverage/compevent-age-group-coverage-models";
import * as CompetitionServiceV2 from "./competiton-service-v2";
import { useConfigStore } from "../../config/useConfigStore";

export function useCompetitionSummaryPublicController(
  competitionSummaryPublic: ICompetitionSummaryPublic
) {
  const competitionSummaryPublicInternal = ref(
    simpleClone(competitionSummaryPublic)
  );

  const competitionMoreInfoV2 = ref<ICompetitionMoreInfoV2>({
    ageGroups: {},
    text: {
      checkinTerms: "",
      checkinText: "",
      e4sNotes: "",
      emailText: "",
      homeInfo: "",
      information: "",
      newsFlash: "",
      termsConditions: "",
    },
  });

  const isLoading = ref(false);
  const isEditingStatus = ref(false);

  const routeInternal = useRoute();
  const routerInternal = useRouter();

  const competitionService = new CompetitionService();

  const configStore = useConfigStore();

  function init(summaryPublic: ICompetitionSummaryPublic) {
    competitionSummaryPublicInternal.value = simpleClone(summaryPublic);
  }

  const getFirstCompDate = computed(() => {
    return eventDateDisplayCard(
      competitionSummaryPublicInternal.value.options.dates[0],
      new Date()
    );
  });

  const getCompDates = computed<string[]>(() => {
    return CompetitionServiceV2.getCompDates(
      competitionSummaryPublicInternal.value
    );
  });

  const getCompDatesListForDisplay = computed<string>(() => {
    return CompetitionServiceV2.getCompDatesListForDisplay(
      competitionSummaryPublicInternal.value
    );
  });

  const isMultiDay = computed(() => {
    return CompetitionServiceV2.isMultiDay(
      competitionSummaryPublicInternal.value
    );
  });

  const getLogo = computed(() => {
    if (
      competitionSummaryPublicInternal.value.logo ===
      "/resources/default_logo.gif"
    ) {
      return "";
    }
    return CONFIG.E4S_HOST + "/" + competitionSummaryPublicInternal.value.logo;
  });

  const getEntryOpenDate = computed(() => {
    return getE4sStandardHumanDateTimeOutPut(
      competitionSummaryPublicInternal.value.opendate,
      false
    );
  });

  const isPastOpenDate = computed(() => {
    return isAfter(
      new Date(),
      parse(competitionSummaryPublicInternal.value.opendate)
    );
  });

  const isPastCompDate = computed(() => {
    return CompetitionServiceV2.isPastCompDate(
      competitionSummaryPublicInternal.value
    );
  });

  const getLateEntryDate = computed(() => {
    if (
      competitionService.hasSaleEndDate(competitionSummaryPublicInternal.value)
    ) {
      return competitionService.getSaleEndMessage(
        competitionSummaryPublicInternal.value
      );
    }
    return "";
  });

  const hasLinkingTicketComp = computed(() => {
    const comp = competitionSummaryPublicInternal.value;
    const ticketBaseCompId =
      comp.options.ui.ticketCompBase && comp.options.ui.ticketCompBase.id
        ? comp.options.ui.ticketCompBase.id
        : 0;
    // return !!(comp.options.ui.ticketComp && comp.options.ui.ticketComp > 0);
    return ticketBaseCompId > 0;
  });

  function goToLinkingTicketComp() {
    const ticketCompBase =
      competitionSummaryPublicInternal.value.options.ui.ticketCompBase;
    const ticketBaseCompId =
      ticketCompBase && ticketCompBase.id ? ticketCompBase.id : 0;
    routerInternal.push({
      path:
        "/shop/" +
        ticketBaseCompId +
        "?parentCompId=" +
        competitionSummaryPublicInternal.value.compId,
    });
  }

  function goCompHome() {
    if (routeInternal.name === LAUNCH_ROUTES_PATHS_V2.SHOW_ENTRY_v2) {
      console.log(
        "useBreadCrumbController goCompHome already on: " +
          LAUNCH_ROUTES_PATHS_V2.SHOW_ENTRY_v2
      );
    }

    routerInternal.push({
      name: LAUNCH_ROUTES_PATHS_V2.SHOW_ENTRY_v2,
      params: {
        id: competitionSummaryPublicInternal.value.compId.toString(),
      },
    });
  }

  const getFlyerLink = computed(() => {
    if (isValidHttpUrl(competitionSummaryPublicInternal.value.link)) {
      return competitionSummaryPublicInternal.value.link;
    }

    const flyerLink =
      CONFIG.E4S_HOST + "/" + competitionSummaryPublicInternal.value.link;
    return flyerLink;
  });

  const hasFlyer = computed<boolean>(() => {
    return !!(
      competitionSummaryPublicInternal.value.link &&
      competitionSummaryPublicInternal.value.link.length > 0
    );
  });

  function getMoreInfoData(compId: number) {
    if (compId === 0) {
      return;
    }
    isLoading.value = true;
    CompetitionDataV2.getCompMoreInfo(compId)
      .then((result) => {
        if (result.errNo === 0) {
          competitionMoreInfoV2.value = result.data;
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  const getAgeGroups = computed(() => {
    return Object.values(competitionMoreInfoV2.value.ageGroups).map(
      (ageGroup: ICompEventAgeGroupCoverageModel) => {
        if (!ageGroup.todate) {
          //  @ts-ignore
          ageGroup.todate = ageGroup.toDate;
        }
        if (!ageGroup.fromdate) {
          //  @ts-ignore
          ageGroup.fromdate = ageGroup.fromDate;
        }
        return ageGroup;
      }
    );
  });

  const getEnterButtonText = computed(() => {
    const builderOptions = competitionSummaryPublicInternal.value.options;
    if (
      builderOptions &&
      builderOptions.ui &&
      builderOptions.ui.enterButtonText
    ) {
      return builderOptions.ui.enterButtonText;
    }
    return "Enter";
  });

  const getEnterButtonTextSimpleCard = computed(() => {
    if (
      CompetitionServiceV2.canUserEnterCompetition(
        competitionSummaryPublicInternal.value,
        configStore.configApp.userInfo
      )
    ) {
      return "Information / Enter";
    }
    return "Information";
  });

  const userNotEnterCompetitionReasons = computed<string[]>(() => {
    return CompetitionServiceV2.canUserNotEnterCompetitionReasons(
      competitionSummaryPublicInternal.value,
      configStore.configApp.userInfo
    );
  });

  const hasAthleteSecurity = computed(() => {
    return CompetitionServiceV2.hasAthleteSecurity(
      competitionSummaryPublicInternal.value
    );
  });

  const getStatusDescription = computed<string>(() => {
    return competitionSummaryPublicInternal.value.status &&
      competitionSummaryPublicInternal.value.status.description
      ? competitionSummaryPublicInternal.value.status.description
      : "";
  });

  const getScheduleLink = computed(() => {
    return (
      CONFIG.E4S_HOST +
      "/" +
      competitionSummaryPublicInternal.value.compId +
      "/schedule"
    );
  });

  const getEntriesLink = computed(() => {
    return (
      CONFIG.E4S_HOST +
      "/" +
      competitionSummaryPublicInternal.value.compId +
      "/entries"
    );
  });

  const getSResultsLink = computed(() => {
    return (
      CONFIG.E4S_HOST +
      "/" +
      competitionSummaryPublicInternal.value.compId +
      "/results"
    );
  });

  const isNearToClosing = computed(() => {
    return CompetitionServiceV2.isNearToCloseDate(
      competitionSummaryPublicInternal.value
    );
  });

  function launchLink(link: string, targetName: string) {
    window.open(link, targetName ? targetName : "_blank");
  }

  return {
    competitionSummaryPublicInternal,
    competitionMoreInfoV2,
    isLoading,
    init,
    getFirstCompDate,
    getCompDates,
    getCompDatesListForDisplay,
    isMultiDay,
    getLogo,
    getEntryOpenDate,
    isPastOpenDate,
    isPastCompDate,
    getLateEntryDate,
    goToLinkingTicketComp,
    hasLinkingTicketComp,
    goCompHome,
    hasFlyer,
    getFlyerLink,
    getMoreInfoData,
    getAgeGroups,
    getEnterButtonText,
    getEnterButtonTextSimpleCard,
    userNotEnterCompetitionReasons,
    hasAthleteSecurity,
    getStatusDescription,
    isEditingStatus,
    getScheduleLink,
    getEntriesLink,
    getSResultsLink,
    launchLink,
    isNearToClosing,
  };
}
